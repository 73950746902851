import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { CellType } from '@grapecity/wijmo.grid';
import { useMaster } from 'context/MasterContext';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRestApi } from 'context/RestApiContext';
import { MessageModal } from 'components/modal/MessageModal';
import ConstructionInfo from 'views/ClientInfo/ConstructionInfo';
import QuoteInfo from 'views/ClientInfo/QuoteInfo';
import AddInvoiceInfo from 'views/ClientInfo/AddInvoiceInfo';
import ContractEntry from 'views/ContractEntry';
import ContractInfo from 'views/ClientInfo/ContractInfo';
import OrderDestSelectModal from 'views/Order/OrderDestSelectModal';
import WarrantyEntryModal from 'views/Warranty/WarrantyEntryModal';
import InvoiceEntryModal from 'views/Invoice/InvoiceEntryModal';
import TextComponent from 'components/Input/TextComponent';
import StatusLableComponent from 'components/Input/StatusLableComponent';

function ClientInfo() {
  const restApi = useRestApi();

  // 画面パラメタを取得
  const location = useLocation();

  // 画面遷移履歴
  const navigate = useNavigate();

  // 確認ダイアログ
  const [dialog, setDialog] = useState({});

  // 工事履歴
  const [constructionList, setConstructionList] = useState([]);
  // 見積り履歴
  const [quoteList, setQuoteList] = useState([]);
  // 契約履歴
  const [contractList, setContractList] = useState([]);
  // 追加請求
  const [addInvoiceList, setAddInvoiceList] = useState([]);
  // 注文書履歴
  const [orderList, setOrderList] = useState([]);
  // 請求一覧
  const [seikyuList, setSeikyuList] = useState([]);
  // 保証書
  const [warrantyList, setWarrantyList] = useState([]);
  // 請求書
  const [invoiceList, setInvoiceList] = useState([]);

  // 契約入力モーダルへのパラメタ
  const [contractModalParam, setContractModalParam] = useState({});
  // 保証書モーダルへのパラメタ
  const [warrantyModalParam, setWarrantyModalParam] = useState({});
  // 請求書モーダルへのパラメタ
  const [invoiceModalParam, setInvoiceModalParam] = useState({});

  // 顧客情報
  const [clientInfo, setClientInfo] = useState({
    NAME_SEI_1: '',
    NAME_MEI_1: '',
    PREF: '',
    CITY: '',
    TOWN: '',
    ADDRESS: '',
    HOUSE_MAKER: '',
    HOUSE_HIKIWATASHI: '',
    TAN_CD: '',
  });

  // マスタ情報
  const master = useMaster();

  // 注文先選択モーダルパラメタ
  const [orderDestSelectParam, setOrderDestSelectParam] = useState({});

  // 初期表示
  useEffect(() => {
    // 検索処理
    search(location?.state?.clientId, location?.state?.seikyuNo);
  }, []);

  // 選択中の工事の請求NO
  const [selectedSeikyuNo, setSelectedSeikyuNo] = useState(-1);

  // 工事の請求NOの変更処理
  useEffect(() => {
    const clientId = location?.state?.clientId;
    searchSeikyu(clientId, selectedSeikyuNo);
  }, [selectedSeikyuNo]);

  // 請求関連情報を検索
  const searchSeikyu = (clientId, seikyuNo) => {
    // 請求NOの指定がない場合は処理しない
    if (seikyuNo == -1) {
      return;
    }
    restApi.get(`/api/client/info/${clientId}/${seikyuNo}`, (data) => {
      // 見積もり履歴
      setQuoteList(data.quoteList.filter((x) => x.MOTO_KEI_NO == 0));
      // 追加請求
      setAddInvoiceList(
        data.quoteList.filter((x) => x.MOTO_KEI_NO == 1).map((x, i) => ({ ...x, NO: i + 1 }))
      );
      // 契約履歴
      setContractList(data.contractList.map((x, i) => ({ ...x, NO: i + 1 })));
      // 注文書履歴
      setOrderList(data.orderList.map((x, i) => ({ ...x, NO: i + 1 })));
      // 請求一覧
      setSeikyuList([...data.seikyuList]);
      // 保証書
      setWarrantyList([...data.seikyuList.filter((x) => x.HIKI_DATE)]);
      // 請求書
      setInvoiceList([...data.seikyuList.filter((x) => x.SEIKYU_DATE)]);
    });
  };

  // 注文書作成処理
  const handleOnClickOrderCreate = () => {
    if (contractList?.length > 0) {
      setOrderDestSelectParam({ keiNo: contractList[0].KEI_NO });
    }
  };

  // 保証書作成
  const handleOnClickWarrantyEdit = (mode, item) => {
    setWarrantyModalParam({
      mode: mode,
      seikyuNo: item.SEIKYU_NO,
      hikiDate: item.HIKI_DATE,
    });
  };

  // 請求書作成
  const handleOnClickInvoiceEdit = (mode, item) => {
    setInvoiceModalParam({
      mode: mode,
      seikyuNo: item.SEIKYU_NO,
    });
  };

  // 注文書の印刷
  const handleOnClickOrderPrint = (item) => {
    restApi.getToPdf(`/api/order/report/${item.CYU_NO}`);
  };

  // 保証書の印刷
  const handleOnClickWarrantyPrint = (item) => {
    restApi.getToPdf(`/api/warranty/report/${item.SEIKYU_NO}`);
  };

  // 請求書の印刷
  const handleOnClickInvoicePrint = (item) => {
    restApi.getToPdf(`/api/invoice/report/${item.SEIKYU_NO}`);
  };

  // 請求書の承認
  const handleOnClickInvoiceApproval = (mode, item) => {
    navigate('/admin/InvoiceApproval', {
      state: {
        mode: mode,
        seikyuNo: item.SEIKYU_NO,
        backPage: location?.pathname,
      },
    });
  };

  // 請求書状態の取り消し処理
  const handleOnClickInvoiceCancel = (item) => {
    setDialog({
      type: 'confirm',
      message: '請求書のステータスを作成中に戻します。よろしいですか？',
      okCallBack: () => {
        restApi.post(
          `/api/invoice/approval/upd`,
          {
            invoice: {
              SEIKYU_NO: selectedSeikyuNo,
              STATUS: '作成中',
              APP_TAN_CD: '',
            },
          },
          (_) => {
            // 再検索
            searchSeikyu(location?.state?.clientId, selectedSeikyuNo);
          }
        );
      },
    });
  };

  // 注文編集ボタン押下
  const handleOnClickEditOrder = (item, mode) => {
    navigate('/admin/orderentry', {
      state: {
        mode: mode,
        cyuNo: item.CYU_NO,
        backPage: location?.pathname,
      },
    });
  };

  // 顧客情報検索
  const search = (clientId, seikyuNo = -1) => {
    restApi.get(`/api/client/info/${clientId}`, (data) => {
      // 工事情報
      setConstructionList(data.constructionList);

      // 選択行が未設定の場合は先頭行の工事を選択
      if (seikyuNo == -1 && data.constructionList.length > 0) {
        setSelectedSeikyuNo(data.constructionList[0].SEIKYU_NO);
      } else {
        setSelectedSeikyuNo(seikyuNo);
      }

      // 顧客情報
      setClientInfo(data.client);
    });
  };

  // 契約入力からのコールバック処理
  const callbakContractModal = () => {
    // 再読み込み
    searchSeikyu(location?.state?.clientId, selectedSeikyuNo);
  };

  // 注文のアイコンが使用不可かどうか
  const isOrderIconDisabled = (icon, item) => {
    switch (icon) {
      case '編集':
      case '削除':
        // 保証書作成済みは不可
        return item.HIKI_DATE;
      case '印刷':
        // 常に使用可
        return false;
      case '承認申請':
        return item.STATUS == '承認待ち' || item.STATUS == '承認済み' || item.HIKI_DATE;
      case '承認申請取消':
        return item.STATUS != '承認待ち';
    }
  };

  // 請求書のアイコンが使用不可かどうか
  const isInvoiceIconDisabled = (icon, item) => {
    switch (icon) {
      case '編集':
      case '削除':
        return item.STATUS == '承認待ち' || item.STATUS == '承認済み';

      case '印刷':
        // 常に使用可
        return false;
      case '承認申請':
        return item.STATUS == '承認待ち' || item.STATUS == '承認済み';
      case '承認申請取消':
        return item.STATUS != '承認待ち';
      case '取消':
        return item.STATUS != '承認済み';
    }
  };

  // 注文のステータスの既読処理
  const handleOnClickOrderStatusLabel = (item) => {
    if (item.APP_UNREAD_FLG == 1) {
      restApi.post(
        '/api/client/appread',
        {
          TABLE: 'F_CYU_H',
          NO: item.CYU_NO,
        },
        (_) => {}
      );
    }
    item.APP_UNREAD_FLG = 0;
  };

  // 請求のステータスの既読処理
  const handleOnClickInvoiceStatusLabel = (item) => {
    if (item.APP_UNREAD_FLG == 1) {
      restApi.post(
        '/api/client/appread',
        {
          TABLE: 'F_SEIKYU',
          NO: item.SEIKYU_NO,
        },
        (_) => {}
      );
    }
    item.APP_UNREAD_FLG = 0;
  };

  // 注文一覧のグリッドのフォーマット処理
  const cyuListFormatItem = (grid, e) => {
    if (e.panel.cellType == CellType.Cell) {
      var dataItem = grid.rows[e.row].dataItem;

      // 申請承認画面から呼び出された場合に選択した注文の場合に青くする
      let isUpd = location.state?.cyuNo == dataItem.CYU_NO;

      e.cell.style.color = isUpd ? 'blue' : '';
      e.cell.style.fontWeight = isUpd ? 'bold' : '';
      e.cell.style.background = isUpd ? '#DDEEFF' : '';
    }
  };

  return (
    <>
      <Card className="m-0">
        <Card.Body>
          <Row>
            <Col className="pr-1" md="2">
              <Form.Group>
                <label>顧客名</label>
                <TextComponent
                  value={`${clientInfo.NAME_SEI_1} ${clientInfo.NAME_MEI_1}`}
                  type="text"
                  disabled={true}
                ></TextComponent>
              </Form.Group>
            </Col>
            <Col className="pr-1" md="3">
              <Form.Group>
                <label>住所</label>
                <TextComponent
                  value={`${clientInfo.PREF}${clientInfo.CITY}${clientInfo.TOWN}${clientInfo.ADDRESS}`}
                  type="text"
                  disabled={true}
                ></TextComponent>
              </Form.Group>
            </Col>
            <Col className="pr-1" md="2">
              <Form.Group>
                <label>ハウスメーカー</label>
                <TextComponent
                  value={master.houseMaker.find((x) => x.id == clientInfo.HOUSE_MAKER)?.name}
                  type="text"
                  disabled={true}
                ></TextComponent>
              </Form.Group>
            </Col>
            <Col className="pr-1" md="2">
              <Form.Group>
                <label>住宅引渡し時期</label>
                <TextComponent
                  value={`${clientInfo.HOUSE_HIKIWATASHI}`}
                  type="text"
                  disabled={true}
                ></TextComponent>
              </Form.Group>
            </Col>
            <Col className="pr-1" md="1">
              <Form.Group>
                <label>担当プランナー</label>
                <TextComponent
                  value={master.planer.find((x) => x.id == clientInfo.TAN_CD)?.name}
                  type="text"
                  disabled={true}
                ></TextComponent>
              </Form.Group>
            </Col>
          </Row>
          <br />
          {/** 工事情報 */}
          <ConstructionInfo
            clientInfo={clientInfo}
            constructionList={constructionList}
            setConstructionList={setConstructionList}
            selectedSeikyuNo={selectedSeikyuNo}
            setSelectedSeikyuNo={setSelectedSeikyuNo}
            search={search}
          />
          <br />
          {/** 見積もり情報 */}
          <QuoteInfo
            quoteList={quoteList}
            selectedSeikyuNo={selectedSeikyuNo}
            setContractModalParam={setContractModalParam}
            clientInfo={clientInfo}
            contractList={contractList}
            seikyuInfo={seikyuList?.[0]}
            searchSeikyu={searchSeikyu}
          />
          <br />
          {/* 契約情報 */}
          <ContractInfo
            clientInfo={clientInfo} // 顧客情報
            contractList={contractList} // 契約一覧
            selectedSeikyuNo={selectedSeikyuNo} // 選択中の請求番号
            quoteList={quoteList} // 見積一覧
            setContractModalParam={setContractModalParam} // 契約編集モーダルパラメタ
            callbakContractModal={callbakContractModal} // 契約入力のコールバック処理
            orderList={orderList} // 注文一覧
            addInvoiceList={addInvoiceList} // 追加請求一覧
            constructionList={constructionList} // 工事一覧
            seikyuList={seikyuList} // 請求一覧
          />

          <br />
          <Row>
            <Col>
              <div className="list-label1">注文一覧</div>
            </Col>
            <Col style={{ textAlign: 'right' }}>
              <Button
                className="material-symbols-rounded cell-icon btn-fill"
                variant="primary"
                title="注文作成"
                disabled={contractList?.length == 0}
                onClick={handleOnClickOrderCreate}
              >
                add
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="list-3-line">
                <FlexGrid
                  itemsSource={orderList}
                  selectionMode={'None'}
                  headersVisibility={'Column'}
                  isReadOnly={true}
                  formatItem={cyuListFormatItem}
                >
                  <FlexGridColumn binding="NO" header="No" width={60}></FlexGridColumn>
                  <FlexGridColumn
                    binding="CYU_NO"
                    header="注文番号"
                    format={'d'}
                    width={95}
                  ></FlexGridColumn>
                  <FlexGridColumn binding="CYU_DATE" header="注文日" width={95}></FlexGridColumn>
                  <FlexGridColumn
                    binding="KOUJI_MEI"
                    header="工事名"
                    width="2*"
                    allowResizing={false}
                  ></FlexGridColumn>
                  <FlexGridColumn binding="TRI_NAME" header="注文先" width={180}></FlexGridColumn>
                  <FlexGridColumn
                    binding="CYU_KIN"
                    header="金額(税抜)"
                    width={100}
                    align={'right'}
                  ></FlexGridColumn>
                  <FlexGridColumn binding="STATUS" header="ステータス" width={88} align={'center'}>
                    <FlexGridCellTemplate
                      cellType="Cell"
                      template={(cell) => (
                        <StatusLableComponent
                          className="m-0"
                          status={cell.item?.STATUS}
                          isUnread={cell.item?.APP_UNREAD_FLG == 1}
                          onClick={() => handleOnClickOrderStatusLabel(cell.item)}
                        />
                      )}
                    />
                  </FlexGridColumn>
                  <FlexGridColumn header="" binding="" isReadOnly={true} width={186}>
                    <FlexGridCellTemplate
                      cellType="Cell"
                      template={(cell) => (
                        <span>
                          <Button
                            className="material-symbols-rounded cell-icon btn-fill"
                            variant="primary"
                            title="編集"
                            onClick={() => handleOnClickEditOrder(cell.item, 'upd')}
                            disabled={isOrderIconDisabled('編集', cell.item)}
                          >
                            edit
                          </Button>
                          <Button
                            className="material-symbols-rounded cell-icon btn-fill"
                            variant="danger"
                            title="削除"
                            onClick={() => handleOnClickEditOrder(cell.item, 'del')}
                            disabled={isOrderIconDisabled('削除', cell.item)}
                          >
                            delete
                          </Button>
                          <Button
                            className="material-symbols-rounded cell-icon btn-fill"
                            variant="primary"
                            title="印刷"
                            onClick={() => handleOnClickOrderPrint(cell.item)}
                            disabled={isOrderIconDisabled('印刷', cell.item)}
                          >
                            print
                          </Button>
                          <Button
                            className="cell-icon btn-fill"
                            variant="primary"
                            title="承認申請"
                            onClick={() => handleOnClickEditOrder(cell.item, 'request')}
                            disabled={isOrderIconDisabled('承認申請', cell.item)}
                          >
                            申
                          </Button>
                          <Button
                            className="cell-icon btn-fill"
                            variant="danger"
                            title="承認申請取消"
                            onClick={() => handleOnClickEditOrder(cell.item, 'cancel')}
                            disabled={isOrderIconDisabled('承認申請取消', cell.item)}
                          >
                            消
                          </Button>
                        </span>
                      )}
                    />
                  </FlexGridColumn>
                </FlexGrid>
              </div>
            </Col>
          </Row>
          <br />
          {/** 追加請求 */}
          <AddInvoiceInfo
            addInvoiceList={addInvoiceList}
            selectedSeikyuNo={selectedSeikyuNo}
            clientInfo={clientInfo}
            contractList={contractList}
            invoiceList={invoiceList}
          />
          <br />
          <Row>
            <Col md="6">
              <Row>
                <Col>
                  <div className="list-label1">保証書</div>
                </Col>
                <Col style={{ textAlign: 'right' }}>
                  <Button
                    className="material-symbols-rounded cell-icon btn-fill"
                    variant="primary"
                    title="保証書作成"
                    disabled={warrantyList?.length > 0 || contractList?.length == 0}
                    onClick={() =>
                      handleOnClickWarrantyEdit('add', {
                        SEIKYU_NO: selectedSeikyuNo,
                        HIKI_DATE: '',
                      })
                    }
                  >
                    add
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div>
                    <FlexGrid
                      itemsSource={warrantyList}
                      selectionMode={'None'}
                      isReadOnly={true}
                      headersVisibility={'Column'}
                    >
                      <FlexGridColumn
                        binding="SEIKYU_NO"
                        header="請求書番号"
                        width={95}
                        align={'right'}
                      ></FlexGridColumn>
                      <FlexGridColumn
                        binding="HIKI_DATE"
                        header="引渡日"
                        width={95}
                      ></FlexGridColumn>
                      <FlexGridColumn
                        binding="KOUJI_MEI"
                        header="工事名"
                        width="2*"
                        allowResizing={false}
                      ></FlexGridColumn>
                      <FlexGridColumn header="" binding="" isReadOnly={true} width={111}>
                        <FlexGridCellTemplate
                          cellType="Cell"
                          template={(cell) => (
                            <span>
                              <Button
                                className="material-symbols-rounded cell-icon btn-fill"
                                variant="primary"
                                title="編集"
                                disabled={cell.item.HIKI_DATE == ''}
                                onClick={() => handleOnClickWarrantyEdit('upd', cell.item)}
                              >
                                edit
                              </Button>
                              <Button
                                className="material-symbols-rounded cell-icon btn-fill"
                                variant="danger"
                                title="削除"
                                disabled={cell.item.HIKI_DATE == ''}
                                onClick={() => handleOnClickWarrantyEdit('del', cell.item)}
                              >
                                delete
                              </Button>
                              <Button
                                className="material-symbols-rounded cell-icon btn-fill"
                                variant="primary"
                                title="保証書印刷"
                                disabled={cell.item.HIKI_DATE == ''}
                                onClick={() => handleOnClickWarrantyPrint(cell.item)}
                              >
                                print
                              </Button>
                            </span>
                          )}
                        />
                      </FlexGridColumn>
                    </FlexGrid>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md="6">
              <Row>
                <Col>
                  <div className="list-label1">請求書</div>
                </Col>
                <Col style={{ textAlign: 'right' }}>
                  <Button
                    className="material-symbols-rounded cell-icon btn-fill"
                    variant="primary"
                    title="請求書作成"
                    disabled={invoiceList?.length > 0 || contractList?.length == 0}
                    onClick={() => handleOnClickInvoiceEdit('add', { SEIKYU_NO: selectedSeikyuNo })}
                  >
                    add
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="list-3-line">
                    <FlexGrid
                      itemsSource={invoiceList}
                      selectionMode={'None'}
                      isReadOnly={true}
                      headersVisibility={'Column'}
                    >
                      <FlexGridColumn
                        binding="SEIKYU_NO"
                        header="請求書番号"
                        width={95}
                        align={'right'}
                      ></FlexGridColumn>
                      <FlexGridColumn
                        binding="SEIKYU_DATE"
                        header="請求日"
                        width={95}
                      ></FlexGridColumn>
                      <FlexGridColumn
                        binding="MITSU_KIN_ZEI"
                        header="金額(税込)"
                        width="1*"
                      ></FlexGridColumn>
                      <FlexGridColumn
                        binding="STATUS"
                        header="ステータス"
                        width={88}
                        align="center"
                      >
                        <FlexGridCellTemplate
                          cellType="Cell"
                          template={(cell) => (
                            <StatusLableComponent
                              status={cell.item?.STATUS}
                              isUnread={cell.item?.APP_UNREAD_FLG == 1}
                              onClick={() => handleOnClickInvoiceStatusLabel(cell.item)}
                            />
                          )}
                        />
                      </FlexGridColumn>
                      <FlexGridColumn header="" binding="" isReadOnly={true} width={222}>
                        <FlexGridCellTemplate
                          cellType="Cell"
                          template={(cell) => (
                            <span>
                              <Button
                                className="material-symbols-rounded cell-icon btn-fill"
                                variant="primary"
                                title="編集"
                                disabled={isInvoiceIconDisabled('編集', cell.item)}
                                onClick={() => handleOnClickInvoiceEdit('upd', cell.item)}
                              >
                                edit
                              </Button>
                              <Button
                                className="material-symbols-rounded cell-icon btn-fill"
                                variant="danger"
                                title="削除"
                                disabled={isInvoiceIconDisabled('削除', cell.item)}
                                onClick={() => handleOnClickInvoiceEdit('del', cell.item)}
                              >
                                delete
                              </Button>
                              <Button
                                className="material-symbols-rounded cell-icon btn-fill"
                                variant="primary"
                                title="請求書印刷"
                                disabled={isInvoiceIconDisabled('印刷', cell.item)}
                                onClick={() => handleOnClickInvoicePrint(cell.item)}
                              >
                                print
                              </Button>
                              <Button
                                className="material-symbols-rounded cell-icon btn-fill"
                                variant="primary"
                                title="取消"
                                disabled={isInvoiceIconDisabled('取消', cell.item)}
                                onClick={() => handleOnClickInvoiceCancel(cell.item)}
                              >
                                Replay
                              </Button>
                              <Button
                                className="cell-icon btn-fill"
                                variant="primary"
                                title="請求書承認申請"
                                disabled={isInvoiceIconDisabled('承認申請', cell.item)}
                                onClick={() => handleOnClickInvoiceApproval('request', cell.item)}
                              >
                                申
                              </Button>
                              <Button
                                className="cell-icon btn-fill"
                                variant="danger"
                                title="請求書申請取消"
                                disabled={isInvoiceIconDisabled('承認申請取消', cell.item)}
                                onClick={() => handleOnClickInvoiceApproval('cancel', cell.item)}
                              >
                                消
                              </Button>
                            </span>
                          )}
                        />
                      </FlexGridColumn>
                    </FlexGrid>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* 契約モーダル */}
      <ContractEntry
        contractModalParam={contractModalParam}
        callbakContractModal={callbakContractModal}
      ></ContractEntry>

      {/** 注文先選択モーダル */}
      <OrderDestSelectModal params={orderDestSelectParam} />

      {/** 保証書モーダル */}
      <WarrantyEntryModal params={warrantyModalParam} callbak={callbakContractModal} />

      {/** 請求書モーダル */}
      <InvoiceEntryModal params={invoiceModalParam} callbak={callbakContractModal} />

      {/** メッセージモーダル */}
      <MessageModal dialog={dialog}></MessageModal>
    </>
  );
}

export default ClientInfo;
