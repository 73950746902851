import { useState, useEffect, useRef } from 'react';
import { Button, Card, Form, Row, Col } from 'react-bootstrap';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { useNavigate } from 'react-router';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { useRestApi } from 'context/RestApiContext';

/** 未発注一覧のモーダル画面 */
function NoOrdersModal(props) {
  const navigate = useNavigate();

  // グリッド
  const flexGrid = useRef();
  // RESTAPI
  const restApi = useRestApi();

  // 表示
  const [show, setShow] = useState(false);
  // 未発注一覧データ
  const [noOrderList, setNoOrderList] = useState([]);

  // モーダルパラメタの変更処理
  useEffect(() => {
    if (props?.params?.show) {
      restApi.post('/api/dashboard/noOrderList', { SEIKYU_NO_LIST: props.noOrderList }, (data) => {
        setNoOrderList(data.noOrderList);
        // ダイアログを表示
        setShow(true);
      });
    }
  }, [props.params]);

  // 顧客情報ボタン
  const handleOnClickClientInfo = (item) => {
    navigate('/admin/ClientInfo', {
      state: {
        clientId: item.CLIENT_CD,
        seikyuNo: item.SEIKYU_NO,
      },
    });
  };

  // 閉じるボタン
  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '880px',
            },
          },
        }}
        open={show}
      >
        <DialogTitle className="p-3">未発注一覧</DialogTitle>
        <DialogContent className="px-0 py-0 pr-0 pl-0">
          <Card className="m-0">
            <Card.Body>
              <Row className="m-0 p-0">
                <Col className="m-0 p-0">
                  ・発注が１件も承認されていない工事の一覧を表示しています。
                  <br />
                  ・発注金額には未承認分の発注金額も含みます。
                </Col>
              </Row>
              <Row className="mt-0">
                <Col className="pt-0 pl-3 table-scroll" md="12" style={{ height: '620px' }}>
                  <FlexGrid
                    ref={flexGrid}
                    allowSorting={false}
                    itemsSource={noOrderList}
                    selectionMode={'Cell'}
                    headersVisibility={'Column'}
                    autoGenerateColumns={false}
                    isReadOnly={true}
                  >
                    <FlexGridColumn
                      binding="SEIKYU_NO"
                      header="#"
                      width={40}
                      format="d"
                    ></FlexGridColumn>
                    <FlexGridColumn binding="CL_NAME" header="施主名" width={'*'}></FlexGridColumn>
                    <FlexGridColumn
                      binding="KOUJI_MEI"
                      header="工事名"
                      width={'*'}
                    ></FlexGridColumn>
                    <FlexGridColumn binding="KEI_DATE" header="契約日" width={78}></FlexGridColumn>
                    <FlexGridColumn binding="CYU_KIN" header="発注金額" width={88}></FlexGridColumn>
                    <FlexGridColumn header="" binding="" isReadOnly={true} width={28}>
                      <FlexGridCellTemplate
                        cellType="Cell"
                        template={(cell) => (
                          <span>
                            <Button
                              className="material-symbols-rounded cell-icon-sm btn-fill"
                              variant="success"
                              title="顧客情報"
                              onClick={() => handleOnClickClientInfo(cell.item)}
                            >
                              person
                            </Button>
                          </span>
                        )}
                      />
                    </FlexGridColumn>
                  </FlexGrid>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </DialogContent>
        <DialogActions className="p-3">
          <Button variant="secondary" className="footer-button" onClick={handleClose}>
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default NoOrdersModal;
