import { useState, useEffect, useCallback } from 'react';
import { Button } from 'react-bootstrap';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import TextComponent from 'components/Input/TextComponent';

// 工事完了モーダル画面
function KoujiCompleteModal(props) {
  const [show, setShow] = useState();
  // 工事完了コメント
  const [completeComment, setCompleteComment] = useState('');

  useEffect(() => {
    if (props.params.show) {
      setCompleteComment('');
      setShow(true);
    }
  }, [props.params]);

  // キャンセルボタンクリック
  const handelOnClickCancel = () => {
    setShow(false);
  };

  // OKボタンクリック
  const handelOnClickOk = () => {
    props.params.okCallBack(completeComment);
    setShow(false);
  };

  // 編集処理
  const handleOnChangeCompleteComment = (target) => {
    setCompleteComment(target.value);
  };

  return (
    <Dialog fullWidth={true} maxWidth={'sm'} open={show}>
      <DialogTitle className="confirm-modal-header p-2">確認</DialogTitle>
      <DialogContent className="p-2">
        <div className="mb-2">完了処理を実行します。よろしいですか？</div>
        <TextComponent
          type="textarea"
          placeholder={'完了コメントを入力してください。'}
          rows="6"
          value={completeComment}
          onChange={handleOnChangeCompleteComment}
        ></TextComponent>
      </DialogContent>
      <DialogActions className="p-3" style={{ justifyContent: 'space-between' }}>
        <Button variant="secondary" className="footer-button" onClick={handelOnClickCancel}>
          キャンセル
        </Button>
        <Button
          variant="primary"
          className="btn-fill footer-button"
          onClick={() => {
            handelOnClickOk();
          }}
          disabled={completeComment === ''}
        >
          完了
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default KoujiCompleteModal;
