import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';

import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import ConstructionEntryModal from 'views/Construction/ConstructionEntryModal';
import { ProcessLableComponent } from 'components/Input/ProcessLableComponent';
import { NyukinLabelComponent } from 'components/Input/NyukinLabelComponent';

import { useMaster } from 'context/MasterContext';
import { Chip } from '@mui/material';

import NyukinCommentModal from 'views/Accounting/NyukinCommentModal';

function ConstructionInfo(props) {
  const master = useMaster();

  // パラメタ
  const clientInfo = props.clientInfo; // 顧客情報
  const constructionList = props.constructionList; // 工事一覧
  const setConstructionList = props.setConstructionList; // 工事一覧
  const selectedSeikyuNo = props.selectedSeikyuNo; // 請求NO
  const setSelectedSeikyuNo = props.setSelectedSeikyuNo; // 請求NOの選択処理
  const search = props.search; // 検索処理

  // グリッド
  const flexGrid = useRef();

  // 工事入力モーダルへのパラメタ
  const [constructionModalParam, setConstructionModalParam] = useState({});
  // 入金コメントモーダルのパラメタ
  const [nyukinCommentModalparam, setNyukinCommentModalparam] = useState({});

  // 工事ラジオの変更処理
  const handleOnClickContractRadio = (item) => (e) => {
    setSelectedSeikyuNo(item.SEIKYU_NO);
  };

  // 工事情報編集
  const handleOnClickEditConstruction = (mode, item) => () => {
    setConstructionModalParam({
      mode: mode,
      clientInfo: clientInfo,
      construction: {
        ...item,
        CLIENT_CD: clientInfo['CLIENT_CD'],
        SEIKYU_NO: item?.SEIKYU_NO,
      },
    });
    // 一覧を再設定（なぜか選択色がクリアされてしまうので・・・）
    setConstructionList([...constructionList]);
  };

  // 工事入力画面からのコールバック処理
  const callbakConstructionModal = (seikyuNo) => {
    // 再読み込み
    search(clientInfo.CLIENT_CD, seikyuNo);
  };

  // 入金コメントモーダルの呼び出し処理
  const callNyukinCommentModal = (item) => {
    setNyukinCommentModalparam({ client: item });
  };

  useEffect(() => {
    const index = constructionList.indexOf(
      constructionList.find((x) => x.SEIKYU_NO == selectedSeikyuNo)
    );
    for (var i = 0; i < flexGrid.current.control.rows.length; i++) {
      flexGrid.current.control.rows[i].cssClass = i == index ? 'wj-state-selected' : '';
    }
  }, [constructionList, selectedSeikyuNo]);

  return (
    <>
      <Row>
        <Col>
          <div className="list-label1">工事履歴</div>
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <Button
            className="material-symbols-rounded cell-icon btn-fill"
            variant="primary"
            title="工事作成"
            onClick={handleOnClickEditConstruction('add', {})}
          >
            add
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <div>
            <FlexGrid
              ref={flexGrid}
              itemsSource={constructionList}
              selectionMode={'None'}
              headersVisibility={'Column'}
              isReadOnly={true}
              allowSorting={false}
            >
              <FlexGridColumn header="選" isReadOnly={true} width={28}>
                <FlexGridCellTemplate
                  cellType="Cell"
                  template={(cell) => (
                    <input
                      type="radio"
                      checked={cell.item.SEIKYU_NO == selectedSeikyuNo}
                      onClick={handleOnClickContractRadio(cell.item)}
                    ></input>
                  )}
                />
              </FlexGridColumn>
              <FlexGridColumn
                binding="PRCESS_CD"
                header="工程"
                width={100}
                allowResizing={false}
                align="center"
              >
                <FlexGridCellTemplate
                  cellType="Cell"
                  template={(cell) => (
                    <ProcessLableComponent
                      process={master.process.find((p) => p.PROCESS_CD == cell.item.PROCESS_CD)}
                      size="small"
                    />
                  )}
                />
              </FlexGridColumn>
              <FlexGridColumn
                binding="SEIKYU_NO"
                header="工事番号"
                width={58}
                format="d"
                allowResizing={false}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="KOUJI_MEI"
                header="工事名"
                width="1*"
                allowResizing={false}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="KOUJI_BASYO"
                header="工事場所"
                width="1*"
                allowResizing={false}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="ATESAKI"
                header="宛先"
                width={250}
                allowResizing={false}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="NYUKIN_STATUS"
                header="入金状況"
                align={'center'}
                width={112}
              >
                <FlexGridCellTemplate
                  cellType="Cell"
                  template={(cell) => (
                    <NyukinLabelComponent
                      key={cell.item.SEIKYU_NO}
                      nyukin={cell.item.NYUKIN_STATUS}
                      size="small"
                      hasComment={cell.item.NYUKIN_COMMENT}
                      onClick={
                        cell.item.NYUKIN_COMMENT ? () => callNyukinCommentModal(cell.item) : null
                      }
                    />
                  )}
                />
              </FlexGridColumn>
              <FlexGridColumn
                binding="LABELS"
                header="ラベル"
                width={172}
                allowResizing={false}
                align="left"
              >
                <FlexGridCellTemplate
                  cellType="Cell"
                  template={(cell) => (
                    <div
                      style={{
                        width: '160px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {master.label
                        .filter(
                          (ml) =>
                            cell.item?.LABELS &&
                            cell.item.LABELS.split(',').some((l) => l == ml.LABEL_CD)
                        )
                        .map((ml) => (
                          <Chip
                            key={ml.LABEL_CD}
                            className="m-1"
                            size="small"
                            style={{ color: ml.FONT_COLOR, backgroundColor: ml.COLOR }}
                            label={ml.DISPLAY}
                          />
                        ))}
                    </div>
                  )}
                />
              </FlexGridColumn>
              <FlexGridColumn header="" binding="" isReadOnly={true} width={78}>
                <FlexGridCellTemplate
                  cellType="Cell"
                  template={(cell) => (
                    <span>
                      <Button
                        className="material-symbols-rounded cell-icon btn-fill"
                        variant="primary"
                        title="編集"
                        onClick={handleOnClickEditConstruction('upd', cell.item)}
                        disabled={cell.item.SEIKYU_NO != selectedSeikyuNo}
                      >
                        edit
                      </Button>
                      <Button
                        className="material-symbols-rounded cell-icon btn-fill"
                        variant="danger"
                        title="削除"
                        onClick={handleOnClickEditConstruction('del', cell.item)}
                        disabled={cell.item.SEIKYU_NO != selectedSeikyuNo}
                      >
                        delete
                      </Button>
                    </span>
                  )}
                />
              </FlexGridColumn>
            </FlexGrid>
          </div>
        </Col>
      </Row>
      {/* 工事情報モーダル */}
      <ConstructionEntryModal
        params={constructionModalParam}
        callbak={callbakConstructionModal}
      ></ConstructionEntryModal>
      {/** 入金コメントモーダル画面 */}
      <NyukinCommentModal params={nyukinCommentModalparam} />
    </>
  );
}

export default ConstructionInfo;
