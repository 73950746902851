import { DataMap, HeadersVisibility } from '@grapecity/wijmo.grid';
import { CellType } from '@grapecity/wijmo.grid';
import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import TextComponent from 'components/Input/TextComponent';
import { useMaster } from 'context/MasterContext';
import { useRestApi } from 'context/RestApiContext';
import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import SelectComponent from 'components/Input/SelectComponent';
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import { Exists } from 'constants/constants';

/** 工事検索モーダル画面 */
function PaymentConstructionSearchModal(props) {
  // グリッド
  const flexGrid = useRef();

  const master = useMaster();
  const restApi = useRestApi();

  var completeMap = new DataMap(Exists, 'key', 'value');

  // 工事一覧
  const [constructions, setConstructions] = useState([]);

  // モーダルの表示
  const [show, setShow] = useState(false);

  // コード翻訳用のマッピング
  var planerMap = new DataMap(master.planer, 'id', 'name');

  // 選択行の状態を追加
  const [selectedRow, setSelectedRow] = useState(null);

  // 検索条件
  const [searchCondition, setSearchCondition] = useState({
    TAN_CD: '',
    FILTER: '',
  });

  // 初期表示処理
  useEffect(() => {
    if (props.params.show) {
      setSearchCondition({
        TAN_CD: '',
        FILTER: '',
      });
      setConstructions([]);
      setShow(true);
    }
  }, [props.params]);

  // 編集処理
  const handleChange = (prop) => (target) => {
    setSearchCondition({ ...searchCondition, [prop]: target?.value ?? target?.target.value });
  };

  // 検索ボタン
  const handleOnClickSearch = () => {
    restApi.post(`/api/construction/list`, searchCondition, (data) => {
      setConstructions(
        data.map((p, i) => {
          // 宛先は請求先を優先する
          let atesaki =
            p.SEIKYU_KBN === 'client'
              ? p.SEIKYU_CLIENT_NAME
              : p.SEIKYU_TRI_NAME ||
                (p.QUOTE_KBN === 'client' ? p.QUOTE_CLIENT_NAME : p.QUOTE_TRI_NAME);
          return {
            ...p,
            ATESAKI: atesaki,
          };
        })
      );
    });
  };

  // 確定ボタン
  const handleFixed = () => {
    props.params.callback(selectedRow);
    setShow(false);
  };

  // 閉じるボタン
  const handleClose = () => {
    setShow(false);
  };

  // グリッドのフォーマット処理
  const formatItem = (grid, e) => {
    if (e.panel.cellType == CellType.Cell) {
      var dataItem = grid.rows[e.row].dataItem;
      // 完了の場合は背景色をグレー
      if (dataItem.COMPLETE_FLG == '1') {
        e.cell.style.background = '#a6a6a6';
      } else {
        e.cell.style.background = '';
      }
    }
  };

  // グリッドの選択変更
  const handleSelectionChanged = (grid) => {
    const selection = grid.selection;
    if (selection.row > -1 && selection.row < grid.rows.length) {
      setSelectedRow(grid.rows[selection.row].dataItem);
    } else {
      setSelectedRow(null);
    }
  };

  return (
    <Dialog
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '1080px',
          },
        },
      }}
      open={show}
    >
      <DialogTitle className="p-3">工事検索</DialogTitle>
      <DialogContent className="">
        <Row className="pt-4">
          <Col className="pr-1" md="5">
            <Form.Group>
              <label className="client-card-label">検索条件</label>
              <TextComponent
                placeholder="工事名/依頼主/工事番号で検索します"
                type="text"
                value={searchCondition.FILTER}
                onChange={handleChange('FILTER')}
              ></TextComponent>
            </Form.Group>
          </Col>
          <Col className="pr-0" md="2">
            <label className="client-card-label">担当プランナー</label>
            <SelectComponent value={searchCondition.TAN_CD} onChange={handleChange('TAN_CD')}>
              <option value="">　</option>
              {master.planer.map((x, i) => (
                <option key={i} value={x['id']}>
                  {x['name']}
                </option>
              ))}
            </SelectComponent>
          </Col>
          <Col className="pr-0 mb-1 d-flex align-items-end" md="1">
            <Button
              variant="success"
              className="btn-fill ml-3"
              size="sm"
              disabled={!searchCondition.FILTER}
              onClick={handleOnClickSearch}
            >
              検索
            </Button>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <div className="table-scroll" style={{ height: '580px' }}>
              <FlexGrid
                ref={flexGrid}
                isReadOnly={true}
                itemsSource={constructions}
                selectionMode={'Row'}
                headersVisibility={HeadersVisibility.Column}
                allowSorting={true}
                style={{ height: '100%' }}
                formatItem={formatItem}
                selectionChanged={(grid) => handleSelectionChanged(grid)}
                onDoubleClick={(e) => {
                  const grid = flexGrid.current.control;
                  const ht = grid.hitTest(e);
                  if (ht.cellType === CellType.Cell) {
                    handleFixed();
                  }
                }}
              >
                <FlexGridColumn
                  binding="SEIKYU_NO"
                  header="#"
                  width={40}
                  format="d"
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="COMPLETE_FLG"
                  header="完了"
                  width={48}
                  dataMap={completeMap}
                  align="center"
                ></FlexGridColumn>
                <FlexGridColumn binding="KOUJI_MEI" header="工事名" width={'1*'}></FlexGridColumn>
                <FlexGridColumn binding="ATESAKI" header="依頼主" width={'1*'}></FlexGridColumn>
                <FlexGridColumn
                  binding="KOUJI_BASYO"
                  header="工事場所"
                  width={'2*'}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="TAN_CD"
                  dataMap={planerMap}
                  header="担当"
                  width={80}
                ></FlexGridColumn>
              </FlexGrid>
            </div>
          </Col>
        </Row>
      </DialogContent>
      <DialogActions className="p-3" style={{ justifyContent: 'space-between' }}>
        <Button variant="secondary" className="footer-button" onClick={handleClose}>
          閉じる
        </Button>
        <Button
          variant="primary btn-fill"
          className="footer-button"
          onClick={handleFixed}
          disabled={selectedRow === null}
        >
          確定
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PaymentConstructionSearchModal;
