import { DataMap } from '@grapecity/wijmo.grid';
import { CollectionView } from '@grapecity/wijmo';
import { CellType, CellRange } from '@grapecity/wijmo.grid';
import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import TextComponent from 'components/Input/TextComponent';
import { useMaster } from 'context/MasterContext';
import { useRestApi } from 'context/RestApiContext';
import { useAuth } from 'context/AuthContext';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { ProcessLableComponent } from 'components/Input/ProcessLableComponent';
import { NyukinLabelComponent, NYUKIN_STATUS_LIST } from 'components/Input/NyukinLabelComponent';
import KoujiStatusModal from 'views/KoujiDaicho/KoujiStatusModal';
import { Exists, Completes, GenkaError, GenkaErrors } from 'constants/constants';
import { isOlderThanDays } from 'utils/dateUtils';

/** 工事台帳一覧 */
function KoujiDaichoList() {
  const navigate = useNavigate();
  const location = useLocation();

  const auth = useAuth();
  const master = useMaster();
  const restApi = useRestApi();

  var planerMap = new DataMap(master.planer, 'id', 'name');
  var completeMap = new DataMap(Completes, 'key', 'value');
  var errorMap = new DataMap(GenkaErrors, 'key', 'value');
  var nyukinMap = new DataMap(NYUKIN_STATUS_LIST, 'id', 'label');
  var processMap = new DataMap(master.process, 'PROCESS_CD', 'DISPLAY');

  const processDict = master.process.reduce((acc, item) => {
    acc[item.PROCESS_CD] = item; // `id` をキーに設定
    return acc;
  }, {});

  // グリッド
  const flexGrid = useRef();

  // 工事一覧情報
  const [koujiList, setKoujiList] = useState(new CollectionView([]));

  // 顧客検索条件
  const [searchCondition, setSearchCondition] = useState({});
  // 工事状況モーダルパラメタ
  const [koujiStatusModalParam, setKoujiStatusModalParam] = useState({});

  // 初期表示
  useEffect(() => {
    // 他画面から戻ってきた時に復元する
    const sessionKoujiDaichoInfo = sessionStorage.getItem('koujiDaichoInfo');
    if (sessionKoujiDaichoInfo) {
      const koujiDaichoInfo = JSON.parse(sessionKoujiDaichoInfo);
      const seikyuNo = koujiDaichoInfo?.koujiSeikyuNo;
      const _searchCondition = koujiDaichoInfo?.searchCondition;
      setSearchCondition(_searchCondition);
      // 検索処理
      search(_searchCondition, seikyuNo);

      // 初期表示は1年前からのデータを表示
    } else {
      const date = new Date();
      let year = date.getFullYear() - 1;
      let month = date.getMonth() + 1;
      const _searchCondition = {
        START_KEI_DATE: `${year}/${String(month).padStart(2, '0')}/01`,
        END_KEI_DATE: '',
        COMPLETE_DISPLAY: '0', // 完了を表示しない
      };
      setSearchCondition(_searchCondition);
      // 検索処理
      search(_searchCondition);
    }
  }, []);

  // 編集ボタン押下
  const handleClickEdit = (item) => {
    setKoujiStatusModalParam({
      show: true,
      item: item,
      callback: (completeFlg) => {
        const index = koujiList.items.indexOf(
          koujiList.items.find((x) => x.SEIKYU_NO == item.SEIKYU_NO)
        );
        if (index >= 0) {
          const updItem = {
            ...item,
            COMPLETE_FLG: completeFlg,
          };
          Object.assign(koujiList.items[index], updItem);
        }
        if (flexGrid.current) {
          setTimeout(() => {
            flexGrid.current.control.select(-1, -1);
          }, 0);
        }
      },
    });
  };

  // 完了のチェックボックスの変更処理
  const handleOnChangeCompleteDisplay = (e) => {
    let _searchCondition = { ...searchCondition, COMPLETE_DISPLAY: e.target.checked ? '1' : '0' };
    setSearchCondition(_searchCondition);
    search(_searchCondition);
  };

  // 検索処理
  const search = useCallback(
    (condition, seikyuNo) => {
      restApi.post('/api/kouji/list', condition, (data) => {
        let _koujiList = data.koujiList.map((x) => ({
          ...x,
          ERROR:
            x.ERROR_FLG == 1
              ? GenkaError.AFTER_COMPLETION // 完了後に請求
              : x.ARARI_RITSU <= 0.25
              ? GenkaError.LESS_THAN_25 // 粗利が25%以下
              : isOlderThanDays(x.SEIKYU_DATE, 31) && x.ARARI_RITSU >= 0.45
              ? GenkaError.MORE_THAN_45 // 粗利が45%以上
              : '',
        }));
        // 完了を表示しない場合はエラーを上に表示
        if (condition.COMPLETE_DISPLAY == '0') {
          _koujiList = _koujiList.sort((a, b) => b.ERROR - a.ERROR);
        }

        setKoujiList(new CollectionView(_koujiList));

        // 戻ってきたときに選択行を復元
        if (seikyuNo) {
          const index = _koujiList.indexOf(_koujiList.find((x) => x['SEIKYU_NO'] == seikyuNo));
          if (flexGrid.current) {
            setTimeout(() => {
              const grid = flexGrid.current.control;
              grid.select(new CellRange(index), true);
            }, 200);
          }
        }
      });
    },
    [searchCondition]
  );

  // 検索条件の編集処理
  const handleChange = (prop) => (target) => {
    setSearchCondition({ ...searchCondition, [prop]: target?.value ?? target?.target.value });
  };

  // 顧客情報ボタン
  const handleOnClickClientInfo = (item) => {
    // 選択行の工事番号を保持
    sessionStorage.setItem(
      'koujiDaichoInfo',
      JSON.stringify({ koujiSeikyuNo: item.SEIKYU_NO, searchCondition: searchCondition })
    );

    navigate('/admin/ClientInfo', {
      state: {
        clientId: item.CLIENT_CD,
        seikyuNo: item.SEIKYU_NO,
      },
    });
  };

  // 検索ボタン
  const handleOnClickSearch = () => {
    search(searchCondition);
  };

  // グリッドのフォーマット処理
  const formatItem = (grid, e) => {
    if (e.panel.cellType == CellType.Cell) {
      var dataItem = grid.rows[e.row].dataItem;
      // 完了の場合は背景色をグレー
      if (dataItem.COMPLETE_FLG == '1') {
        e.cell.style.background = '#a6a6a6';
      } else {
        e.cell.style.background = '';
      }
      // エラーの場合は文字色を赤
      if (dataItem.ERROR !== '') {
        e.cell.style.color = 'red';
      } else {
        e.cell.style.color = '';
      }
    }
  };

  return (
    <Card className="m-0 table-container">
      <Card.Body>
        <Row>
          <Col className="pr-1">
            <Form.Group>
              <label>契約日</label>
              <br />
              <TextComponent
                type="date"
                value={searchCondition.START_KEI_DATE ?? ''}
                onChange={handleChange('START_KEI_DATE')}
              ></TextComponent>
              <span className="ml-2 mr-2">～</span>
              <TextComponent
                type="date"
                value={searchCondition.END_KEI_DATE ?? ''}
                onChange={handleChange('END_KEI_DATE')}
              ></TextComponent>
              <Button
                variant="success"
                className="btn-fill ml-3"
                size="sm"
                onClick={handleOnClickSearch}
              >
                検索
              </Button>
              <label className="ml-4" style={{ fontSize: '12pt', color: 'black' }}>
                <input
                  className="mr-2"
                  style={{ transform: 'scale(1.3)' }}
                  type="checkbox"
                  checked={searchCondition.COMPLETE_DISPLAY == '1'}
                  onChange={(e) => handleOnChangeCompleteDisplay(e)}
                ></input>
                完了を表示する
              </label>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <div className="table-scroll" style={{ height: 'calc(100vh - 200px)' }}>
              <FlexGrid
                className="kouji-daicho-grid"
                ref={flexGrid}
                isReadOnly={true}
                itemsSource={koujiList}
                selectionMode={'ListBox'}
                headersVisibility={'Column'}
                style={{ height: '100%', pointerEvents: 'auto' }}
                formatItem={formatItem}
                onDoubleClick={(e) => {
                  const grid = flexGrid.current.control;
                  const ht = grid.hitTest(e);
                  if (ht.cellType === CellType.Cell) {
                    const item = grid.rows[ht.row].dataItem;
                    handleClickEdit(item);
                  }
                }}
              >
                <FlexGridFilter />
                <FlexGridColumn
                  header=" "
                  dataMap={completeMap}
                  binding="COMPLETE_FLG"
                  isReadOnly={true}
                  width={28}
                >
                  <FlexGridCellTemplate
                    cellType="Cell"
                    template={(cell) => (
                      <span>
                        <Button
                          className="material-symbols-rounded cell-icon-sm btn-fill"
                          variant={cell.item.COMPLETE_FLG == 1 ? 'warning' : 'primary'}
                          title="編集"
                          onClick={() => handleClickEdit(cell.item)}
                        >
                          {cell.item.COMPLETE_FLG == 1 ? 'task_alt' : 'Radio_Button_Unchecked'}
                        </Button>
                      </span>
                    )}
                  />
                </FlexGridColumn>
                <FlexGridColumn
                  binding="SEIKYU_NO"
                  header="工事No"
                  format={'d'}
                  width={68}
                ></FlexGridColumn>

                <FlexGridColumn
                  binding="PROCESS_CD"
                  header="工程"
                  width={100}
                  allowResizing={false}
                  align="center"
                  dataMap={processMap}
                >
                  <FlexGridCellTemplate
                    cellType="Cell"
                    template={(cell) => (
                      <ProcessLableComponent
                        className="m-0 p-0"
                        process={processDict[cell.item.PROCESS_CD]}
                        size="small"
                      />
                    )}
                  />
                </FlexGridColumn>
                <FlexGridColumn
                  binding="NYUKIN_STATUS"
                  header="入金状況"
                  align={'center'}
                  width={88}
                  dataMap={nyukinMap}
                >
                  <FlexGridCellTemplate
                    cellType="Cell"
                    template={(cell) => (
                      <NyukinLabelComponent nyukin={cell.item.NYUKIN_STATUS} size="small" />
                    )}
                  />
                </FlexGridColumn>
                <FlexGridColumn binding="KEI_DATE" header="契約日" width={88}></FlexGridColumn>
                <FlexGridColumn binding="SEIKYU_DATE" header="請求日" width={88}></FlexGridColumn>
                <FlexGridColumn
                  binding="TAN_CD"
                  header="担当"
                  dataMap={planerMap}
                  width={58}
                ></FlexGridColumn>
                <FlexGridColumn binding="CL_NAME" header="顧客名" width={'*'}></FlexGridColumn>
                <FlexGridColumn binding="KOUJI_MEI" header="工事名" width={'*'}></FlexGridColumn>
                <FlexGridColumn binding="MITSU_KIN" header="売上金額" width={92}></FlexGridColumn>
                <FlexGridColumn
                  binding="QUOTE_GENKA"
                  header="当初予定原価"
                  width={100}
                ></FlexGridColumn>
                <FlexGridColumn binding="CYU_GENKA" header="注文金額" width={92}></FlexGridColumn>
                <FlexGridColumn
                  binding="SEIKYU_GENKA"
                  header="請求金額"
                  width={92}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="ARARI_RITSU"
                  header="利益率"
                  format="P2"
                  width={76}
                ></FlexGridColumn>
                <FlexGridColumn binding="C_CNT" header="注文" width={52}></FlexGridColumn>
                <FlexGridColumn binding="G_CNT" header="請求" width={52}></FlexGridColumn>
                <FlexGridColumn
                  binding="LAST_COMMENT"
                  header="最終ｺﾒﾝﾄ"
                  width={80}
                  align="right"
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="ERROR"
                  header="異常"
                  width={82}
                  dataMap={errorMap}
                ></FlexGridColumn>
                <FlexGridColumn header="" binding="" isReadOnly={true} width={28}>
                  <FlexGridCellTemplate
                    cellType="Cell"
                    template={(cell) => (
                      <span>
                        <Button
                          className="material-symbols-rounded cell-icon-sm btn-fill"
                          variant="success"
                          title="顧客情報"
                          onClick={() => handleOnClickClientInfo(cell.item)}
                        >
                          person
                        </Button>
                      </span>
                    )}
                  />
                </FlexGridColumn>
              </FlexGrid>
              {/** 工事状況モーダル */}
              <KoujiStatusModal params={koujiStatusModalParam} />
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default KoujiDaichoList;
