import { DataMap } from '@grapecity/wijmo.grid';
import { CellType } from '@grapecity/wijmo.grid';
import { CollectionView } from '@grapecity/wijmo';
import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { useMaster } from 'context/MasterContext';
import { useRestApi } from 'context/RestApiContext';
import { useAuth } from 'context/AuthContext';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { MessageModal } from 'components/modal/MessageModal';
import './KoujiDaicho.css';

/** 工事コメントタブ */
function KoujiCommentTab(props) {
  const auth = useAuth();
  const restApi = useRestApi();
  const master = useMaster();
  // コード翻訳用のマッピング
  const staffMap = new DataMap(master.staff, 'id', 'name');

  // 管理者かどうか？
  const isAdmin = auth.auth.kbn?.some((k) => k == 'admin');

  // 工事情報
  const kouji = props.kouji;
  // コメント一覧
  const koujiComments = props.koujiComments;
  const setKoujiComments = props.setKoujiComments;

  // 確認ダイアログ
  const [dialog, setDialog] = useState({});

  // 追加コメント
  const [newComment, setNewComment] = useState('');
  // 編集中のコメント
  const [editedComment, setEditedComment] = useState('');
  // 編集中のコメントID
  const [editCommentId, setEditCommentId] = useState(null);

  // 追加コメントの変更イベント
  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };
  // 追加コメントの保存ボタン押下
  const handleAddComment = () => {
    if (newComment.trim()) {
      restApi.post(`/api/kouji/${kouji.SEIKYU_NO}/comments`, { CONTENT: newComment }, (data) => {
        setKoujiComments([data, ...koujiComments]);
        setNewComment('');
      });
    }
  };
  // コメントの編集リンクの選択
  const handleEditComment = (comment) => {
    setEditCommentId(comment.KOUJI_COMMENT_ID);
    setEditedComment(comment.CONTENT);
  };

  // コメントの内容変更
  const handleEditCommentChange = (e) => {
    setEditedComment(e.target.value);
  };

  // コメントの保存ボタン押下
  const handleSaveEditedComment = (commentId) => {
    restApi.post(
      `/api/kouji/${kouji.SEIKYU_NO}/comments/${commentId}`,
      { CONTENT: editedComment },
      (_) => {
        const updatedComments = koujiComments.map((comment) =>
          comment.KOUJI_COMMENT_ID === commentId ? { ...comment, CONTENT: editedComment } : comment
        );
        setKoujiComments(updatedComments);
        setEditCommentId(null);
        setEditedComment('');
      }
    );
  };

  // コメントの削除リンク押下
  const handleDeleteComment = (commentId) => {
    setDialog({
      type: 'confirm',
      message: `コメントを削除します。よろしいですか？`,
      okCallBack: () => {
        restApi.doDelete(`/api/kouji/${kouji.SEIKYU_NO}/comments/${commentId}`, (_) => {
          setKoujiComments(koujiComments.filter((x) => x.KOUJI_COMMENT_ID !== commentId));
        });
      },
    });
  };

  // コメントのキャンセルボタン押下
  const handleCancelEditComment = () => {
    setEditCommentId(null);
  };

  return (
    <>
      <div className="kouji-panel-tab" style={{ minHeight: '500px' }}>
        <Form.Control
          as="textarea"
          rows={2}
          value={newComment}
          onChange={handleCommentChange}
          placeholder="コメントを入力してください"
        />
        <div className="mt-2 mb-2">
          <Button
            className="btn-fill"
            variant="primary"
            size="sm"
            disabled={!newComment}
            onClick={handleAddComment}
          >
            保存
          </Button>
        </div>
        <ul className="comments-list">
          {koujiComments.map((comment, index) => (
            <span key={index}>
              <span style={{ fontSize: '11pt' }}>
                <b>{staffMap.getDisplayValue(comment.INS_TAN_CD, '')}</b>さんの
                <span className={comment.COMMENT_TYPE == 1 && 'kouji-complete-label'}>
                  {comment.COMMENT_TYPE == 1 && '完了'}コメント
                </span>
              </span>
              {editCommentId === comment.KOUJI_COMMENT_ID ? (
                <>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    value={editedComment}
                    onChange={handleEditCommentChange}
                    className="mb-2"
                  />
                  <Button
                    className="btn-fill"
                    variant="primary"
                    size="sm"
                    onClick={() => handleSaveEditedComment(comment.KOUJI_COMMENT_ID)}
                  >
                    保存
                  </Button>
                  <Button
                    className="btn-fill ml-2"
                    variant="secondary"
                    size="sm"
                    onClick={handleCancelEditComment}
                  >
                    キャンセル
                  </Button>
                  <br />
                </>
              ) : (
                <>
                  <span style={{ fontSize: '10pt' }} className="pl-2">
                    &nbsp;{comment.INS_DATE}&nbsp;{comment.INS_TIME}
                  </span>
                  <li key={index} className={comment.COMMENT_TYPE == 1 && 'kouji-complete-comment'}>
                    {comment.CONTENT}
                  </li>
                  {/* コメントした本人または管理者が編集可能 */}
                  {auth.auth.user_id == comment.INS_TAN_CD || isAdmin ? (
                    <div className="mt-1 ml-1 mb-1" style={{ display: 'flex' }}>
                      <a
                        href="#"
                        style={{
                          fontSize: '10pt',
                          color: '#44546f',
                          textDecoration: 'none',
                        }}
                        onClick={() => handleEditComment(comment)}
                      >
                        <u>編集</u>
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <a
                        href="#"
                        style={{
                          fontSize: '10pt',
                          color: '#44546f',
                          textDecoration: 'none',
                          marginLeft: '8px',
                        }}
                        onClick={() => handleDeleteComment(comment.KOUJI_COMMENT_ID)}
                      >
                        <u>削除</u>
                      </a>
                    </div>
                  ) : (
                    <div className="mt-2"></div>
                  )}
                </>
              )}
            </span>
          ))}
        </ul>
      </div>
      <MessageModal dialog={dialog}></MessageModal>
    </>
  );
}

export default KoujiCommentTab;
