import { useState, useContext, useEffect, useRef, useCallback } from 'react';

import { Button, Card, Form, Row, Col } from 'react-bootstrap';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { useRestApi } from 'context/RestApiContext';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// 顧客情報のソート処理
function ClientCardSortModal(props) {
  const restApi = useRestApi();

  // グリッドとスクロール位置
  const flexGrid = useRef();
  const scrollPosition = useRef(0);

  // 顧客情報
  const [clients, setClients] = useState([]);
  // 選択行
  const [selectedRow, setSelectedRow] = useState(-1);
  // モーダルの表示
  const [show, setShow] = useState(false);

  // モーダルパラメタの変更処理
  useEffect(() => {
    if (props.params.clients) {
      setClients([
        ...props.params.clients
          .sort((a, b) => (a.SORT > b.SORT ? 1 : -1))
          .map((c) => ({
            ...c,
            NAME: `${c.NAME_SEI_1}　${c.NAME_MEI_1}`,
            ADDRESS: `${c.PREF}${c.CITY}${c.TOWN}${c.ADDRESS}`,
          })),
      ]);
      // 未選択の状態にする
      setSelectedRow(-1);
      // ダイアログを表示
      setShow(true);
    }
  }, [props.params]);

  // 明細の変更時にスクロール位置を復元
  useEffect(() => {
    if (flexGrid.current?.control) {
      scrollPosition.current = flexGrid.current.control.scrollPosition = scrollPosition.current;
    }
  }, [clients]);

  // 明細の移動処理
  const handleOnClickMove = (item, upDown) => (e) => {
    item = selectedRow;
    // 移動位置を取得
    let idx = clients.indexOf(item) + (upDown == 'down' ? 1 : -1);
    if (idx < 0 || clients.length <= idx) {
      return;
    }

    let _clients = clients.filter((x) => x != item);
    _clients.splice(idx, 0, item);

    // スクロール位置を保持
    scrollPosition.current = flexGrid.current.control.scrollPosition;
    setClients(_clients);
  };

  // 明細の選択処理
  const selectionChanged = (item) => (e) => {
    setSelectedRow(item);
  };

  // 確定ボタン
  const handleFix = () => {
    let params = {
      SALES_KBN: props.params.staff.SALES_KBN, // 営業事務か？
      CLIENTS: clients.map((c, i) => ({
        ...c,
        SORT: i + 1,
      })),
    };
    restApi.post('/api/dashboard/sort', params, (_) => {
      props.params.fixCallback([...params.CLIENTS]);
      setShow(false);
    });
  };
  // 閉じるボタン
  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '1080px', // Set your width here
            },
          },
        }}
        open={show}
      >
        <DialogTitle className="p-3">{props.params.processName}</DialogTitle>
        <DialogContent className="px-0 py-0 pr-0 pl-0">
          <Card className="m-0">
            <Card.Body>
              <Row>
                <Col className="m-0 p-0">
                  <Button
                    className="material-symbols-rounded cell-icon-sm btn-fill"
                    variant="success"
                    disabled={clients.indexOf(selectedRow) < 0}
                    title="下に移動"
                    onClick={handleOnClickMove({}, 'down')}
                  >
                    Arrow_Downward
                  </Button>
                  <Button
                    className="material-symbols-rounded cell-icon-sm btn-fill ml-1"
                    variant="success"
                    disabled={clients.indexOf(selectedRow) < 0}
                    title="上に移動"
                    onClick={handleOnClickMove({}, 'up')}
                  >
                    Arrow_Upward
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col className="m-0 p-0 table-scroll" style={{ height: '500px' }}>
                  <FlexGrid
                    ref={flexGrid}
                    allowSorting={false}
                    itemsSource={clients}
                    selectionMode={'Cell'}
                    headersVisibility={'Column'}
                    autoGenerateColumns={false}
                    isReadOnly={true}
                  >
                    <FlexGridColumn header="　" isReadOnly={true} width={28}>
                      <FlexGridCellTemplate
                        cellType="Cell"
                        template={(cell) => (
                          <input
                            type="radio"
                            checked={cell.item === selectedRow}
                            onChange={selectionChanged(cell.item)}
                          ></input>
                        )}
                      />
                    </FlexGridColumn>
                    <FlexGridColumn binding="NAME" header="顧客名" width={200}></FlexGridColumn>
                    <FlexGridColumn
                      binding="KOUJI_MEI"
                      header="工事名"
                      width={200}
                    ></FlexGridColumn>
                    <FlexGridColumn binding="ADDRESS" header="住所" width={'1*'}></FlexGridColumn>
                    <FlexGridColumn
                      binding="EXPECTED_SALES_MONTH"
                      header="売上予定月"
                      width={88}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="ORDER_LEVEL"
                      header="受注確度"
                      width={88}
                    ></FlexGridColumn>
                  </FlexGrid>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </DialogContent>
        <DialogActions className="p-3" style={{ justifyContent: 'space-between' }}>
          <Button variant="secondary" className="footer-button" onClick={handleClose}>
            閉じる
          </Button>
          <Button variant="primary btn-fill" className="footer-button" onClick={handleFix}>
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ClientCardSortModal;
