import { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { CellType, KeyAction, DataMap } from '@grapecity/wijmo.grid';

import '@grapecity/wijmo.styles/wijmo.css';

import { Button, Card, Form, Row, Col, MenuItem } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';
import { useRestApi } from 'context/RestApiContext';

import { MessageModal } from 'components/modal/MessageModal';
import SelectComponent from 'components/Input/SelectComponent';
import IconButtonCompnent from 'components/Input/IconButtonCompnent';

import QuoteBaseHistoryModal from 'views/MasterManagement/QuoteBaseHistoryModal';

import { toFloat, toInt } from 'utils/numberUtils';

function QuoteBaseEntry(props) {
  const navigate = useNavigate();
  const restApi = useRestApi();

  // グリッドとスクロール位置
  const flexGrid = useRef();
  const scrollPosition = useRef(0);

  // メッセージモーダル
  const [dialog, setDialog] = useState({});
  // 見積基盤履歴モーダルのパラメタ
  const [historyParams, setHistoryParams] = useState({});
  // 工事種コードマッピング
  const koujiMap = useRef(new DataMap([]));

  // モード（編集/確認）
  const [mode, setMode] = useState('edit');

  // 見積基盤全データ
  const baseQuotes = useRef({});
  // 工事種類一覧
  const [kojiSyuList, setKojiSyuList] = useState([]);

  // 選択中の工事種別
  const [selectedKojiSyu, setSelectedKojiSyu] = useState('');

  // 登録明細
  const [editDetails, setEditDetails] = useState([]);
  // 選択中の見積もり明細
  const [selectedRow, setSelectedRow] = useState(-1);

  // 確認明細
  const [confirmDetails, setConfirmDetails] = useState([]);

  // リロード用データ
  const [reload, setReload] = useState('');

  // 変更データ
  const updDataList = useRef([]);

  // グルーピング処理
  const groupBy = (array, getKey) =>
    array.reduce((obj, cur, idx, src) => {
      const key = getKey(cur, idx, src);
      (obj[key] || (obj[key] = [])).push(cur);
      return obj;
    }, {});

  // 初期表示
  useEffect(() => {
    // 見積基盤の全データを取得
    restApi.get('/api/master/baseQuote/list', (data) => {
      // 工事種別毎の連想配列に変換に変換
      const _baseQuotes = groupBy(
        data.baseQuotes.map((b, i) => ({
          ...b,
          EDITABLE_FLG: b.EDITABLE_FLG == '1',
          ORIGIN_INDEX: i,
          SU: toInt(b.SU),
          GENKA_TANKA: toInt(b.GENKA_TANKA),
          TANKA: toInt(b.TANKA),
        })),
        (d) => d.KOUJI_CD
      );

      baseQuotes.current = _baseQuotes;
      setKojiSyuList(data.koujiList);
      setSelectedKojiSyu(data.koujiList[0].KOUJI_CD);
      koujiMap.current = new DataMap(data.koujiList, 'KOUJI_CD', 'KOUJI_SYU');
    });
  }, []);

  // 工事種別の変更処理
  useEffect(() => {
    if (selectedKojiSyu) {
      let _editDetails = baseQuotes.current[selectedKojiSyu]
        ? [
            ...baseQuotes.current[selectedKojiSyu].map(
              (x) =>
                // 変更データがある場合は変更データから取得
                updDataList.current.find((u) => u.ORIGIN_INDEX == x.ORIGIN_INDEX) || { ...x }
            ),
          ]
            .concat(updDataList.current.filter((u) => !u.BASE_NO && u.KOUJI_CD == selectedKojiSyu))
            .sort((a, b) => (a.SORT > b.SORT ? 1 : -1))
        : [];

      // スクロール位置はトップに
      scrollPosition.current = 0;
      setEditDetails(_editDetails);
    }
  }, [selectedKojiSyu]);

  // 明細の変更時にスクロール位置を復元
  useEffect(() => {
    if (flexGrid.current?.control) {
      scrollPosition.current = flexGrid.current.control.scrollPosition = scrollPosition.current;
    }
  }, [editDetails]);

  // 登録明細の追加ボタン
  const handlenOnClickAdd = () => {
    let addData = {
      KOUJI_CD: selectedKojiSyu,
      GENKA_RATE: 0,
      // 被らないようユニークに採番
      ORIGIN_INDEX: 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (a) {
        let r = (new Date().getTime() + Math.random() * 16) % 16 | 0,
          v = a == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }),
      EDITABLE_FLG: false,
      SORT: Math.max(...editDetails.map((x) => x.SORT)) + 1, // ソート順は表示の最大+1
      GENKA_RATE: null,
    };
    appendUpdData(addData);

    // スクロール位置を保持
    scrollPosition.current = flexGrid.current.control.scrollPosition;
    setEditDetails([...editDetails, addData]);
  };

  // 明細の削除処理
  const handleOnClickDelete = (item, delFlg) => {
    item['DEL_FLG'] = delFlg;

    // スクロール位置を保持
    scrollPosition.current = flexGrid.current.control.scrollPosition;

    // 既存項目は削除フラグを立てる
    if (item.BASE_NO) {
      appendUpdData(item, ['DEL_FLG']);
      setEditDetails(editDetails.map((x) => (x == item ? item : x)));
    }
    // 新規の項目は削除
    else {
      removeUpdData(item);
      setEditDetails(editDetails.filter((x) => x != item));
    }
  };

  // 明細の履歴表示処理
  const handleOnClickHistory = (item) => {
    setHistoryParams({ baseNo: item.BASE_NO });
  };

  // 明細の移動処理
  const handleOnClickMove = (item, upDown) => (e) => {
    item = selectedRow;
    // 移動位置を取得
    let idx = editDetails.indexOf(item) + (upDown == 'down' ? 1 : -1);
    if (idx < 0 || editDetails.length <= idx) {
      return;
    }

    // ソート番号を入れ替え
    let _sort = item.SORT;
    item.SORT = editDetails[idx].SORT;
    editDetails[idx].SORT = _sort;

    // 変更データを保持
    appendUpdData(item, ['SORT']);
    appendUpdData(editDetails[idx], ['SORT']);

    let _editDetails = editDetails.filter((x) => x != item);
    _editDetails.splice(idx, 0, item);

    // スクロール位置を保持
    scrollPosition.current = flexGrid.current.control.scrollPosition;
    setEditDetails(_editDetails);
  };

  // 工事種類の変更処理
  const handleChangeKojisyu = (target) => {
    setSelectedKojiSyu(target.value);
  };

  // 次へボタン押下処理
  const handleOnClickNext = () => {
    updDataList.current = updDataList.current.sort((a, b) =>
      a.KOUJI_CD > b.KOUJI_CD ? 1 : a.SORT > b.SORT ? 1 : -1
    );
    setMode('confirm');
    setConfirmDetails([...updDataList.current]);
  };

  // 戻るボタン押下処理
  const handleOnClickBack = () => {
    // 変更無しの場合はそのまま戻る
    if (!updDataList.current.length) {
      navigate(-1);
    }

    setDialog({
      type: 'confirm',
      message: `修正内容は破棄されます。よろしいですか？`,
      okCallBack: () => {
        navigate(-1);
      },
    });
  };

  // 保存ボタン押下処理
  const handleOnClickSave = () => {
    setDialog({
      type: 'confirm',
      message: `保存します。よろしいですか？`,
      okCallBack: () => {
        restApi.post('/api/master/baseQuote/save', { updList: updDataList.current }, (data) => {
          navigate(-1);
        });
      },
    });
  };

  // 貼り付け処理（編集開始処理をおこなう）
  const pastingCell = (s, args) => {
    beginningEdit(s, args);
  };
  // 編集開始処理
  const beginningEdit = (grid, args) => {
    var dataItem = grid.rows[args.row].dataItem;

    // 削除行は編集不可
    if (dataItem.DEL_FLG == '1') {
      args.cancel = true;
    }
  };

  // グリッドの選択時の処理（ime制御)
  const gridSelectionChanged = (grid, args) => {
    var col = grid.columns[args.col];
    // 数量、単価、原価、掛け率の場合はimeをoffにする
    var imeEnabled = !['SU', 'TANKA', 'GENKA_TANKA', 'GENKA_RATE'].some((x) => x == col?.binding);
    setTimeout(function () {
      grid.imeEnabled = imeEnabled;
    }, 50);
  };

  // 貼り付け後の処理
  const pasted = (grid, args) => {
    cellEditEnded(grid, args);
  };
  // 編集終了処理
  const cellEditEnded = (grid, args) => {
    let dataItem = grid.rows[args.row].dataItem;
    let col = grid.columns[args.col];
    let upd_bindings = [];

    // 掛け率から単価を計算
    if (col['binding'] == 'GENKA_RATE') {
      if (dataItem['GENKA_RATE']) {
        let genka = toFloat(dataItem['GENKA_TANKA']);
        let rate = toFloat(dataItem['GENKA_RATE'], 2);

        dataItem['GENKA_RATE'] = rate;
        dataItem['TANKA'] = rate ? toInt(genka / rate) : 0;
        upd_bindings = ['TANKA'];
      }
    }
    // 単価/原価単価から掛け率を取得
    else if (col['binding'] == 'TANKA' || col['binding'] == 'GENKA_TANKA') {
      let genka = toFloat(dataItem['GENKA_TANKA']);
      let tanka = toFloat(dataItem['TANKA']);
      let rate = tanka == 0 ? 0 : genka / tanka;
      dataItem['GENKA_RATE'] = !isNaN(rate) ? toFloat(rate, 2) : null;
      upd_bindings = ['GENKA_RATE'];
    }

    // 変更データを保持
    appendUpdData(dataItem, [...upd_bindings, col['binding']]);

    // 一覧の再描画をおこなう
    if (upd_bindings.length) {
      grid.beginUpdate();
      grid.setCellData(args.row, 7, dataItem['GENKA_RATE'], true);
      grid.setCellData(args.row, 8, dataItem['TANKA'], true);
      grid.endUpdate();
    }
  };

  // 変更データを削除
  const removeUpdData = (dataItem) => {
    updDataList.current = updDataList.current.filter(
      (u) => u.ORIGIN_INDEX != dataItem.ORIGIN_INDEX
    );
  };

  // 変更データを退避処理
  const appendUpdData = (dataItem, bindings) => {
    // 既存データの編集の場合は、変更列を保持
    if (dataItem.BASE_NO) {
      dataItem['updColumns'] = dataItem['updColumns'] || [];

      bindings.forEach((binding) => {
        // 変更列のオリジナルデータを取得取得
        let org = baseQuotes.current[dataItem.KOUJI_CD].find(
          (b) => b.ORIGIN_INDEX == dataItem.ORIGIN_INDEX
        );

        // 変更が無い場合は取り除く
        if ((org[binding] ?? '') == (dataItem[binding] ?? '')) {
          dataItem['updColumns'] = dataItem['updColumns']?.filter((c) => c != binding) ?? [];
        }
        // 変更あり、かつ、ない場合は追加
        else if (!dataItem['updColumns'].some((c) => c == binding)) {
          dataItem['updColumns'].push(binding);
        }
      });
    }

    // 修正データありの場合
    if (updDataList.current.some((u) => u.ORIGIN_INDEX == dataItem.ORIGIN_INDEX)) {
      if (!dataItem.BASE_NO || dataItem['updColumns'].length) {
        updDataList.current = updDataList.current.map((u) =>
          u.ORIGIN_INDEX == dataItem.ORIGIN_INDEX ? dataItem : u
        );
      }
      // 修正なしの場合は修正データから消す
      else {
        updDataList.current = updDataList.current.filter(
          (u) => u.ORIGIN_INDEX != dataItem.ORIGIN_INDEX
        );
      }
    }
    // 修正データなしの場合、
    // カラムに変更があった、もしくは、新規追加の場合は変更データに追加
    else if (dataItem['updColumns']?.length || !dataItem.BASE_NO) {
      updDataList.current.push(dataItem);
    }

    // 画面の読み込み直し用にstateの変更
    setReload(new Date().getTime());
  };

  // グリッドのフォーマット処理
  const formatItem = (grid, e) => {
    if (e.panel.cellType == CellType.Cell) {
      var col = grid.columns[e.col];
      var dataItem = grid.rows[e.row].dataItem;

      if (col.binding) {
        // 削除データの場合は取り消し線を引く
        let isDel = dataItem.DEL_FLG == '1';
        e.cell.style.textDecorationLine = isDel ? 'line-through' : '';
        e.cell.style.textDecorationStyle = isDel ? 'double' : '';
        e.cell.style.textDecorationColor = isDel ? 'red' : '';

        // 変更列は青く表示
        let isUpd = !dataItem.BASE_NO || dataItem.updColumns?.includes(col.binding);

        e.cell.style.color = isUpd ? 'blue' : '';
        e.cell.style.fontWeight = isUpd ? 'bold' : '';
        e.cell.style.background = isUpd ? '#DDEEFF' : '';
      }
    }
  };

  // 見積もり明細の選択処理
  const selectionChanged = (item) => {
    setSelectedRow(item);
  };

  return (
    <>
      <Card className="m-0">
        <Card.Body>
          {mode == 'edit' ? (
            <>
              <Row>
                <Col className="pl-3" md="4">
                  <Form.Group>
                    <label>工事種別</label>
                    <SelectComponent value={selectedKojiSyu} onChange={handleChangeKojisyu}>
                      {kojiSyuList.map((x) => (
                        <option
                          key={x.KOUJI_CD}
                          value={x.KOUJI_CD}
                          style={{
                            color: updDataList.current.some((u) => u.KOUJI_CD == x.KOUJI_CD)
                              ? 'blue'
                              : '',
                            fontWeight: updDataList.current.some((u) => u.KOUJI_CD == x.KOUJI_CD)
                              ? 'bold'
                              : '',
                            background: updDataList.current.some((u) => u.KOUJI_CD == x.KOUJI_CD)
                              ? '#DDEEFF'
                              : '',
                          }}
                        >
                          {x.KOUJI_SYU}
                        </option>
                      ))}
                    </SelectComponent>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="pt-2">
                <Col>
                  <Button
                    className="material-symbols-rounded cell-icon-sm btn-fill"
                    variant="success"
                    disabled={editDetails.indexOf(selectedRow) < 0}
                    title="下に移動"
                    onClick={handleOnClickMove({}, 'down')}
                  >
                    Arrow_Downward
                  </Button>
                  <Button
                    className="material-symbols-rounded cell-icon-sm btn-fill ml-1"
                    variant="success"
                    disabled={editDetails.indexOf(selectedRow) < 0}
                    title="上に移動"
                    onClick={handleOnClickMove({}, 'up')}
                  >
                    Arrow_Upward
                  </Button>
                </Col>
                <Col className="text-right">
                  <Button
                    className="material-symbols-rounded cell-icon-sm btn-fill"
                    title="追加"
                    onClick={handlenOnClickAdd}
                  >
                    add
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <>変更内容を確認のうえ、保存ボタンで登録してください。</>
          )}
          <Row>
            <Col
              className="pt-1 pl-3 table-scroll"
              style={{ height: mode == 'edit' ? 'calc(100vh - 262px)' : 'calc(100vh - 187px)' }}
            >
              <FlexGrid
                className="small-detail"
                ref={flexGrid}
                imeEnabled={true}
                allowSorting={false}
                itemsSource={mode == 'edit' ? editDetails : confirmDetails}
                selectionMode={mode == 'edit' ? 'Cell' : 'None'}
                headersVisibility={'Column'}
                formatItem={formatItem}
                beginningEdit={beginningEdit}
                cellEditEnded={cellEditEnded}
                pastingCell={pastingCell}
                pasted={pasted}
                keyActionTab={KeyAction.Cycle}
                autoGenerateColumns={false}
                selectionChanged={gridSelectionChanged}
                isReadOnly={mode == 'confirm'}
              >
                <FlexGridColumn header="　" isReadOnly={true} width={28}>
                  <FlexGridCellTemplate
                    cellType="Cell"
                    template={(cell) =>
                      mode == 'edit' ? (
                        <input
                          type="radio"
                          checked={cell.item === selectedRow}
                          onClick={() => {
                            selectionChanged(cell.item);
                          }}
                        ></input>
                      ) : (
                        <></>
                      )
                    }
                  />
                </FlexGridColumn>
                <FlexGridColumn
                  visible={mode == 'confirm'}
                  dataType="String"
                  binding="KOUJI_CD"
                  header={'工事種別'}
                  dataMap={koujiMap.current}
                  width={200}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="CONTENT"
                  dataType="String"
                  header="名称"
                  width={200}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="SIYOU"
                  dataType="String"
                  header="仕様"
                  width={200}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="SU"
                  header="数量"
                  dataType="Number"
                  isRequired={false}
                  width={100}
                  inputType={'tel'}
                ></FlexGridColumn>
                <FlexGridColumn
                  dataType="String"
                  binding="TANI"
                  header="単位"
                  width={50}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="GENKA_TANKA"
                  header="原価単価"
                  dataType="Number"
                  isRequired={false}
                  width={100}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="GENKA_RATE"
                  header="掛け率"
                  width={100}
                  dataType="Number"
                  format="n2"
                  isRequired={false}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="TANKA"
                  header="単価"
                  dataType="Number"
                  isRequired={false}
                  width={100}
                ></FlexGridColumn>
                <FlexGridColumn
                  dataType="Boolean"
                  binding="EDITABLE_FLG"
                  header="変更可能"
                  width={80}
                ></FlexGridColumn>
                <FlexGridColumn
                  dataType="String"
                  binding="BIKO"
                  header="摘要"
                  width={100}
                ></FlexGridColumn>
                <FlexGridColumn
                  dataType="String"
                  binding="BIKO_SYANAI"
                  header="社内備考"
                  width="2*"
                  allowResizing={false}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="SORT"
                  header="ソート順"
                  width={80}
                  allowResizing={false}
                  isReadOnly={true}
                  format="d"
                  align="right"
                ></FlexGridColumn>
                <FlexGridColumn
                  visible={mode == 'edit'}
                  binding=""
                  header="　"
                  width={28}
                  isReadOnly={true}
                >
                  <FlexGridCellTemplate
                    cellType="Cell"
                    template={(cell) => (
                      <IconButtonCompnent
                        className="cell-icon-sm"
                        variant="warning"
                        title="履歴"
                        onClick={() => handleOnClickHistory(cell.item)}
                        icon="History"
                      ></IconButtonCompnent>
                    )}
                  />
                </FlexGridColumn>

                <FlexGridColumn
                  header="　"
                  binding=""
                  isReadOnly={true}
                  width={28}
                  visible={mode == 'edit'}
                >
                  <FlexGridCellTemplate
                    cellType="Cell"
                    template={(cell) =>
                      cell.item.DEL_FLG != '1' ? (
                        <IconButtonCompnent
                          className="cell-icon-sm"
                          variant="danger"
                          title="削除"
                          onClick={() => handleOnClickDelete(cell.item, '1')}
                          icon="Clear"
                        ></IconButtonCompnent>
                      ) : (
                        <IconButtonCompnent
                          className="cell-icon-sm"
                          variant="success"
                          title="元に戻す"
                          onClick={() => handleOnClickDelete(cell.item, '0')}
                          icon="Replay"
                        ></IconButtonCompnent>
                      )
                    }
                  />
                </FlexGridColumn>
              </FlexGrid>
            </Col>
          </Row>
          <Row className="pt-2">
            <Col>
              <Row>
                {mode == 'edit' ? (
                  <>
                    <Col className="d-flex align-items-end">
                      <Button
                        className="footer-button"
                        variant="secondary"
                        onClick={() => handleOnClickBack()}
                      >
                        戻る
                      </Button>
                    </Col>
                    <Col className="d-flex align-items-end flex-row-reverse">
                      <Button
                        variant="success"
                        className="footer-button btn-fill"
                        disabled={!updDataList.current.length}
                        onClick={() => handleOnClickNext()}
                      >
                        次へ
                      </Button>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col className="d-flex align-items-end">
                      <Button
                        className="footer-button"
                        variant="secondary"
                        onClick={() => setMode('edit')}
                      >
                        {' '}
                        戻る
                      </Button>
                    </Col>
                    <Col className="d-flex align-items-end flex-row-reverse">
                      <Button
                        variant="primary"
                        className="btn-fill footer-button"
                        onClick={() => handleOnClickSave()}
                      >
                        保存
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {/** メッセージモーダル */}
      <MessageModal dialog={dialog} />
      {/** 見積もり基盤履歴モーダル */}
      <QuoteBaseHistoryModal params={historyParams} />
    </>
  );
}

export default QuoteBaseEntry;
