// 〇かなし
export const Exists = Object.freeze([
  {
    key: 0,
    value: '',
  },
  {
    key: 1,
    value: '〇',
  },
]);

// 完了
export const Completes = Object.freeze([
  {
    key: 0,
    value: '',
  },
  {
    key: 1,
    value: '完了',
  },
]);

// 原価エラー
export const GenkaError = Object.freeze({
  NONE: 0, // 正常
  MORE_THAN_45: 1, // 粗利が45%以上
  LESS_THAN_25: 2, // 粗利が25%以下
  AFTER_COMPLETION: 3, // 完了後に請求
});
export const GenkaErrors = Object.freeze([
  {
    key: GenkaError.NONE,
    value: '',
  },
  {
    key: GenkaError.AFTER_COMPLETION,
    value: '完了後に請求',
  },
  {
    key: GenkaError.LESS_THAN_25,
    value: '利益25%以下',
  },
  {
    key: GenkaError.MORE_THAN_45,
    value: '利益45%以上',
  },
]);
