import * as React from 'react';
import * as wijmo from '@grapecity/wijmo';

import * as wjChart from '@grapecity/wijmo.react.chart';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';

import { Card, Col, Form, Row, Container } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Checkbox, Select, MenuItem, Tooltip } from '@mui/material';

import { useRestApi } from 'context/RestApiContext';
import { useMaster } from 'context/MasterContext';

import { toFloat } from 'utils/numberUtils';
import { calcKeiRate } from 'utils/keiyakuUtils';

function CorporatePlanning() {
  const master = useMaster();
  const restApi = useRestApi();

  // 年度を取得
  const getNendo = (td) => {
    // 1月、2月の場合は年度は前年
    if (td.getMonth() < 2) {
      return td.getFullYear() - 1;
    }
    return td.getFullYear();
  };

  // 全体
  const [total, setTotal] = useState({ sales: 0, count: 0 });
  // エリア一覧
  const [cityList, setCityList] = useState([]);
  // 年度一覧
  const [nendoList, setNendoList] = useState([]);

  // 選択中の年度
  const [nendo, setNendo] = useState(getNendo(new Date()));
  // 選択中の売上げ区分（1:実績 2:実績+見込み）
  const [salesKbn, setSalesKbn] = useState('1');
  // 選択中の社員
  const [staffs, setStaffs] = useState([]);
  // 選択中の事業
  const [jigyos, setJigyos] = useState([]);
  // 選択中のエリア
  const [cities, setCities] = useState([]);

  // 売り上げデータ
  const [salesData, setSalesData] = useState([]);
  // 原価データ
  const [genkaData, setGenkaData] = useState([]);
  // 売り上げ目標データ
  const [salesGoalData, setSalesGoalData] = useState([]);
  // 売上見込みデータ
  const [saleForecastedData, setSaleForecastedData] = useState([]);

  // 月毎の売り上げデータ
  const [salesByMonth, setSalesByMonth] = useState([]);
  // 担当毎の売り上げデータ
  const [salesByTanto, setSalesByTanto] = useState([]);
  // エリア毎の売り上げデータ(金額)
  const [salesByCityMoney, setSalesByCityMoney] = useState([]);
  // エリア毎の売り上げデータ(件数)
  const [salesByCityCount, setSalesByCityCount] = useState([]);

  // 契約件数情報
  const [keiyakuCount, setKeiyakuCount] = useState([]);
  // 担当毎の契約件数
  const [keiyakuCountByTanto, setKeiyakuCountByTanto] = useState([]);

  const KOUMU_BU = {
    Id: 2,
    Name: '工務部',
  };
  // 年度変更時の処理
  useEffect(() => {
    restApi.post(
      `/api/corporate_planning`,
      {
        nendo: nendo,
        nendoList_acquisition: nendoList.length == 0, // 初回のみ年度リストを取得
      },
      (data) => {
        // 原価
        setGenkaData(data.genkas);

        // 売り上げ目標
        setSalesGoalData(data.sales_goal);

        // 売り上げ見込み
        setSaleForecastedData(data.saleForecasteds);

        // 売り上げ
        setSalesData(data.sales);

        // 契約件数
        setKeiyakuCount(data.keiyaku_count);

        // 年度一覧（初回のみ）
        if (nendoList.length == 0) {
          setNendoList(
            data.nendoList.includes(nendo) ? data.nendoList : [nendo, ...data.nendoList]
          );
        }

        // エリア
        let _cityList = [...new Set(data.sales.map((x) => x.CITY))].sort((a, b) =>
          a > b ? 1 : -1
        );
        setCityList(_cityList);
        // 選択エリア
        setCities(cities.filter((c) => _cityList.includes(c)));
      }
    );
  }, [nendo]);

  // 職員、エリアの変更処理
  useEffect(() => {
    let td = new Date(nendo, 3 - 1, 1);
    let _salesDataByMonth = [];

    let _salesData = [...salesData];
    let _saleForecastedData = [...saleForecastedData];
    let _salesGoalData = [...salesGoalData];

    // 社員／事業でフィルタ
    if (staffs.length || jigyos.length) {
      // [売り上げ]※社員の金額は工務を除いたもの
      _salesData = _salesData.filter(
        (x) =>
          (x.JIGYO_CD != KOUMU_BU.Id && staffs.includes(Number(x.TAN_CD))) ||
          jigyos.includes(x.JIGYO_CD)
      );
      // [売り上げ見込み]※社員の金額は工務を除いたもの
      _saleForecastedData = _saleForecastedData.filter(
        (x) =>
          (x.JIGYO_CD != KOUMU_BU.Id && staffs.includes(Number(x.TAN_CD))) ||
          jigyos.includes(x.JIGYO_CD)
      );
      // [目標金額]
      _salesGoalData = _salesGoalData.filter(
        (x) =>
          (x.GOAL_KBN == 0 && staffs.includes(x.TAN_CD)) ||
          (x.GOAL_KBN == 1 && jigyos.includes(x.TAN_CD))
      );
    } else {
      _salesGoalData = _salesGoalData.filter((x) => x.TAN_CD == 0);
    }

    // エリアでフィルタ
    if (cities.length) {
      _salesData = _salesData.filter((x) => cities.some((c) => c == x.CITY));
      _saleForecastedData = _saleForecastedData.filter((x) => cities.some((c) => c == x.CITY));
    }

    // 原価
    let seikyuNo = [...new Set(_salesData.map((s) => s.SEIKYU_NO))];
    let _genka = genkaData?.filter((g) => seikyuNo.includes(g.SEIKYU_NO)) ?? [];

    // 月ごとの集計を作成
    for (let i = 0; i < 12; i++) {
      let targetMonth = format(td, 'yyyy/MM');
      let m = format(td, 'MM');
      let sales = _salesData
        .filter((x) => x.SEIKYU_DATE.substring(0, 7) == targetMonth)
        .reduce((a, x) => a + x['MITSU_KIN'], 0);
      let saleForecasted = 0;
      let genka = _genka
        .filter((x) => x.SEIKYU_DATE.substring(0, 7) == targetMonth)
        .reduce((a, x) => a + x['GENKA'], 0);
      if (salesKbn != '1') {
        saleForecasted = _saleForecastedData
          .filter((x) => x.EXPECTED_SALES_MONTH == targetMonth)
          .reduce((a, x) => a + x['MITSU_KIN'], 0);
        genka += _saleForecastedData
          .filter((x) => x.EXPECTED_SALES_MONTH == targetMonth)
          .reduce((a, x) => a + x['GENKA_TOTAL'], 0);
      }
      // 注文原価
      let cyu_genka = _genka
        .filter((x) => x.SEIKYU_DATE.substring(0, 7) == targetMonth)
        .reduce((a, x) => a + x['CYU_GENKA'], 0);

      let salesAll = sales + saleForecasted;
      let arari = Math.max(salesAll - genka, 0);
      let arariRate = salesAll == 0 ? 0.0 : toFloat(arari / salesAll, 2);

      let cyu_arari = Math.max(salesAll - cyu_genka, 0);
      let cyu_arariRate = salesAll == 0 ? 0.0 : toFloat(cyu_arari / salesAll, 2);

      let genkaRate = salesAll == 0 && genka == 0 ? 0 : toFloat(1.0) - arariRate;
      let salesGoal = _salesGoalData.reduce((a, x) => a + x['M' + m], 0);

      _salesDataByMonth.push({
        month: format(td, 'yyyy年MM月'),
        sales: sales, // 売上金額
        salesAll: salesAll, // 売上+見込
        genka: genka, // 原価
        arari: arari, // 粗利
        arariRate: arariRate, // 粗利率
        genkaRate: genkaRate, // 原価率

        cyu_genka: cyu_genka, // 注文原価
        cyu_arari: cyu_arari, // 注文粗利
        cyu_arariRate: cyu_arariRate, // 注文粗利率

        goalProfitRate: 0.35, // 目標利益率
        salesGoal: salesGoal, // 目標予算
        achievementRate: salesGoal == 0 ? 0 : salesAll / salesGoal, // 達成率
        saleForecasted: saleForecasted, // 売上見込み
      });
      td.setMonth(td.getMonth() + 1);
    }
    setSalesByMonth(_salesDataByMonth);

    // 担当毎の集計を作成
    const _salesByTanto = _salesData.reduce((acc, s) => {
      const key = s.TAN_CD;
      if (!acc[key]) {
        acc[key] = {
          tanto: master.staff.find((x) => x.id == key)?.name ?? '',
          sales: 0,
          count: 0,
        };
        // 見込みの場合、売り上げ見込みを加算
        if (salesKbn != '1') {
          acc[key].sales = _saleForecastedData
            .filter((x) => x.TAN_CD == key)
            .reduce((a, x) => x.MITSU_KIN + a, 0);
          acc[key].count = _saleForecastedData.filter((x) => x.TAN_CD == key).length;
        }
      }
      acc[key].sales = acc[key].sales + s.MITSU_KIN; // 売上
      acc[key].count = acc[key].count + 1; // 契約件数
      return acc;
    }, {});
    setSalesByTanto(Object.keys(_salesByTanto).map((key) => _salesByTanto[key]));

    // エリア別の集計を作成
    const _salesByCity = _salesData.reduce((acc, s) => {
      const key = s.CITY;
      if (!acc[key]) {
        acc[key] = {
          city: key,
          count: 0,
          sales: 0,
        };
        // 見込みの場合、売り上げ見込みを加算
        if (salesKbn != '1') {
          acc[key].sales = _saleForecastedData
            .filter((x) => x.CITY == key)
            .reduce((a, x) => x.MITSU_KIN + a, 0);
          acc[key].count = _saleForecastedData.filter((x) => x.CITY == key).length;
        }
      }
      acc[key].sales = acc[key].sales + s.MITSU_KIN; // 売上
      acc[key].count = acc[key].count + 1; // 契約件数
      return acc;
    }, {});

    let _salesByCityData = Object.keys(_salesByCity).map((key) => _salesByCity[key]);

    // エリア別（売上金）
    let _salesByCityMoney = [..._salesByCityData];
    _salesByCityMoney.sort((a, b) => b.sales - a.sales);
    setSalesByCityMoney(_salesByCityMoney);

    // エリア別（件数）
    let _salesByCityCount = [..._salesByCityData];
    _salesByCityCount.sort((a, b) => b.count - a.count);
    setSalesByCityCount(_salesByCityCount);

    // 担当者毎の契約件数を作成
    let _keiyakuCountByTanto = master.staff
      .filter(
        (x) =>
          keiyakuCount.some((k) => k.TAN_CD == x.id) &&
          x.PLANNER_KBN == '1' && // プランナーの契約のみを契約数としてカウント
          (staffs.length == 0 || staffs.some((s) => x.id == s))
      )
      .map((x) => {
        let keiyaku = keiyakuCount.find((k) => k.TAN_CD == x.id);
        let keiCount = keiyaku?.KEI_COUNT ?? 0;
        let pendingCount = keiyaku?.PENDING_COUNT ?? 0;
        let lostCount = keiyaku?.LOST_COUNT ?? 0;

        return {
          TANTO: x.name,
          KEI_COUNT: keiCount,
          PENDING_COUNT: pendingCount,
          LOST_COUNT: lostCount,
          // 契約率（保留あり）
          KEI_RATE_PENDING: calcKeiRate(keiCount, lostCount, pendingCount),
          // 契約率（保留なし）
          KEI_RATE: calcKeiRate(keiCount, lostCount),
        };
      });

    // 全体の契約情報（契約率を求めるための情報）
    let kei = _keiyakuCountByTanto.reduce(
      (a, x) => ({
        KEI_COUNT: a.KEI_COUNT + x.KEI_COUNT,
        PENDING_COUNT: a.PENDING_COUNT + x.PENDING_COUNT,
        LOST_COUNT: a.LOST_COUNT + x.LOST_COUNT,
      }),
      { KEI_COUNT: 0, PENDING_COUNT: 0, LOST_COUNT: 0 }
    );
    kei = {
      ...kei,
      TANTO: '合計',
      KEI_RATE_PENDING: calcKeiRate(kei.KEI_COUNT, kei.LOST_COUNT, kei.PENDING_COUNT),
      KEI_RATE: calcKeiRate(kei.KEI_COUNT, kei.LOST_COUNT),
    };
    setKeiyakuCountByTanto([..._keiyakuCountByTanto, kei]);

    // 全体
    let sales = _salesData.reduce((a, x) => a + x.MITSU_KIN, 0);
    if (salesKbn != '1') {
      sales += _saleForecastedData.reduce((a, x) => a + x.MITSU_KIN, 0);
    }
    let salesGoal = _salesDataByMonth.reduce((a, x) => a + x.salesGoal, 0); // 売り上げ目標
    let progress = salesGoal ? sales / salesGoal : 0; // 進捗
    let genka = _salesDataByMonth.reduce((a, x) => a + x.genka, 0);
    let arari = sales - genka;

    setTotal({
      sales: sales,
      salesGoal: salesGoal,
      progress: progress,
      genka: genka,
      arari: arari,
      arariRate: sales ? arari / sales : 0,
      count: _salesData.length + _saleForecastedData.length, // 契約件数
      keiRatePending: kei.KEI_RATE_PENDING, // 契約率（保留あり）
      keiRate: kei.KEI_RATE, // 契約率
      keiCount: kei.KEI_COUNT,
      lostCount: kei.LOST_COUNT,
      pendingCount: kei.PENDING_COUNT,
    });
  }, [salesData, salesKbn, staffs, cities, master.staff]);

  // 月単位グラフデータ
  const [monthlySalesData, setMonthlySalesData] = useState([]);
  useEffect(() => {
    let _monthlySalesData = [];
    salesByMonth.forEach((s) => {
      _monthlySalesData.push({});
      _monthlySalesData.push({
        salesGoal: s.salesGoal, // 目標予算
      });
      _monthlySalesData.push({
        sales: s.sales, // 売り上げ
        saleForecasted: s.saleForecasted, // 売り上げ見込み
        achievementRate: s.achievementRate, // 達成率
      });
    });
    setMonthlySalesData(_monthlySalesData);
  }, [salesByMonth]);

  // 社員/事業を選択
  const handleOnChangeStaffJigyo = (selectedValues) => {
    const staffIds = [];
    const jigyoIds = [];
    selectedValues.forEach((value) => {
      if (!value) {
        return;
      }
      const [type, id] = value.split(':');
      if (type === 'staff') {
        staffIds.push(Number(id));
      } else if (type === 'jigyo') {
        jigyoIds.push(Number(id));
      }
    });
    setStaffs(staffIds);
    setJigyos(jigyoIds);
  };

  // エリアを選択
  const handleOnChangeCity = (value) => {
    setCities(value.sort((a, b) => (a > b ? 1 : -1)));
  };

  // グラフの初期化処理
  function initChart(chart) {
    chart.dataLabel.content = (ht) => {
      if (ht.series.binding === 'achievementRate') {
        return wijmo.format('{val:p2}', { val: ht.value });
      }
      return '';
    };
  }

  // 売上別割合ラベル
  const getLabelContentBySalse = (ht) => {
    let val = ht.value / total.sales;
    if (val < 0.01) {
      // 1パーセント以下は重なるので修正
      return '';
    }
    return wijmo.format('{val:p2}', { val: val });
  };

  // 件数別割合ラベル
  const getLabelContentByCount = (ht) => {
    let val = ht.value / total.count; // 1パーセント以下は重なるので修正
    if (val < 0.01) {
      return '';
    }
    return wijmo.format('{val:p2}', { val: val });
  };

  // 担当別契約率Grid初期化処理
  const initializKeiyakuRateGrid = (flex) => {
    flex.formatItem.addHandler((grid, e) => {
      if (e.panel.cellType == wjcGrid.CellType.Cell) {
        var dataItem = grid.rows[e.row].dataItem;
        if (dataItem.TANTO == '合計') {
          e.cell.style.background = 'rgb(217, 225, 242)';
        } else {
          e.cell.style.background = '';
        }
      }
    });
  };

  return (
    <Container fluid className="p-1">
      <Row>
        <Col md="2">
          <Form.Select
            className="form-control"
            value={nendo}
            onChange={(e) => setNendo(e.target.value)}
          >
            {nendoList?.map((x, i) => (
              <option key={i} value={x}>
                {x}年度
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col md="2">
          <Form.Select
            className="form-control"
            value={salesKbn}
            onChange={(e) => setSalesKbn(e.target.value)}
          >
            <option value={1}>売上実績</option>
            <option value={2}>売上実績＋売上見込</option>
          </Form.Select>
        </Col>
        <Col md="3">
          <Select
            multiple
            displayEmpty
            fullWidth
            style={{ height: '38px' }}
            variant="standard"
            value={[...staffs.map((id) => `staff:${id}`), ...jigyos.map((id) => `jigyo:${id}`)]}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em className="pl-2">社員・事業を選択</em>;
              }
              const selectedStaffs = selected
                .filter((val) => val.startsWith('staff:'))
                .map((val) => master.staff.find((s) => s.id === Number(val.split(':')[1])).name);
              const selectedJigyos = selected
                .filter((val) => val.startsWith('jigyo:'))
                .map((val) => KOUMU_BU.Name);

              return (
                <div className="pl-1">
                  {selectedStaffs.join('、')}
                  {selectedJigyos.length > 0 && (selectedStaffs.length > 0 ? '、' : '')}
                  {selectedJigyos.join('、')}
                </div>
              );
            }}
            onChange={(e) => {
              handleOnChangeStaffJigyo(e.target.value);
            }}
          >
            <MenuItem disabled value="">
              <em>社員を選択</em>
            </MenuItem>
            {master.staff.map((s) => (
              <MenuItem className="m-0 ml-2 p-0" key={s.id} value={`staff:${s.id}`}>
                <Checkbox checked={staffs.indexOf(s.id) > -1} />
                <h4 className="m-0 p-0">{s.name}</h4>
              </MenuItem>
            ))}
            <MenuItem disabled value="">
              <em>事業部を選択</em>
            </MenuItem>
            <MenuItem className="m-0 ml-2 p-0" value={`jigyo:${KOUMU_BU.Id}`}>
              <Checkbox checked={jigyos.includes(KOUMU_BU.Id)} />
              <h4 className="m-0 p-0">{KOUMU_BU.Name}</h4>
            </MenuItem>
          </Select>
        </Col>
        <Col md="3">
          <Select
            multiple
            displayEmpty
            fullWidth
            style={{ height: '38px' }}
            variant="standard"
            value={cities}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em className="pl-2">エリアを選択</em>;
              }
              return <div className="pl-1">{selected.join('、')}</div>;
            }}
            onChange={(e) => {
              handleOnChangeCity(e.target.value);
            }}
          >
            <MenuItem disabled value="">
              <em>エリアを選択</em>
            </MenuItem>
            {cityList.map((c) => (
              <MenuItem key={c} value={c}>
                <Checkbox checked={cities.indexOf(c) > -1} />
                <h4 className="m-0 p-0">{c}</h4>
              </MenuItem>
            ))}
          </Select>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col className="p-1 pl-3" md="2">
          <Card className="m-0">
            <Card.Body className="p-1">
              <Row>
                <Col>
                  <div>
                    <p className="card-category">年間目標</p>
                  </div>
                </Col>
              </Row>
              <Row className="m-0 p-0">
                <Col className="text-right">
                  <h3 className="pt-2 pb-2 m-0">
                    {wijmo.format('{val:c0}', { val: total.salesGoal })}
                  </h3>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col className="p-1" md="2">
          <Card className="m-0">
            <Card.Body className="p-1">
              <Row>
                <Col>
                  <div>
                    <p className="card-category">総売上金額</p>
                  </div>
                </Col>
              </Row>
              <Row className="m-0 p-0">
                <Col className="text-right">
                  <h3 className="pt-2 pb-2 m-0">
                    {wijmo.format('{val:c0}', { val: total.sales })}
                  </h3>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col className="p-1" md="1">
          <Card className="m-0">
            <Card.Body className="p-1">
              <Row>
                <Col>
                  <div>
                    <p className="card-category">売上進捗</p>
                  </div>
                </Col>
              </Row>
              <Row className="m-0 p-0">
                <Col className="text-right">
                  <h3 className="pt-2 pb-2 m-0">
                    {wijmo.format('{val:p0}', { val: total.progress })}
                  </h3>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col className="p-1" md="2">
          <Card className="m-0">
            <Card.Body className="p-1">
              <Row>
                <Col>
                  <div>
                    <p className="card-category">原価</p>
                  </div>
                </Col>
              </Row>
              <Row className="m-0 p-0">
                <Col className="text-right">
                  <h3 className="pt-2 pb-2 m-0">
                    {wijmo.format('{val:c0}', { val: total.genka })}
                  </h3>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col className="p-1" md="2">
          <Card className="m-0">
            <Card.Body className="p-1">
              <Row>
                <Col>
                  <div>
                    <p className="card-category">粗利</p>
                  </div>
                </Col>
              </Row>
              <Row className="m-0 p-0">
                <Col className="text-right">
                  <h3 className="pt-2 pb-2 m-0">
                    {wijmo.format('{val:c0}', { val: total.arari })}
                  </h3>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col className="p-1" md="1">
          <Card className="m-0">
            <Card.Body className="p-1">
              <Row>
                <Col>
                  <div>
                    <p className="card-category">利益率</p>
                  </div>
                </Col>
              </Row>
              <Row className="m-0 p-0">
                <Col className="text-right">
                  <h3 className="pt-2 pb-2 m-0">
                    {wijmo.format('{val:p0}', { val: total.arariRate })}
                  </h3>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col className="p-1" md="2">
          <Tooltip
            title={
              <>
                契約数：{total.keiCount}
                <br />
                失注数：{total.lostCount}
                <br />
                保留数：{total.pendingCount}
              </>
            }
          >
            <Card className="mb-0 mt-0 ml-0 mr-2">
              <Card.Body className="p-1">
                <Row>
                  <Col>
                    <div>
                      <p className="card-category">契約率 ※カッコ内は保留含む</p>
                    </div>
                  </Col>
                </Row>
                <Row className="m-0 p-0">
                  <Col className="text-right">
                    <h3 className="pt-2 pb-2 m-0">
                      {wijmo.format('{val:p0}', { val: total.keiRate })}
                      <span className="ml-2" style={{ fontSize: '15pt' }}>
                        ({wijmo.format('{val:p0}', { val: total.keiRatePending })})
                      </span>
                    </h3>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Tooltip>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <wjChart.FlexChart
            header=""
            bindingX="month"
            stacking="Stacked"
            options={{ groupWidth: '100%' }}
            itemsSource={monthlySalesData}
            palette={['#F10096', '#0072F0', '#00B6CB', 'orange']}
            initialized={initChart}
          >
            <wjChart.FlexChartLegend position="Top" titleAlign="left"></wjChart.FlexChartLegend>

            <wjChart.FlexChartSeries name="目標予算" binding="salesGoal" />
            <wjChart.FlexChartSeries name="売上金額" binding="sales" />
            <wjChart.FlexChartSeries
              name="売上見込"
              binding="saleForecasted"
              visibility={salesKbn == 1 ? 'Hidden' : 'Visible'}
            />
            <wjChart.FlexChartSeries
              name="達成率"
              binding="achievementRate"
              chartType="LineSymbols"
              interpolateNulls={true}
              symbolSize={5}
            >
              <wjChart.FlexChartAxis
                wjProperty="axisY"
                position="Right"
                title="達成率"
                axisLine={true}
                min={0}
                max={2.8}
                format={'p0'}
              ></wjChart.FlexChartAxis>
            </wjChart.FlexChartSeries>
            <wjChart.FlexChartAxis
              wjProperty="axisX"
              itemsSource={[
                { value: 1.5, text: `${nendo}年03月` },
                { value: 4.5, text: `${nendo}年04月` },
                { value: 7.5, text: `${nendo}年05月` },
                { value: 10.5, text: `${nendo}年06月` },
                { value: 13.5, text: `${nendo}年07月` },
                { value: 16.5, text: `${nendo}年08月` },
                { value: 19.5, text: `${nendo}年09月` },
                { value: 22.5, text: `${nendo}年10月` },
                { value: 25.5, text: `${nendo}年11月` },
                { value: 28.5, text: `${nendo}年12月` },
                { value: 31.5, text: `${nendo}年01月` },
                { value: 34.5, text: `${nendo}年02月` },
              ]}
            ></wjChart.FlexChartAxis>
          </wjChart.FlexChart>
        </Col>
      </Row>
      <Row>
        <Col md="8">
          <FlexGrid
            isReadOnly={true}
            allowSorting={false}
            itemsSource={salesByMonth}
            selectionMode={'None'}
            headersVisibility={'Column'}
          >
            <FlexGridColumn binding="month" header="年月"></FlexGridColumn>
            <FlexGridColumn
              binding="salesGoal"
              header="目標予算"
              format="c0"
              width={'1*'}
            ></FlexGridColumn>
            <FlexGridColumn
              binding="salesAll"
              header="売上金額"
              format="c0"
              width={'1*'}
            ></FlexGridColumn>
            <FlexGridColumn
              binding="achievementRate"
              header="達成率"
              format={'p2'}
            ></FlexGridColumn>
            <FlexGridColumn binding="genka" header="原価" format="c0" width={'1*'}></FlexGridColumn>
            <FlexGridColumn binding="arari" header="粗利" format="c0" width={'1*'}></FlexGridColumn>
            <FlexGridColumn binding="arariRate" header="利益率" format={'p2'}></FlexGridColumn>
            <FlexGridColumn
              binding="cyu_genka"
              header="注文 原価"
              format="c0"
              width={'1*'}
            ></FlexGridColumn>
            <FlexGridColumn
              binding="cyu_arari"
              header="注文 粗利"
              format="c0"
              width={'1*'}
            ></FlexGridColumn>
            <FlexGridColumn
              binding="cyu_arariRate"
              header="注文 利益率"
              format={'p2'}
            ></FlexGridColumn>
          </FlexGrid>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <wjChart.FlexPie
            header="売上構成比（担当別売上）"
            bindingName="tanto"
            binding="sales"
            itemsSource={salesByTanto}
            startAngle={90}
          >
            <wjChart.FlexPieDataLabel content={getLabelContentBySalse}></wjChart.FlexPieDataLabel>
          </wjChart.FlexPie>
        </Col>
        <Col md="3">
          <wjChart.FlexPie
            header="売上構成比（担当別売契約数）"
            bindingName="tanto"
            binding="count"
            itemsSource={salesByTanto}
            startAngle={90}
          >
            <wjChart.FlexPieDataLabel content={getLabelContentByCount}></wjChart.FlexPieDataLabel>
          </wjChart.FlexPie>
        </Col>
        <Col md="3">
          <wjChart.FlexPie
            header="売上構成比（エリア別売上）"
            bindingName="city"
            binding="sales"
            itemsSource={salesByCityMoney}
            startAngle={90}
          >
            <wjChart.FlexPieDataLabel content={getLabelContentBySalse}></wjChart.FlexPieDataLabel>
          </wjChart.FlexPie>
        </Col>
        <Col md="3">
          <wjChart.FlexPie
            header="売上構成比（エリア別契約件数）"
            bindingName="city"
            binding="count"
            itemsSource={salesByCityCount}
            startAngle={90}
          >
            <wjChart.FlexPieDataLabel content={getLabelContentByCount}></wjChart.FlexPieDataLabel>
          </wjChart.FlexPie>
        </Col>
      </Row>
      <Row className="pt-2">
        <Col>＜担当別契約率＞</Col>
      </Row>
      <Row>
        <Col md="6">
          <FlexGrid
            isReadOnly={true}
            allowSorting={false}
            itemsSource={keiyakuCountByTanto}
            selectionMode={'None'}
            headersVisibility={'Column'}
            initialized={initializKeiyakuRateGrid}
          >
            <FlexGridColumn binding="TANTO" header="担当"></FlexGridColumn>
            <FlexGridColumn
              binding="KEI_COUNT"
              header="契約数"
              format={'d'}
              width={'1*'}
            ></FlexGridColumn>
            <FlexGridColumn
              binding="LOST_COUNT"
              header="失注数"
              format="d"
              width={'1*'}
            ></FlexGridColumn>
            <FlexGridColumn
              binding="PENDING_COUNT"
              header="保留数"
              format="d"
              width={'1*'}
            ></FlexGridColumn>
            <FlexGridColumn
              binding="KEI_RATE"
              header="契約率(保留なし)"
              format="p0"
              width={'1*'}
            ></FlexGridColumn>
            <FlexGridColumn
              binding="KEI_RATE_PENDING"
              header="契約率(保留あり)"
              format="p0"
              width={'1*'}
            ></FlexGridColumn>
          </FlexGrid>
        </Col>
        <Col md="6">
          <wjChart.FlexChart
            itemsSource={salesByMonth}
            bindingX="month"
            stacking="Stacked"
            palette={['#0072F0', '#00B6CB', '#F325A5']}
          >
            <wjChart.FlexChartLegend position="Top" titleAlign="left"></wjChart.FlexChartLegend>
            <wjChart.FlexChartAxis wjProperty="axisY" format={'p0'}></wjChart.FlexChartAxis>

            <wjChart.FlexChartSeries binding="arariRate" name="粗利"></wjChart.FlexChartSeries>
            <wjChart.FlexChartSeries binding="genkaRate" name="原価" />
            <wjChart.FlexChartSeries
              name="目標利益率"
              binding="goalProfitRate"
              chartType="Line"
              symbolSize={5}
            ></wjChart.FlexChartSeries>
          </wjChart.FlexChart>
        </Col>
      </Row>
    </Container>
  );
}
export default CorporatePlanning;
