import { useState, useContext, useEffect, useRef, useCallback } from 'react';

import { Button, Card, Form, Row, Col } from 'react-bootstrap';
import { format } from 'date-fns';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { useRestApi } from 'context/RestApiContext';
import { useMaster } from 'context/MasterContext';

import { MessageModal } from 'components/modal/MessageModal';
import TextComponent from 'components/Input/TextComponent';
import SelectComponent from 'components/Input/SelectComponent';

import { ProcessLableComponent, getProcess, PROCESS } from 'components/Input/ProcessLableComponent';

import { Chip, Slider } from '@mui/material';

import { isPastYearMonth } from 'utils/dateUtils';

function ConstructionEntryModal(props) {
  const master = useMaster();
  const restApi = useRestApi();

  // モード情報
  const MODE_LIST = [
    { id: 'add', caption: '登録', variant: 'primary', readOnly: false },
    { id: 'upd', caption: '更新', variant: 'primary', readOnly: false },
    { id: 'del', caption: '削除', variant: 'danger', readOnly: true },
  ];

  // 確認ダイアログ
  const [dialog, setDialog] = useState({});

  // 画面モード
  const [mode, setMode] = useState({});

  // 顧客情報
  const [clientInfo, setClientInfo] = useState({});

  // 工事情報
  const [construction, setConstruction] = useState({});

  // 修正前の工事情報
  const [originConstruction, setOriginConstruction] = useState({});

  // 宛先区分
  const [atesakiKbn, setAtesakiKbn] = useState('client');

  // ラベル
  const [labels, setLabels] = useState([]);

  // モーダルの表示
  const [show, setShow] = useState(false);

  // モーダルパラメタの変更処理
  useEffect(() => {
    let construction = props.params.construction;
    if (construction) {
      // モードを設定
      setMode(MODE_LIST.find((x) => x.id == props.params.mode));

      let clientInfo = props.params.clientInfo;

      // 顧客情報
      setClientInfo(clientInfo);

      // 修正前の工事情報
      setOriginConstruction({ ...construction });

      // 工事情報
      if (props.params.mode == 'add') {
        setConstruction({
          ...construction,
          KOUJI_MEI: '',
          KOUJI_BASYO: `${clientInfo.PREF}${clientInfo.CITY}${clientInfo.TOWN}${clientInfo.ADDRESS}`,
          QUOTE_CLIENT_NAME: `${clientInfo.NAME_SEI_1} ${clientInfo.NAME_MEI_1}`,
          QUOTE_TRI_NAME: clientInfo.TRI_NAME ?? '',
          QUOTE_KBN: 'client',
          QUOTE_TRI_SUFFIX: '御中',
        });
        setAtesakiKbn('client');
      } else {
        setConstruction({
          ...construction,
          QUOTE_CLIENT_NAME:
            construction.QUOTE_CLIENT_NAME || `${clientInfo.NAME_SEI_1} ${clientInfo.NAME_MEI_1}`,
          QUOTE_TRI_NAME: construction.QUOTE_TRI_NAME || (clientInfo.TRI_NAME ?? ''),
        });
        setAtesakiKbn(construction.QUOTE_KBN || 'client');
      }

      // ラベル
      setLabels(construction.LABELS ? construction.LABELS.split(',') : []);

      // ダイアログを表示
      setShow(true);
    }
  }, [props.params]);

  // 編集処理
  const handleOnChange = (prop) => (target) => {
    setConstruction({ ...construction, [prop]: target?.value ?? target?.target.value });
  };

  // 宛先ラジオの変更処理
  const handleOnChangeAtesakiKbn = (event) => {
    let _atesakiKbn = event.target.value;
    // 請求名を変更
    setAtesakiKbn(_atesakiKbn);
    setConstruction({ ...construction, QUOTE_KBN: _atesakiKbn });
  };

  // 工程の変更処理
  const handleOnClickProcess = (processCd) => {
    setConstruction({ ...construction, PROCESS_CD: processCd });
  };

  // ラベルの選択処理
  const handleOnClickLabel = (lbl) => {
    if (labels.some((l) => l == lbl)) {
      setLabels(labels.filter((x) => x != lbl));
    } else {
      setLabels([...labels, lbl]);
    }
  };

  // 登録ボタン押下処理
  const handleOnClickCreate = () => {
    setDialog({
      type: 'confirm',
      message: `${mode.caption}します。よろしいですか？`,
      okCallBack: () => {
        // originConstruction
        let today = new Date();
        let dispDate = master.process.find(
          (p) => p.PROCESS_CD == construction.PROCESS_CD
        )?.DISP_DATE;
        let isChangeProcessCd = originConstruction.PROCESS_CD != construction.PROCESS_CD; // 工程に変更があったか？
        let dashboardDate = !isChangeProcessCd
          ? originConstruction.DASHBOARD_DISP_DATE // 工程が変更なしの場合はそのまま
          : dispDate == 0
          ? '9999-12-31' // 表示期限が無期限の場合
          : format(today.setDate(today.getDate() + dispDate), 'yyyy-MM-dd');

        restApi.post(
          `/api/construction/${mode.id}`,
          {
            ...construction,
            LABELS: labels,
            DASHBOARD_DISP_DATE: dashboardDate,
            IS_CHANGE_PROCESS: isChangeProcessCd,
            LOST_PENDING_DATE: [PROCESS.ON_HOLD, PROCESS.LOST_ORDER].includes(
              construction.PROCESS_CD
            )
              ? construction.LOST_PENDING_DATE
              : null,
          },
          () => {
            let seikyuNo = mode.id == 'upd' ? construction.SEIKYU_NO : -1;
            props.callbak(seikyuNo);
            setShow(false);
          }
        );
      },
    });
  };

  // 閉じるボタン
  const handleClose = useCallback(() => {
    setShow(false);
  }, [props.params]);

  return (
    <>
      <Dialog fullWidth={true} maxWidth="md" open={show}>
        <DialogTitle className="p-3">工事情報</DialogTitle>
        <DialogContent className="px-0 py-0 pr-0 pl-0">
          <Card className="m-0">
            <Card.Body>
              <Row>
                <Col className="pl-3" md="6">
                  <Form.Group>
                    <label>工事名（例：〇〇様　工事名）</label>
                    <br />
                    <TextComponent
                      type="text"
                      placeholder="〇〇様　工事名"
                      value={construction?.KOUJI_MEI}
                      disabled={mode?.readOnly}
                      onChange={handleOnChange('KOUJI_MEI')}
                    ></TextComponent>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="pl-3 pt-3" md="10">
                  <Form.Group>
                    <label>工事場所</label>
                    <br />
                    <TextComponent
                      type="text"
                      value={construction?.KOUJI_BASYO}
                      disabled={mode?.readOnly}
                      onChange={handleOnChange('KOUJI_BASYO')}
                    ></TextComponent>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="pl-3 pt-3 d-flex flex-nowrap" md="5">
                  <label>宛先</label>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-start align-items-center">
                  <div style={{ width: '80px' }}>
                    <label>
                      <input
                        type="radio"
                        className="mr-2"
                        name="radioAtesaki"
                        value="client"
                        disabled={mode?.readOnly}
                        checked={atesakiKbn == 'client'}
                        onChange={handleOnChangeAtesakiKbn}
                      />
                      施主
                    </label>
                  </div>
                  <div style={{ width: '300px' }}>
                    <TextComponent
                      className="pl-2"
                      type="text"
                      value={construction?.QUOTE_CLIENT_NAME}
                      disabled={mode?.readOnly || atesakiKbn == 'tori'}
                      onChange={handleOnChange('QUOTE_CLIENT_NAME')}
                    ></TextComponent>
                  </div>
                  <div className="pl-2">様</div>
                </Col>
              </Row>
              <Row className="pt-2">
                <Col className="d-flex justify-content-start align-items-center">
                  <div style={{ width: '80px' }}>
                    <label>
                      <input
                        type="radio"
                        className="mr-2"
                        name="radioAtesaki"
                        value="tori"
                        disabled={
                          mode?.readOnly ||
                          (!clientInfo?.TRI_CD && !originConstruction.QUOTE_TRI_NAME)
                        }
                        checked={atesakiKbn == 'tori'}
                        onChange={handleOnChangeAtesakiKbn}
                      />
                      受注元
                    </label>
                  </div>
                  <div style={{ width: '500px' }}>
                    <TextComponent
                      className="pl-2"
                      type="text"
                      value={construction?.QUOTE_TRI_NAME}
                      disabled={
                        mode?.readOnly ||
                        atesakiKbn == 'client' ||
                        (!clientInfo?.TRI_CD && !originConstruction.QUOTE_TRI_NAME)
                      }
                      onChange={handleOnChange('QUOTE_TRI_NAME')}
                    ></TextComponent>
                  </div>
                  <div className="pl-2">
                    <SelectComponent
                      value={construction?.QUOTE_TRI_SUFFIX}
                      onChange={handleOnChange('QUOTE_TRI_SUFFIX')}
                      disabled={
                        mode?.readOnly ||
                        atesakiKbn == 'client' ||
                        (!clientInfo?.TRI_CD && !originConstruction.QUOTE_TRI_NAME)
                      }
                    >
                      <option value="御中">御中</option>
                      <option value="様">様</option>
                    </SelectComponent>
                  </div>
                </Col>
              </Row>
              {mode.id != 'add' ? (
                <>
                  <Row className="pl-2 pt-2">
                    <label>売上予定月</label>
                  </Row>
                  <Row>
                    <Col md="3">
                      <TextComponent
                        value={construction.EXPECTED_SALES_MONTH}
                        onChange={handleOnChange('EXPECTED_SALES_MONTH')}
                        type="date"
                        selectionMode="Month"
                      />
                    </Col>
                    <Col className="m-0 p-0 d-flex align-items-end">
                      <div style={{ color: 'red' }}>
                        {!construction.EXPECTED_SALES_MONTH && construction.ORDER_LEVEL >= 80
                          ? '※受注確度が80%以上は売上予定月を入力してください。'
                          : ''}
                      </div>
                      <div style={{ color: 'orange' }}>
                        {construction.EXPECTED_SALES_MONTH &&
                        isPastYearMonth(construction.EXPECTED_SALES_MONTH)
                          ? '売上年月に過去の年月が設定されています!'
                          : ''}
                      </div>
                    </Col>
                  </Row>
                  <Row className="pl-2 pt-2">
                    <label>受注確度</label>
                  </Row>
                  <Row>
                    <Col md="10" className="pl-4">
                      <Slider
                        defaultValue={construction.ORDER_LEVEL}
                        valueLabelFormat={(v) => `${v}%`}
                        aria-labelledby="discrete-slider-always"
                        valueLabelDisplay="auto"
                        step={10}
                        min={10}
                        onChange={handleOnChange('ORDER_LEVEL')}
                        marks={[
                          { value: 10, label: '10%' },
                          { value: 50, label: '50%' },
                          { value: 80, label: '80%' },
                          { value: 100, label: '100%' },
                        ]}
                      />
                    </Col>
                    <Col style={{ color: '#1976d2' }}>
                      <h4 className="m-0 p-0">{construction.ORDER_LEVEL}%</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pl-3" md="5">
                      <Form.Group>
                        <label>工程</label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="pt-1 pl-2 mb-2">
                    {getProcess(
                      master,
                      originConstruction.PROCESS_CD,
                      originConstruction.NYUKIN_STATUS,
                      originConstruction.KEI_NO
                    ).map((p) => (
                      <ProcessLableComponent
                        key={p.PROCESS_CD}
                        process={p}
                        width="112px"
                        isDisabled={p.PROCESS_CD != construction.PROCESS_CD}
                        onClick={mode.id != 'del' ? () => handleOnClickProcess(p.PROCESS_CD) : null}
                      />
                    ))}
                  </Row>
                  {/** 保留または失注の場合に保留/失注日付を表示 */}
                  {[PROCESS.LOST_ORDER, PROCESS.ON_HOLD].includes(construction.PROCESS_CD) && (
                    <>
                      <Row>
                        <Col className="pl-2" md="5">
                          <Form.Group>
                            <label>
                              {construction.PROCESS_CD == PROCESS.LOST_ORDER
                                ? '失注日付'
                                : '保留日付'}
                            </label>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="pt-1">
                        <Col className="pl-2" md="3">
                          <TextComponent
                            type="date"
                            value={construction.LOST_PENDING_DATE}
                            onChange={handleOnChange('LOST_PENDING_DATE')}
                          />
                        </Col>
                        <Col className="m-0 p-0 d-flex align-items-end">
                          <div style={{ color: 'red' }}>
                            {!construction.LOST_PENDING_DATE
                              ? `※${
                                  construction.PROCESS_CD == PROCESS.LOST_ORDER
                                    ? '失注日付'
                                    : '保留日付'
                                }入力してください。`
                              : ''}
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row className="pl-3 pt-2">
                    <label>ラベル</label>
                  </Row>
                  <Row className="pt-1 pl-2">
                    {master.label.map((ml) => (
                      <Chip
                        key={ml.LABEL_CD}
                        className="m-1"
                        style={{
                          width: '112px',
                          color: labels.some((l) => l == ml.LABEL_CD) ? ml.FONT_COLOR : '#172b4d',
                          backgroundColor: labels.some((l) => l == ml.LABEL_CD)
                            ? ml.COLOR
                            : '#e4e4e4',
                        }}
                        label={ml.DISPLAY}
                        onClick={mode.id != 'del' ? () => handleOnClickLabel(ml.LABEL_CD) : null}
                      />
                    ))}
                  </Row>
                </>
              ) : (
                <></>
              )}
            </Card.Body>
          </Card>
        </DialogContent>
        <DialogActions className="p-3" style={{ justifyContent: 'space-between' }}>
          <Button variant="secondary" className="footer-button" onClick={handleClose}>
            閉じる
          </Button>
          <Button
            className="btn-fill footer-button"
            variant={mode?.variant}
            disabled={
              construction?.KOUJI_MEI == '' ||
              (!construction.EXPECTED_SALES_MONTH && construction.ORDER_LEVEL >= 80)
            }
            onClick={handleOnClickCreate}
          >
            {mode?.caption}
          </Button>
        </DialogActions>
      </Dialog>
      {/** メッセージモーダル */}
      <MessageModal dialog={dialog}></MessageModal>
    </>
  );
}

export default ConstructionEntryModal;
