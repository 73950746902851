import { CellRange, DataMap } from '@grapecity/wijmo.grid';
import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import TextComponent from 'components/Input/TextComponent';
import { useAuth } from 'context/AuthContext';
import { useMaster } from 'context/MasterContext';
import { useRestApi } from 'context/RestApiContext';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import SelectComponent from 'components/Input/SelectComponent';
import { FlexGridXlsxConverter } from '@grapecity/wijmo.grid.xlsx';

const ClientManage = (props) => {
  const auth = useAuth();

  const restApi = useRestApi();

  const location = useLocation();
  // 履歴
  const navigate = useNavigate();

  // グリッド
  const flexGrid = useRef();

  // 顧客一覧情報
  const [clients, setClients] = useState([]);

  // 選択中の顧客ID
  const [targetClientCd, setTargetClientCd] = useState();

  // 顧客検索条件
  const [searchCondition, setSearchCondition] = useState({
    TAN_CD: '',
    NAME: '',
    CITY: '',
    TOWN: '',
    KOUJI_NO: '',
  });

  // マスタ情報
  const master = useMaster();

  // 検索処理
  const SearchClients = (condition, targetClientCd = null) => {
    restApi.post('/api/client/list', condition, (data) => {
      setClients(data);
      setTargetClientCd(targetClientCd);
    });
  };

  // 初期表示処理
  useEffect(() => {
    // 別画面から戻ってきた場合は検索条件を復活
    let condition = location?.state?.searchCondition ?? {
      TAN_CD: auth.auth.user_id, // 担当コードで絞り込んだ状態で表示（プランナーに限らず）
      NAME: '',
      CITY: '',
      TOWN: '',
      KOUJI_NO: '',
    };
    setSearchCondition(condition);
    // 検索
    SearchClients(condition, location?.state?.targetClientCd);
  }, []);

  // 他の画面から戻ってきた場合に顧客を選択
  useEffect(() => {
    if (targetClientCd) {
      const index = clients.indexOf(clients.find((c) => c['CLIENT_CD'] == targetClientCd));
      if (index >= 0) {
        setTimeout(() => {
          flexGrid.current.control.select(new CellRange(index), true);
        }, 10);
      }
    }
  }, [targetClientCd]);

  // コード翻訳用のマッピング
  var planerMap = new DataMap(master.planer, 'id', 'name');
  var houseMakerMap = new DataMap(master.houseMaker, 'id', 'name');
  var channelKbnMap = new DataMap(
    [...master.channelKbn, { CHANNEL_KBN: 0 }, { CHANNEL_KBN: -1 }],
    'CHANNEL_KBN',
    'CHANNEL_KBN_NM'
  );
  var channelCdMap = new DataMap(
    [...master.channelCd, { CHANNEL_CD: 0 }, { CHANNEL_CD: -1 }],
    'CHANNEL_CD',
    'CHANNEL_NM'
  );

  // 新規顧客作成ボタン
  const handleOnClickNewClient = useCallback(() => {
    navigate('/admin/clientedit', {
      state: {
        mode: 'add',
        searchCondition: searchCondition,
      },
    }),
      [navigate];
  });

  // 編集ボタン
  const handleOnClickEditClient = useCallback(() => {
    navigate('/admin/clientedit', {
      state: {
        mode: 'upd',
        targetClient: flexGrid.current.control.selectedItems[0],
        searchCondition: searchCondition,
      },
    }),
      [navigate];
  });

  // 削除ボタン
  const handleOnClickDeleteClient = useCallback(() => {
    navigate('/admin/clientedit', {
      state: {
        mode: 'del',
        targetClient: flexGrid.current.control.selectedItems[0],
        searchCondition: searchCondition,
      },
    }),
      [navigate];
  });

  // 顧客情報ボタン
  const handleOnClickClientInfo = useCallback(() => {
    navigate('/admin/ClientInfo', {
      state: {
        clientId: flexGrid.current.control.selectedItems[0]['CLIENT_CD'],
      },
    });
  }, [navigate]);

  // 検索ボタン
  const handleOnClickSearch = () => {
    SearchClients(searchCondition);
  };

  // 編集処理
  const handleChange = (prop) => (target) => {
    setSearchCondition({ ...searchCondition, [prop]: target?.value ?? target?.target.value });
  };

  // Excelダウンロード
  const handleExcelDownload = () => {
    const grid = flexGrid.current.control; // FlexGridコンポーネントへの参照を取得
    const fileName = '顧客一覧.xlsx'; // ダウンロードするExcelファイルの名前

    // Excelファイルを生成し、ダウンロードする
    FlexGridXlsxConverter.save(
      grid,
      {
        includeColumns: function (column) {
          return column.header; // ヘッダー名称なしは含めない（ボタンの項目）
        },
      },
      fileName
    );
  };

  return (
    <>
      <Card className="m-0 table-container">
        <Card.Body>
          <Row>
            <Col className="pr-1" md="1">
              <Form.Group>
                <label>担当プランナー</label>
                <SelectComponent value={searchCondition.TAN_CD} onChange={handleChange('TAN_CD')}>
                  <option value={''}></option>
                  {master.planer.map((x, i) => (
                    <option key={i} value={x['id']}>
                      {x['name']}
                    </option>
                  ))}
                </SelectComponent>
              </Form.Group>
            </Col>
            <Col className="pr-1" md="2">
              <Form.Group>
                <label>施主様名</label>
                <TextComponent
                  placeholder="部分一致で検索します"
                  type="text"
                  value={searchCondition.NAME}
                  onChange={handleChange('NAME')}
                ></TextComponent>
              </Form.Group>
            </Col>
            <Col className="pr-1" md="2">
              <Form.Group>
                <label>市</label>
                <TextComponent
                  placeholder="部分一致で検索します"
                  type="text"
                  value={searchCondition.CITY}
                  onChange={handleChange('CITY')}
                ></TextComponent>
              </Form.Group>
            </Col>
            <Col className="pr-1" md="2">
              <Form.Group>
                <label>町名</label>
                <TextComponent
                  placeholder="部分一致で検索します"
                  type="text"
                  value={searchCondition.TOWN}
                  onChange={handleChange('TOWN')}
                ></TextComponent>
              </Form.Group>
            </Col>
            <Col className="pr-1" md="2">
              <Form.Group>
                <label>工事番号</label>
                <TextComponent
                  placeholder="完全一致で検索します"
                  type="text"
                  value={searchCondition.KOUJI_NO}
                  onChange={handleChange('KOUJI_NO')}
                ></TextComponent>
              </Form.Group>
            </Col>

            <Col style={{ paddingTop: '30px' }}>
              <Button
                variant="success"
                className="btn-fill"
                size="sm"
                onClick={handleOnClickSearch}
              >
                検索
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                variant="success"
                className="btn-fill m-2"
                size="sm"
                onClick={handleOnClickNewClient}
              >
                新規登録
              </Button>
              {
                // 経営企画のみ、Excelダウンロード機能が使える
                auth.auth.kbn?.some((k) => k == 'strategic') && (
                  <Button
                    variant="success"
                    className="btn-fill m-2"
                    size="sm"
                    onClick={handleExcelDownload}
                  >
                    Excelダウンロード
                  </Button>
                )
              }
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="table-scroll">
                <FlexGrid
                  ref={flexGrid}
                  isReadOnly={true}
                  itemsSource={clients}
                  selectionMode={'Row'}
                  headersVisibility={'Column'}
                  style={{ height: '100%' }}
                  frozenColumns={2}
                >
                  <FlexGridFilter />
                  <FlexGridColumn header="" binding="" isReadOnly={true} width={42}>
                    <FlexGridCellTemplate
                      cellType="Cell"
                      template={(cell) => (
                        <span className="m-0">
                          <Button
                            className="material-symbols-rounded cell-icon btn-fill"
                            variant="success"
                            title="顧客情報"
                            onClick={handleOnClickClientInfo}
                          >
                            person
                          </Button>
                        </span>
                      )}
                    />
                  </FlexGridColumn>
                  <FlexGridColumn
                    binding="NAME_SEI_1"
                    header="施主名１（姓）"
                    width={140}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="NAME_MEI_1"
                    header="施主名１（名）"
                    width={140}
                  ></FlexGridColumn>
                  <FlexGridColumn binding="CITY" header="市"></FlexGridColumn>
                  <FlexGridColumn binding="TOWN" header="町村"></FlexGridColumn>
                  <FlexGridColumn binding="ADDRESS" header="番地"></FlexGridColumn>
                  <FlexGridColumn
                    binding="FIRST_INQUIRY_DAY"
                    header="初回問合せ日"
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="NAME_SEI_KANA_1"
                    header="施主名１かな（姓）"
                    width={150}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="NAME_MEI_KANA_1"
                    header="施主名１かな（名）"
                    width={150}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="NAME_SEI_2"
                    header="施主名２（姓）"
                    width={140}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="NAME_MEI_2"
                    header="施主名２（名）"
                    width={140}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="NAME_SEI_KANA_2"
                    header="施主名２かな（姓）"
                    width={150}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="NAME_MEI_KANA_2"
                    header="施主名２かな（名）"
                    width={150}
                  ></FlexGridColumn>
                  <FlexGridColumn binding="POST_CODE" header="郵便番号"></FlexGridColumn>
                  <FlexGridColumn binding="PREF" header="都道府県"></FlexGridColumn>
                  <FlexGridColumn binding="TEL1" header="電話番号１"></FlexGridColumn>
                  <FlexGridColumn binding="TEL2" header="電話番号２"></FlexGridColumn>
                  <FlexGridColumn
                    binding="MAIL"
                    header="メールアドレス"
                    width={150}
                  ></FlexGridColumn>
                  <FlexGridColumn binding="BIRTHDAY" header="生年月日"></FlexGridColumn>
                  <FlexGridColumn binding="FAMILY" header="家族構成"></FlexGridColumn>
                  <FlexGridColumn binding="CARS" header="車の保有台数"></FlexGridColumn>
                  <FlexGridColumn
                    binding="HOUSE_MAKER"
                    header="ハウスメーカー"
                    width={150}
                    dataMap={houseMakerMap}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="HOUSE_HIKIWATASHI"
                    header="住宅引き渡し予定日"
                    width={180}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="HIKKOSI_YOTEI"
                    header="引っ越し予定日"
                    width={180}
                  ></FlexGridColumn>
                  <FlexGridColumn binding="BIKO" header="備考"></FlexGridColumn>
                  <FlexGridColumn
                    binding="CHANNEL_KBN"
                    header="集客要因区分"
                    dataMap={channelKbnMap}
                    width={150}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="CHANNEL_CD"
                    header="集客要因"
                    dataMap={channelCdMap}
                    width={150}
                  ></FlexGridColumn>
                  <FlexGridColumn binding="TRI_RYAKU" header="受注元"></FlexGridColumn>
                  <FlexGridColumn
                    binding="TAN_CD"
                    header="担当プランナー"
                    width={150}
                    dataMap={planerMap}
                  ></FlexGridColumn>
                  <FlexGridColumn header="" binding="" isReadOnly={true} width={80}>
                    <FlexGridCellTemplate
                      cellType="Cell"
                      template={(cell) => (
                        <span className="m-0">
                          <Button
                            className="material-symbols-rounded cell-icon btn-fill"
                            variant="primary"
                            title="編集"
                            onClick={handleOnClickEditClient}
                          >
                            edit
                          </Button>
                          <Button
                            className="material-symbols-rounded cell-icon btn-fill"
                            variant="danger"
                            title="削除"
                            onClick={handleOnClickDeleteClient}
                          >
                            delete
                          </Button>
                        </span>
                      )}
                    />
                  </FlexGridColumn>
                </FlexGrid>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default ClientManage;
