import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { CellType, KeyAction, DataMap } from '@grapecity/wijmo.grid';
import { MessageModal } from 'components/modal/MessageModal';
import { useRestApi } from 'context/RestApiContext';
import { useMaster } from 'context/MasterContext';
import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { Chip } from '@mui/material';

import IconButtonCompnent from 'components/Input/IconButtonCompnent';
import { ColorPickerModal } from 'components/modal/ColorPickerModal';

// マスタ編集モーダル
function MasterEditModal(props) {
  const master = useMaster();
  const restApi = useRestApi();

  // マスタ定義
  const [masterContext, setMasterContext] = useState({});
  // マスタデータ
  const [masterData, setMasterData] = useState([]);

  // 削除データのキー値
  const [deleteDataKeys, setDeleteDataKeys] = useState([]);

  // メッセージダイアログ
  const [message, setMessage] = useState({});

  // 色選択ダイアログパラメタ
  const [colorPickerParams, setColorPickerParams] = useState({});
  // モーダルの表示
  const [show, setShow] = useState(false);

  // データマッピング
  const [dataMaps, setDataMaps] = useState({});

  // モーダルパラメタの変更処理
  useEffect(() => {
    if (props.params.tableName) {
      restApi.get(`/api/master/manage/${props.params.tableName}`, (data) => {
        // マスタ定義
        setMasterContext(data.masterContext);
        // マスタデータ(チェックボックスはbool型に)
        let mData = data.masterData.map((d) => {
          data.masterContext.fields.forEach((f) => {
            if (f.dataType == 'checkbox') {
              d[f.columnName] = d[f.columnName] == f.checkOnValue;
            }
          });
          return d;
        });

        setMasterData(mData);

        // データマッピングを保持
        let _dataMaps = {};
        if (data.masterContext.dataMaps) {
          console.log(data.masterContext.dataMaps);
          _dataMaps = Object.keys(data.masterContext.dataMaps).reduce((acc, key) => {
            acc[key] = new DataMap(data.masterContext.dataMaps[key], 'key', 'name');
            return acc;
          }, {});
        }
        setDataMaps(_dataMaps);

        // 変更データのキー値
        setDeleteDataKeys([]);
        // ダイアログを表示
        setShow(true);
      });
    }
  }, [props.params]);

  // 追加ボタン押下処理
  const handlenOnClickAdd = () => {
    let mData = { editMode: 'add' };
    masterContext.fields.forEach((f) => {
      // ラベル
      if (f.dataType == 'labelSample') {
        mData[f.color] = '#ffbf7f';
        mData[f.fontColor] = '#172b4d';
      }
      // チェックボックス
      else if (f.dataType == 'checkbox') {
        mData[f.columnName] = false;
      }
    });

    setMasterData([...masterData, mData]);
  };

  // 削除ボタン
  const handleOnClickDelete = (cell) => {
    // 削除データを追加
    const pk = masterContext.fields.find((f) => f.isPk);
    let key = cell.item[pk.columnName];
    if (!deleteDataKeys.some((k) => key == k)) {
      setDeleteDataKeys([...deleteDataKeys, key]);
    }

    setMasterData(masterData.filter((d) => d != cell.item));
  };

  // 色の変更処理
  const handleOnClickColor = (e, cell, color) => {
    let mode = 'back';
    let backColor = color;
    let fontColor = '';
    const lbl = masterContext.fields.find((f) => f.dataType == 'labelSample');
    if (lbl) {
      // 背景色か文字色モードかをカラムによって決定
      mode = cell.col.binding == lbl.color ? 'back' : 'font';
      backColor = cell.item[lbl.color];
      fontColor = cell.item[lbl.fontColor];
    }

    setColorPickerParams({
      color: color,
      mode: mode,
      backColor: backColor,
      fontColor: fontColor,
      callBack: (_color) => {
        let item = {
          ...cell.item,
          editMode: cell.item.editMode ?? 'upd',
          [cell.col.binding]: _color,
        };
        setMasterData(masterData.map((d) => (d == cell.item ? item : d)));
      },
    });
    e.preventDefault();
  };

  // 閉じるボタン
  const handleClose = () => {
    setShow(false);
  };
  // 保存ボタン
  const handleSave = () => {
    // 入力チェック
    let errors = new Set();

    // 必須チェック
    let requiredFields = masterContext.fields.filter((f) => f.required);
    requiredFields.forEach((f) => {
      masterData.forEach((d) => {
        if (!(d[f.columnName] ?? '').trim()) {
          if (!d['error']) {
            d['error'] = [];
          }
          d['error'].push(f.columnName);
          errors.add(`${f.columnDisplayName}を入力してください。`);
        }
      });
    });
    // 重複チェック
    let uniqueFields = masterContext.fields.filter((f) => f.unique);
    uniqueFields.forEach((f) => {
      masterData.forEach((md) => {
        if (
          masterData.some(
            (d) => md != d && (md[f.columnName] ?? '').trim() && md[f.columnName] == d[f.columnName]
          )
        ) {
          if (!md['error']) {
            md['error'] = [];
          }
          md['error'].push(f.columnName);
          errors.add(`${f.columnDisplayName}が重複しています。`);
        }
      });
    });

    if (errors.size) {
      setMessage({
        type: 'error',
        message: Array.from(errors),
      });
      return;
    }

    // チェックボックスの値を元に戻す
    let mData = masterData.map((d) => {
      let _d = { ...d };
      masterContext.fields.forEach((f) => {
        if (f.dataType == 'checkbox') {
          _d[f.columnName] = _d[f.columnName] ? f.checkOnValue : f.checkOffValue;
        }
      });
      return _d;
    });

    const params = {
      addData: mData.filter((d) => d.editMode == 'add'),
      updData: mData.filter((d) => d.editMode == 'upd'),
      delKeys: deleteDataKeys,
    };

    restApi.post(`/api/master/manage/${props.params.tableName}`, params, (_) => {
      // 画面を閉じる
      setShow(false);

      // マスタの再読み込み
      master.reloadMaster();
    });
  };

  // 貼り付け処理（編集開始処理をおこなう）
  const pastingCell = (grid, args) => {
    beginningEdit(grid, args);
  };
  // 編集開始処理
  const beginningEdit = (grid, args) => {
    let col = grid.columns[args.col];
    let field = masterContext.fields.find((f) => f.columnName == col.binding);
    // PK、色選択の場合は非活性
    if (field.isPk || field.dataType == 'color') {
      args.cancel = true;
    }
  };
  // 貼り付け後の処理
  const pasted = (grid, args) => {
    cellEditEnded(grid, args);
  };
  // 編集終了処理
  const cellEditEnded = (grid, args) => {
    let col = grid.columns[args.col];
    let dataItem = grid.rows[args.row].dataItem;
    if (!dataItem.editMode) {
      dataItem['editMode'] = 'upd';
    }
    if (dataItem.error) {
      dataItem['error'] = dataItem['error'].filter((e) => e != col?.binding);
      grid.beginUpdate();
      grid.endUpdate();
    }
  };

  // グリッドの選択時の処理（ime制御)
  const gridSelectionChanged = (grid, args) => {
    let col = grid.columns[args.col];
    let field = masterContext.fields?.find((f) => f.columnName == col?.binding);
    let imeEnabled = field?.imeEnabled ?? false;
    setTimeout(function () {
      grid.imeEnabled = imeEnabled;
    }, 50);
  };
  // 表示フォーマット処理
  const itemFormatter = (panel, r, c, cell) => {
    if (panel.cellType == CellType.Cell) {
      let col = panel.columns[c];
      if (
        col.inputType == 'password' &&
        (!panel.grid.activeEditor || !panel.grid.editRange.contains(r, c))
      ) {
        let cvalue = panel.rows[r].dataItem[col.binding];
        if (cvalue) {
          let ncvalue = '';
          for (var i = 0; i < cvalue.toString().length; i++) {
            if (ncvalue) ncvalue = ncvalue + '●';
            else ncvalue = '●';
          }
          cell.innerHTML = ncvalue;
        }
      }
    }
  };
  // グリッドのフォーマット処理
  const formatItem = (grid, e) => {
    if (e.panel.cellType == CellType.Cell) {
      var col = grid.columns[e.col];
      var dataItem = grid.rows[e.row].dataItem;
      if (dataItem.error && dataItem.error.includes(col.binding)) {
        e.cell.style.background = 'lightpink';
      } else {
        e.cell.style.background = '';
      }
    }
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        open={show}
        maxWidth={masterContext.table?.width ?? 'md'}
        disableEnforceFocus={true} // フォーカス制御を無効にする
      >
        <DialogTitle className="p-3">{masterContext.table?.tableDisplayName}</DialogTitle>
        <DialogContent className="px-0 py-0 pr-0 pl-0">
          <Card className="m-0">
            <Card.Body>
              <Row className="text-right">
                <Col className="m-0 p-0">
                  <Button
                    className="m-0 material-symbols-rounded cell-icon-sm btn-fill"
                    title="追加"
                    onClick={handlenOnClickAdd}
                  >
                    add
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col className="m-0 p-0 table-scroll" style={{ height: '500px' }}>
                  <FlexGrid
                    imeEnabled={true}
                    itemsSource={masterData}
                    headersVisibility={'Column'}
                    allowSorting={false}
                    autoGenerateColumns={false}
                    selectionMode={'Cell'}
                    keyActionTab={KeyAction.Cycle}
                    pastingCell={pastingCell}
                    beginningEdit={beginningEdit}
                    pasted={pasted}
                    cellEditEnded={cellEditEnded}
                    selectionChanged={gridSelectionChanged}
                    itemFormatter={itemFormatter}
                    formatItem={formatItem}
                  >
                    {masterContext.fields
                      ?.filter((f) => !f.hidden)
                      .map((f) => (
                        <FlexGridColumn
                          key={f.columnName}
                          header={f.columnDisplayName}
                          binding={f.columnName}
                          format={f.format}
                          cssClass={f.isPk ? 'cell-readonly' : ''}
                          width={f.width ?? '*'}
                          isReadOnly={
                            f.isPk || f.dataType == 'color' || f.dataType == 'labelSample'
                          }
                          inputType={f.inputType ?? (f.imeEnabled ? 'text' : 'tel')}
                          dataMap={f.dataMap ? dataMaps[f.dataMap] : null}
                        >
                          {
                            // カラーピッカー
                            f.dataType == 'color' ? (
                              <FlexGridCellTemplate
                                cellType="Cell"
                                template={(cell) => (
                                  <input
                                    type="color"
                                    value={cell.item[cell.col.binding]}
                                    onClick={(e) =>
                                      handleOnClickColor(e, cell, cell.item[f.columnName])
                                    }
                                  />
                                )}
                              />
                            ) : // ラベル見本
                            f.dataType == 'labelSample' ? (
                              <FlexGridCellTemplate
                                cellType="Cell"
                                template={(cell) => (
                                  <Chip
                                    label={'サンプル'}
                                    style={{
                                      minWidth: '62px',
                                      color: cell.item[f.fontColor],
                                      background: cell.item[f.color],
                                    }}
                                    size="small"
                                  />
                                )}
                              />
                            ) : null
                          }
                        </FlexGridColumn>
                      ))}
                    <FlexGridColumn header="　" binding="DEL_FLG" isReadOnly={true} width={28}>
                      <FlexGridCellTemplate
                        cellType="Cell"
                        template={(cell) => (
                          <IconButtonCompnent
                            className="cell-icon-sm"
                            variant="danger"
                            title="削除"
                            onClick={() => handleOnClickDelete(cell)}
                            icon="Clear"
                          ></IconButtonCompnent>
                        )}
                      />
                    </FlexGridColumn>
                  </FlexGrid>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </DialogContent>
        <DialogActions className="p-3" style={{ justifyContent: 'space-between' }}>
          <Button variant="secondary" className="footer-button" onClick={handleClose}>
            閉じる
          </Button>
          <Button variant="primary" className="footer-button btn-fill" onClick={() => handleSave()}>
            保存
          </Button>
        </DialogActions>
      </Dialog>
      {/** メッセージダイアログ */}
      <MessageModal dialog={message}></MessageModal>
      {/** カラーピッカー */}
      <ColorPickerModal params={colorPickerParams}></ColorPickerModal>
    </>
  );
}

export default MasterEditModal;
