import { DataMap, CellType, KeyAction } from '@grapecity/wijmo.grid';
import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import TextComponent from 'components/Input/TextComponent';
import { useMaster } from 'context/MasterContext';
import { useRestApi } from 'context/RestApiContext';
import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { Exists } from 'constants/constants';

/** 請求情報の確定画面 */
function PaymentFixed(props) {
  const paymentMonth = props.paymentMonth;
  const toriDataMap = props.toriDataMap;
  const setDialog = props.setDialog;

  const location = useLocation();
  const navigate = useNavigate();

  const master = useMaster();
  const restApi = useRestApi();

  var errorMap = new DataMap(Exists, 'key', 'value');

  // 修正前のオリジナルデータ
  const orgPayments = useRef({});
  // 変更データ
  const updDataList = useRef([]);

  // リロード用データ
  const [reload, setReload] = useState('');

  // 請求データ
  const [payments, setPayments] = useState([]);

  // 工事選択用のマッピング
  const constructionsMap = new DataMap(
    [
      { SEIKYU_NO: -1, KOUJI_MEI: '　' },
      // 支払用工事
      ...master.paymentKouji.map((x) => ({ SEIKYU_NO: x.id, KOUJI_MEI: x.name })),
      ...props.constructions.map((x) => ({
        SEIKYU_NO: x.SEIKYU_NO,
        KOUJI_MEI: x.SEIKYU_NO + ': ' + x.KOUJI_MEI + ' ' + x.ATESAKI,
      })),
    ],
    'SEIKYU_NO',
    'KOUJI_MEI'
  );

  // 完了済みの工事の請求番号
  const completeSeikyuNos = props.constructions
    .filter((x) => x.COMPLETE_FLG == 1)
    .map((x) => x.SEIKYU_NO);

  // 初期表示処理
  useEffect(() => {
    restApi.post('/api/payment/list', { paymentMonth: paymentMonth }, (data) => {
      setPayments(data.payments);
      orgPayments.current = [...data.payments.map((x) => ({ ...x }))];
    });
  }, []);

  // 貼り付け後の処理
  const pasted = (grid, args) => {
    cellEditEnded(grid, args);
  };
  // 編集終了処理
  const cellEditEnded = (grid, args) => {
    var dataItem = grid.rows[args.row].dataItem;
    var col = grid.columns[args.col];
    // 変更データを保持
    appendUpdData(dataItem, col['binding']);
  };
  // グリッドのフォーマット処理
  const formatItem = (grid, e) => {
    if (e.panel.cellType == CellType.Cell) {
      var col = grid.columns[e.col];
      var dataItem = grid.rows[e.row].dataItem;

      if (col.binding) {
        // 変更列は青く表示
        let isUpd = dataItem.PAYMENT_ID == -1 || dataItem.updColumns?.includes(col.binding);
        let isError = dataItem.ERROR_FLG == 1;
        e.cell.style.color = isError ? 'red' : isUpd ? 'blue' : '';

        e.cell.style.fontWeight = isUpd ? 'bold' : '';
        e.cell.style.background = isUpd ? '#DDEEFF' : '';
      }
    }
  };

  // 変更データを退避処理
  const appendUpdData = (dataItem, binding) => {
    const org = orgPayments.current.find((o) => o.PAYMENT_ID === dataItem.PAYMENT_ID) || {};

    // 変更列のリストを更新
    if ((org[binding] ?? '') === (dataItem[binding] ?? '')) {
      dataItem['updColumns'] = (dataItem['updColumns'] ?? []).filter((x) => x != binding);
    } else if (!(dataItem['updColumns'] ?? []).includes(binding)) {
      dataItem['updColumns'] = [...(dataItem['updColumns'] ?? []), binding];
    }

    // エラーフラグを設定
    dataItem.ERROR_FLG =
      dataItem['updColumns'].length == 0
        ? org.ERROR_FLG
        : completeSeikyuNos.includes(dataItem.SEIKYU_NO)
        ? 1
        : 0;

    // 変更データリストの更新
    updDataList.current = updDataList.current.filter((u) => u.PAYMENT_ID !== dataItem.PAYMENT_ID);
    if (dataItem.PAYMENT_ID === -1 || dataItem['updColumns'].length) {
      updDataList.current.push(dataItem);
    }

    // 画面の再レンダリング
    setReload(Date.now());
  };

  // 戻るボタン押下処理
  const handleOnClickBack = () => {
    // 変更無しの場合はそのまま戻る
    if (!updDataList.current.length) {
      props.handleBack();
    } else {
      setDialog({
        type: 'confirm',
        message: `修正内容は破棄されます。よろしいですか？`,
        okCallBack: () => {
          props.handleBack();
        },
      });
    }
  };

  // 保存ボタン押下
  const handleOnClickSave = () => {
    let param = {
      paymentMonth: paymentMonth,
      payments: updDataList.current,
      orgPayments: orgPayments.current.filter(
        (o) => o.SEIKYU_NO > 0 && updDataList.current.some((u) => u.PAYMENT_ID == o.PAYMENT_ID)
      ),
    };
    restApi.post('/api/payment/fixed', param, (data) => {
      setPayments(payments.map((x) => ({ ...x, updColumns: [] })));
      orgPayments.current = [...payments.map((x) => ({ ...x }))];
      updDataList.current = [];
      setDialog({
        type: 'info',
        message: `原価情報を保存しました。`,
      });
    });
  };

  return (
    <>
      <Row className="pt-4">
        <Col className="pr-1">・請求情報に対する工事を選択してください。</Col>
      </Row>
      <Row className="pt-2">
        <Col className="pr-1" md="2">
          <Form.Group>
            <label>請求年月</label>
            <TextComponent value={paymentMonth} disabled={true} type="date" selectionMode="Month" />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <div className="table-scroll" style={{ height: 'calc(100vh - 342px)' }}>
            <FlexGrid
              imeEnabled={true}
              itemsSource={payments}
              selectionMode={'Cell'}
              headersVisibility={'Column'}
              autoGenerateColumns={false}
              allowSorting={true}
              style={{ height: '100%' }}
              cellEditEnded={cellEditEnded}
              pasted={pasted}
              formatItem={formatItem}
              keyActionTab={KeyAction.Cycle}
            >
              <FlexGridColumn
                binding="MONTH"
                header="請求年月"
                width={80}
                cssClass="cell-readonly"
                isReadOnly={true}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="TRI_CD"
                header="取引先"
                dataMap={toriDataMap}
                width={'1*'}
                cssClass="cell-readonly"
                isReadOnly={true}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="ITEM_NAME"
                header="商品名"
                width={'1*'}
                cssClass="cell-readonly"
                isReadOnly={true}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="SU"
                header="数量"
                dataType="Number"
                format="n1"
                width={100}
                cssClass="cell-readonly"
                isReadOnly={true}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="TANI"
                header="単位"
                width={50}
                cssClass="cell-readonly"
                isReadOnly={true}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="TANKA"
                header="単価"
                dataType="Number"
                width={100}
                cssClass="cell-readonly"
                isReadOnly={true}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="KINGAKU"
                header="小計"
                dataType="Number"
                cssClass="cell-readonly"
                isReadOnly={true}
                width={120}
              ></FlexGridColumn>
              {/* <FlexGridColumn
                binding="ERROR_FLG"
                header="エラー"
                width={80}
                dataMap={errorMap}
              ></FlexGridColumn> */}
              <FlexGridColumn
                binding="SEIKYU_NO"
                header="工事"
                dataMap={constructionsMap}
                width={'2*'}
              ></FlexGridColumn>
            </FlexGrid>
          </div>
        </Col>
      </Row>
      <Row className="pt-2">
        <Col className="d-flex justify-content-between">
          <Button variant="secondary" className="footer-button" onClick={handleOnClickBack}>
            戻る
          </Button>
          <Button variant="primary" className="btn-fill footer-button" onClick={handleOnClickSave}>
            保存
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default PaymentFixed;
