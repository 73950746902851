import { Chip } from '@mui/material';
import { NYUKIN_STATUS } from 'components/Input/NyukinLabelComponent';

// 工程
export const PROCESS = {
  IN_PROGRESS: 1, // 作成中
  MEETING_SCHEDULED: 2, // 打ち合わせ予定
  CONSTRUCTION_SCHEDULED: 3, // 工事予定
  UNDER_CONSTRUCTION: 4, // 工事中
  WARRANTY_ISSUED: 5, // 保証書発行済
  INVOICE_ISSUED: 6, // 請求書発行済
  ON_HOLD: 7, // 保留
  LOST_ORDER: 8, // 失注
  COMPLETED: 9, // 完了
};

// 選択可能なプロセスを取得
export const getProcess = (master, now, nyukin, isKei) => {
  let processes = [];
  // 契約前
  if (!isKei) {
    processes = [
      PROCESS.IN_PROGRESS, // 工事中
      PROCESS.MEETING_SCHEDULED, // 打合せ予定
      PROCESS.CONSTRUCTION_SCHEDULED, // 工事予定
      PROCESS.ON_HOLD, // 保留
    ];
  }
  // 契約後
  else {
    processes = [
      PROCESS.CONSTRUCTION_SCHEDULED, // 工事予定
      PROCESS.UNDER_CONSTRUCTION, // 工事中
      PROCESS.WARRANTY_ISSUED, // 保証書発行済
      PROCESS.INVOICE_ISSUED, // 請求書発行済
    ];
  }

  // 現在の値は選択可能
  processes.push(now);

  // 現工程が保留の場合は、失注を選択可能
  if (now == PROCESS.ON_HOLD) {
    processes.push(PROCESS.LOST_ORDER);
  }

  // 現工程が請求書発行済、かつ、入金済の場合、完了を選択可能
  if (now == PROCESS.INVOICE_ISSUED && nyukin == NYUKIN_STATUS.ALL) {
    processes.push(PROCESS.COMPLETED);
  }

  return master.process.filter((x) => processes.some((y) => y == x.PROCESS_CD));
};

// 工程ラベルコンポーネント
export const ProcessLableComponent = (props) => {
  // 工程情報
  const process = props.process;

  return (
    <Chip
      className="m-0"
      style={{
        width: props.width,
        minWidth: '72px',
        color: props.isDisabled ? '#172b4d' : process?.FONT_COLOR,
        backgroundColor: props.isDisabled ? '#e4e4e4' : process?.COLOR,
      }}
      size={props.size}
      label={process?.DISPLAY}
      onClick={props.onClick ? () => props.onClick() : null}
    />
  );
};
