import React, { useContext, useState, useEffect, createContext } from 'react';

import { useAuth } from 'context/AuthContext';
import { useRestApi } from 'context/RestApiContext';

const MasterContext = createContext();

/**
 * マスタ情報プロバイダ
 * @param {*} 子要素
 * @returns マスタ情報プロバイダ
 */
export function MasterContextProvider({ children }) {
  const restApi = useRestApi();
  // 社員
  const [staff, setStaff] = useState([]);
  // プランナー
  const [planer, setPlaner] = useState([]);
  // ハウスメーカ
  const [houseMaker, setHouseMaker] = useState([]);
  // 集客要因区分
  const [channelKbn, setChannelKbn] = useState([]);
  // 集客要因
  const [channelCd, setChannelCd] = useState([]);
  // 工事種別
  const [kojiSyu, setKojiSyu] = useState([]);
  // ラベル
  const [label, setLabel] = useState([]);
  // 工程
  const [process, setProcess] = useState([]);
  // 事業
  const [jigyo, setJigyo] = useState([]);
  // 支払用工事
  const [paymentKouji, setPaymentKouji] = useState([]);

  // 前受金の比率情報
  const [maeukeRates, setMaeukeRates] = useState([]);

  useEffect(() => {
    restApi.get('/api/master/list', (data) => {
      // 社員
      setStaff(data['staff']);
      // プランナー（全社員を選択可能に変更）
      // setPlaner(data["staff"].filter(s => s["kbn"] === '1'));
      setPlaner(data['staff']);
      // ハウスメーカ
      setHouseMaker(data['company']);
      // 集客チャネル区分
      setChannelKbn(data['channelkbn']);
      // 集客チャネル
      setChannelCd(data['channel']);
      // 工事種別
      setKojiSyu(data['code'].filter((s) => s['kbn'] == 3));
      // ラベル
      setLabel(data['label']);
      // 工程
      setProcess(data['process']);
      // 事業
      setJigyo(data['jigyo']);
      // 前受金の比率情報
      setMaeukeRates(data['maeuke_rates']);
      // 支払用工事
      setPaymentKouji(data['payment_kouji']);
    });
  }, []);

  // マスタの再読み込み処理
  const reloadMaster = () => {
    restApi.get('/api/master/list', (data) => {
      // 社員
      setStaff(data['staff']);
      // プランナー（全社員を選択可能に変更）
      // setPlaner(data["staff"].filter(s => s["kbn"] === '1'));
      setPlaner(data['staff']);
      // ハウスメーカ
      setHouseMaker(data['company']);
      // 集客チャネル区分
      setChannelKbn(data['channelkbn']);
      // 集客チャネル
      setChannelCd(data['channel']);
      // 工事種別
      setKojiSyu(data['code'].filter((s) => s['kbn'] == 3));
      // ラベル
      setLabel(data['label']);
      // 工程
      setProcess(data['process']);
      // 事業
      setJigyo(data['jigyo']);
      // 前受金の比率情報
      setMaeukeRates(data['maeuke_rates']);
      // 支払用工事
      setPaymentKouji(data['payment_kouji']);
    });
  };

  return (
    <MasterContext.Provider
      value={{
        staff,
        planer,
        houseMaker,
        channelKbn,
        channelCd,
        kojiSyu,
        label,
        process,
        jigyo,
        reloadMaster,
        maeukeRates,
        paymentKouji,
      }}
    >
      {children}
    </MasterContext.Provider>
  );
}

/**
 * マスタ情報を取得
 * @returns マスタ情報
 */
export function useMaster() {
  const context = useContext(MasterContext);
  if (context == undefined) {
    throw new Error('マスタ情報の取得に失敗しました。');
  }
  return context;
}
