import { DataMap } from '@grapecity/wijmo.grid';
import { CellType } from '@grapecity/wijmo.grid';
import { CollectionView } from '@grapecity/wijmo';
import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { useMaster } from 'context/MasterContext';
import { useRestApi } from 'context/RestApiContext';
import { useAuth } from 'context/AuthContext';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ProcessLableComponent, PROCESS } from 'components/Input/ProcessLableComponent';
import { NyukinLabelComponent, NYUKIN_STATUS } from 'components/Input/NyukinLabelComponent';
import { GenkaError } from 'constants/constants';
import KoujiCommentTab from 'views/KoujiDaicho/KoujiComment';
import KoujiCompleteModal from 'views/KoujiDaicho/KoujiCompleteModal';
import './KoujiDaicho.css';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import Tooltip from '@mui/material/Tooltip';

/** 工事状況モーダル画面 */
function KoujiDaichoList(props) {
  const auth = useAuth();

  const restApi = useRestApi();
  const master = useMaster();
  var planerMap = new DataMap(master.planer, 'id', 'name');

  const processDict = master.process.reduce((acc, item) => {
    acc[item.PROCESS_CD] = item; // `id` をキーに設定
    return acc;
  }, {});

  // 注文一覧情報
  const [orderList, setOrderList] = useState(new CollectionView());
  // 請求一覧情報
  const [paymentList, setPaymentList] = useState(new CollectionView());
  // 工事コメント情報
  const [koujiComments, setKoujiComments] = useState([]);
  // 工事情報
  const [koujiInfo, setKoujiInfo] = useState(new CollectionView([{}]));
  // 表示
  const [show, setShow] = useState(false);
  // 警告メッセージ
  const [warningMessage, setWarningMessage] = useState('');
  // エラーメッセージ
  const [errorMessage, setErrorMessage] = useState('');
  // エラー確認チェックボックス
  const [confirmErrror, setConfirmError] = useState(false);
  const [selectedTab, setSelectedTab] = useState('kouji');

  const [koujiCompleteModalParam, setKoujiCompleteModalParam] = useState({});

  // 初期表示
  useEffect(() => {
    if (props.params?.show) {
      console.log(props.params.item.COMPLETE_FLG);
      restApi.get(
        `/api/kouji/${props.params.item.CLIENT_CD}/${props.params.item.SEIKYU_NO}`,
        (data) => {
          // 注文一覧
          setOrderList(new CollectionView(data.orderList));
          // 請求一覧
          setPaymentList(new CollectionView(data.paymentList));
          // 工事情報
          setKoujiInfo(new CollectionView([props.params.item]));
          // 工事コメント
          setKoujiComments(data.koujiComments);

          // 警告メッセージ（管理者のみ表示）
          const _warnMessage =
            props.params.item.COMPLETE_FLG == 1 || !auth.auth.kbn?.some((k) => k == 'admin')
              ? ''
              : props.params.item.PROCESS_CD != PROCESS.COMPLETED
              ? '工程が完了でないため、完了処理はおこなえません。'
              : props.params.item.NYUKIN_STATUS != NYUKIN_STATUS.ALL
              ? '入金済みでないため、完了処理はおこなえません。'
              : '';
          setWarningMessage(_warnMessage);
          // エラーメッセージ
          const _errorMessage =
            props.params.item.ERROR == GenkaError.AFTER_COMPLETION
              ? '完了後に請求が発生しています。'
              : props.params.item.ERROR == GenkaError.LESS_THAN_25
              ? '利益率が25%以下です。'
              : props.params.item.ARARI_RITSU >= 0.45
              ? '利益率が45%以上です。'
              : '';
          setErrorMessage(_errorMessage);
          // エラー確認チェックボックス(エラーがない場合はチェック済み)
          setConfirmError(props.params.item.COMPLETE_FLG == 1 || _errorMessage === '');
          // 工事タブを選択
          setSelectedTab('kouji');

          setShow(true);
        }
      );
    }
  }, [props.params]);

  // モーダルの閉じる処理
  const handleClose = () => {
    setShow(false);
  };

  // 完了処理
  const handleOnComplete = () => {
    const completeFlg = 1;
    setKoujiCompleteModalParam({
      show: true,
      okCallBack: (comment) => {
        restApi.post(
          `/api/kouji/complete/${props.params.item.SEIKYU_NO}`,
          { COMPLETE_FLG: completeFlg, COMMENT: comment },
          (data) => {
            props.params.callback(completeFlg);
            setShow(false);
          }
        );
      },
    });
  };

  // 完了取り消し処理
  const handleOnCancelComplete = () => {
    const completeFlg = 0;
    restApi.post(
      `/api/kouji/complete/${props.params.item.SEIKYU_NO}`,
      { COMPLETE_FLG: completeFlg },
      (data) => {
        props.params.callback(completeFlg);
        setShow(false);
      }
    );
  };

  // タブの選択処理
  const handleOnClickTab = (tab) => {
    setSelectedTab(tab);
  };

  // グリッドのフォーマット処理
  const formatItem = (grid, e) => {
    if (e.panel.cellType == CellType.Cell) {
      const dataItem = grid.rows[e.row].dataItem;
      // エラーの場合は文字を赤色
      if (dataItem.ERROR_FLG == 1) {
        e.cell.style.color = 'red';
      } else {
        e.cell.style.background = '';
      }
    }
  };

  // 金額グリッドのフォーマット処理
  const formatItemKingaku = (grid, e) => {
    if (e.panel.cellType == CellType.Cell) {
      const col = grid.columns[e.col];

      // 粗利が25%以下または45%以上の場合は文字色を赤
      if (col.binding == 'ARARI_RITSU') {
        const dataItem = grid.rows[e.row].dataItem;
        if (dataItem.ARARI_RITSU < 0.25 || dataItem.ARARI_RITSU > 0.45) {
          e.cell.style.color = 'red';
        } else {
          e.cell.style.color = '';
        }
      }
    }
  };

  return (
    <>
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '1020px',
            },
          },
        }}
        open={show}
      >
        <DialogTitle className="p-3">{props.params?.item?.KOUJI_MEI}</DialogTitle>
        <DialogContent className="px-0 py-0 pr-0 pl-0">
          <Card className="m-0">
            <Card.Body className="p-3">
              {errorMessage && (
                <Row className="mt-0">
                  <Col>
                    <div style={{ color: 'red' }}>
                      <ErrorRoundedIcon className="mr-1 mb-1" />
                      {errorMessage}
                    </div>
                  </Col>
                </Row>
              )}
              {warningMessage && (
                <Row className="mt-0">
                  <Col>
                    <div style={{ color: 'orange' }}>
                      <WarningAmberRoundedIcon className="mr-1 mb-1" />
                      {warningMessage}
                    </div>
                  </Col>
                </Row>
              )}
              {!warningMessage && errorMessage && auth.auth.kbn?.some((k) => k == 'admin') && (
                <Row className="mt-0 mb-2">
                  <Col>
                    <label style={{ fontSize: '12pt', color: confirmErrror ? 'blue' : '#5e5e5e' }}>
                      <input
                        className="mr-2 ml-1"
                        style={{ transform: 'scale(1.3)' }}
                        type="checkbox"
                        checked={confirmErrror}
                        disabled={props.params?.item?.COMPLETE_FLG == 1}
                        onChange={(e) => {
                          setConfirmError(e.target.checked);
                        }}
                      ></input>
                      <span className="ml-1">エラーの内容を確認済みです</span>
                    </label>
                  </Col>
                </Row>
              )}
              <div style={{ display: 'flex' }}>
                <div
                  className={`pl-2 kouji-panel-label ${selectedTab == 'kouji' && 'selected'}`}
                  onClick={() => handleOnClickTab('kouji')}
                >
                  <i className="material-symbols-rounded">construction</i>
                  工事情報
                </div>
                <div
                  className={`pl-2 kouji-panel-label ${selectedTab == 'comment' && 'selected'}`}
                  onClick={() => handleOnClickTab('comment')}
                >
                  <i className="material-symbols-rounded">comment</i>
                  コメント({koujiComments.length}件)
                </div>
              </div>
              {
                // 工事情報タブ
                selectedTab == 'kouji' && (
                  <div className="kouji-panel-tab" style={{ minHeight: '500px' }}>
                    <Row>
                      <Col className="pl-3">
                        <FlexGrid
                          className="kouji-daicho-grid"
                          itemsSource={koujiInfo}
                          selectionMode={'None'}
                          headersVisibility={'Column'}
                          isReadOnly={true}
                          allowSorting={false}
                        >
                          <FlexGridColumn
                            binding="SEIKYU_NO"
                            header="工事No"
                            format={'d'}
                            width={68}
                          ></FlexGridColumn>
                          <FlexGridColumn
                            binding="PRCESS_CD"
                            header="工程"
                            width={100}
                            allowResizing={false}
                            align="center"
                          >
                            <FlexGridCellTemplate
                              binding="PRCESS_CD"
                              cellType="Cell"
                              template={(cell) => (
                                <ProcessLableComponent
                                  process={processDict[cell.item.PROCESS_CD]}
                                  size="small"
                                />
                              )}
                            />
                          </FlexGridColumn>
                          <FlexGridColumn
                            binding="NYUKIN_STATUS"
                            header="入金状況"
                            align={'center'}
                            width={88}
                          >
                            <FlexGridCellTemplate
                              cellType="Cell"
                              template={(cell) => (
                                <NyukinLabelComponent
                                  nyukin={cell.item.NYUKIN_STATUS}
                                  size="small"
                                />
                              )}
                            />
                          </FlexGridColumn>
                          <FlexGridColumn
                            binding="KEI_DATE"
                            header="契約日"
                            width={88}
                          ></FlexGridColumn>
                          <FlexGridColumn
                            binding="TAN_CD"
                            header="担当"
                            dataMap={planerMap}
                            width={68}
                          ></FlexGridColumn>
                          <FlexGridColumn
                            binding="CL_NAME"
                            header="顧客名"
                            width={'*'}
                          ></FlexGridColumn>
                          <FlexGridColumn
                            binding="KOUJI_MEI"
                            header="工事名"
                            width={'*'}
                          ></FlexGridColumn>
                        </FlexGrid>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col>
                        <div>注文一覧</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div
                          className="table-scroll"
                          style={{
                            minHeight: '100px',
                            maxHeight: '200px',
                            height: '100%',
                          }}
                        >
                          <FlexGrid
                            itemsSource={orderList}
                            selectionMode={'None'}
                            headersVisibility={'Column'}
                            isReadOnly={true}
                          >
                            <FlexGridColumn
                              binding="CYU_DATE"
                              header="注文日"
                              width={95}
                            ></FlexGridColumn>
                            <FlexGridColumn
                              binding="KOUJI_MEI"
                              header="工事名"
                              width="*"
                              allowResizing={false}
                            ></FlexGridColumn>
                            <FlexGridColumn
                              binding="TRI_NAME"
                              header="注文先"
                              width={320}
                              allowResizing={false}
                            ></FlexGridColumn>
                            <FlexGridColumn
                              binding="CYU_KIN"
                              header="金額(税抜)"
                              width={100}
                              align={'right'}
                            ></FlexGridColumn>
                          </FlexGrid>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col>
                        <div>請求一覧</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div
                          className="table-scroll"
                          style={{
                            minHeight: '100px',
                            maxHeight: '200px',
                            height: '100%',
                          }}
                        >
                          <FlexGrid
                            itemsSource={paymentList}
                            selectionMode={'None'}
                            headersVisibility={'Column'}
                            isReadOnly={true}
                            formatItem={formatItem}
                          >
                            <FlexGridColumn
                              binding="MONTH"
                              header="請求年月"
                              width={95}
                            ></FlexGridColumn>
                            <FlexGridColumn
                              binding="SEIKYU_FIX_MONTH"
                              header="工事割当月"
                              width={95}
                            ></FlexGridColumn>

                            <FlexGridColumn
                              binding="ITEM_NAME"
                              header="商品名"
                              width="*"
                              allowResizing={false}
                            ></FlexGridColumn>
                            <FlexGridColumn
                              binding="TRI_NAME"
                              header="取引先"
                              width={320}
                              allowResizing={false}
                            ></FlexGridColumn>
                            <FlexGridColumn
                              binding="KINGAKU"
                              header="金額(税抜)"
                              width={100}
                              align={'right'}
                            ></FlexGridColumn>
                          </FlexGrid>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )
              }
              {
                // コメントタブ
                selectedTab == 'comment' && (
                  <KoujiCommentTab
                    kouji={props.params.item}
                    koujiComments={koujiComments}
                    setKoujiComments={setKoujiComments}
                  ></KoujiCommentTab>
                )
              }
            </Card.Body>
          </Card>
        </DialogContent>
        <DialogActions className="p-3 d-flex justify-content-between">
          {/* 管理者の場合、閉じるボタンを左側に表示 */}
          {auth.auth.kbn?.some((k) => k == 'admin') && (
            <Button variant="secondary" className="footer-button" onClick={handleClose}>
              閉じる
            </Button>
          )}

          <div style={{ width: '600px' }}>
            <FlexGrid
              itemsSource={koujiInfo}
              selectionMode={'None'}
              headersVisibility={'Column'}
              isReadOnly={true}
              allowSorting={false}
              formatItem={formatItemKingaku}
            >
              <FlexGridColumn
                binding="MITSU_KIN"
                header="売上金額(税抜)"
                format="c0"
                width={'1*'}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="QUOTE_GENKA"
                header="当初予定原価(税抜)"
                format="c0"
                width={'1*'}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="CYU_GENKA"
                header="注文金額(税抜)"
                format="c0"
                width={'1*'}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="SEIKYU_GENKA"
                header="請求金額(税抜)"
                format="c0"
                width={'1*'}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="ARARI_RITSU"
                header="利益率"
                format="P2"
                width={80}
              ></FlexGridColumn>
            </FlexGrid>
          </div>
          {/* 管理者の場合、完了処理が可能 */}
          {auth.auth.kbn?.some((k) => k == 'admin') ? (
            props.params?.item?.COMPLETE_FLG == 0 ? (
              <Tooltip title={warningMessage} placement="top" arrow>
                <Button
                  variant="primary"
                  className="footer-button btn-fill"
                  disabled={!confirmErrror || warningMessage}
                  onClick={() => {
                    handleOnComplete();
                  }}
                >
                  完了
                </Button>
              </Tooltip>
            ) : (
              <Button
                variant="warning"
                className="footer-button btn-fill"
                onClick={() => {
                  handleOnCancelComplete();
                }}
              >
                未完了に戻す
              </Button>
            )
          ) : (
            // 一般ユーザーの場合、閉じるボタンを右側に表示
            <Button variant="secondary" className="footer-button" onClick={handleClose}>
              閉じる
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {/* 工事完了モーダル */}
      <KoujiCompleteModal params={koujiCompleteModalParam}></KoujiCompleteModal>
    </>
  );
}

export default KoujiDaichoList;
