import Big from 'big.js';
import { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { CellType, KeyAction } from '@grapecity/wijmo.grid';

import '@grapecity/wijmo.styles/wijmo.css';

// react-bootstrap components
import { Button, Card, Form, Row, Col } from 'react-bootstrap';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { useRestApi } from 'context/RestApiContext';

import { MessageModal } from 'components/modal/MessageModal';
import TextComponent from 'components/Input/TextComponent';
import SelectComponent from 'components/Input/SelectComponent';
import IconButtonCompnent from 'components/Input/IconButtonCompnent';

import { toFloat, toInt } from 'utils/numberUtils';

function QuoteBaseModal(props) {
  const restApi = useRestApi();

  const flexGrid = useRef();

  // 確認ダイアログ
  const [dialog, setDialog] = useState({});

  // 見積基盤全データ
  const [baseQuotes, setBaseQuotes] = useState({});
  // 工事種類一覧
  const [kojiSyuList, setKojiSyuList] = useState([]);

  // 工事種別の選択方法
  const [kojisyuMethod, setKojisyuMethod] = useState('base');
  // 工事種別の手入力
  const [kojisyuHand, setKojisyuHand] = useState('');

  // 選択中の工事種別（見積基盤）
  const [selectedKojiSyu, setSelectedKojiSyu] = useState('');
  // 選択中の工事種別の見積基盤データ
  const [selectedKojiSyuBaseQuotes, setSelectedKojiSyuBaseQuotes] = useState([]);
  // 登録明細
  const [editDetails, setEditDetails] = useState([]);

  // 選択中の見積もり明細
  const [selectedRow, setSelectedRow] = useState(-1);

  // 追加請求かどうか？
  const [isAddInvoice, setAddInvoice] = useState(false);

  // 合計
  const [total, setTotal] = useState([]);

  // グルーピング処理
  const groupBy = (array, getKey) =>
    array.reduce((obj, cur, idx, src) => {
      const key = getKey(cur, idx, src);
      (obj[key] || (obj[key] = [])).push(cur);
      return obj;
    }, {});

  // 初期表示
  useEffect(() => {
    // 見積基盤の全データを取得
    restApi.get('/api/baseQuote/list', (data) => {
      // 工事種別毎の連想配列に変換に変換
      const _baseQuotes = groupBy(data, (d) => d['KOUJI_SYU']);
      setBaseQuotes(_baseQuotes);
    });
  }, []);

  // 工事種別の変更処理
  useEffect(() => {
    if (selectedKojiSyu) {
      // 選択中の工事種のみ表示
      setSelectedKojiSyuBaseQuotes(
        baseQuotes[selectedKojiSyu].map((x) => ({
          ...x,
          KINGAKU: 0,
          Check: editDetails.length > 0 && editDetails.some((y) => x['BASE_NO'] == y['BASE_NO']),
        }))
      );
    }
  }, [selectedKojiSyu]);

  // モーダルを表示時の処理
  useEffect(() => {
    if (props.show) {
      // 明細を未選択状態に
      setSelectedRow(-1);

      // 追加請求かどうか？
      setAddInvoice(props.paramBaseQuote.isAddInvoice);

      // 追加モードの場合
      if (props.paramBaseQuote.Mode == 'add') {
        // 工事種別一覧を取得（編集中の工事種は除く）
        const _kojiSyuList = Object.keys(baseQuotes).filter(
          (x) => !props.paramBaseQuote.KojiSyu.some((y) => y == x)
        );
        setKojiSyuList(_kojiSyuList);

        // 工事種別を選択
        if (_kojiSyuList.length > 0) {
          // 初回の場合は土工事
          setSelectedKojiSyu(
            props.paramBaseQuote.IsInit && _kojiSyuList.some((x) => x == '土工事')
              ? '土工事'
              : _kojiSyuList[0]
          );
        }
        setEditDetails([]);
      }
      // 編集モードの場合
      else {
        // 見積基盤からの選択の場合
        if (Object.keys(baseQuotes).some((x) => x == props.paramBaseQuote.TargetKojiSyu)) {
          // 工事種一覧は固定
          setKojiSyuList([props.paramBaseQuote.TargetKojiSyu]);
          // 選択された工事を設定
          setSelectedKojiSyu(props.paramBaseQuote.TargetKojiSyu);
          // 編集中の明細を設定
          setEditDetails([...props.paramBaseQuote.Details].map((x) => ({ ...x })));
        }
        // 手入力の場合
        else {
          setKojisyuMethod('hand');
          setKojisyuHand(props.paramBaseQuote.TargetKojiSyu);
          setEditDetails(props.paramBaseQuote.Details);

          // 工事種別一覧を取得（編集中の工事種は除く）
          const _kojiSyuList = Object.keys(baseQuotes).filter(
            (x) => !props.paramBaseQuote.KojiSyu.some((y) => y == x)
          );
          setKojiSyuList(_kojiSyuList);
          // 一件目の工事種別を選択
          if (_kojiSyuList.length > 0) {
            setSelectedKojiSyu(_kojiSyuList[0]);
          }
        }
      }
    }
  }, [props.show]);

  // 明細の変更処理
  useEffect(() => {
    calcTotal(editDetails);
  }, [editDetails]);

  // 合計の計算処理
  const calcTotal = (details) => {
    // 小計
    let shokei =
      details.reduce((a, x) => a + toFloat(x['KINGAKU']), 0) + props.paramBaseQuote.shokei;
    // 消費税
    let tax = Math.floor(shokei * 0.1);
    // 請求額
    let seikyu = shokei + tax;
    // 原価合計
    let genka_kingaku =
      details.reduce((a, x) => a + toFloat(x['GENKA_KINGAKU']), 0) + props.paramBaseQuote.genkaKei;
    // 粗利
    let arari = shokei - genka_kingaku;
    // 粗利率
    let arariritu = Math.floor((arari / shokei) * 100) / 100;

    // 集計結果を反映
    setTotal([
      {
        ShoKei: shokei,
        ZeiKei: tax,
        SeikyuKei: seikyu,
        GenkaKei: genka_kingaku,
        ArariKei: arari,
        RiekiRitsu: !isNaN(arariritu) ? arariritu : 0,
      },
    ]);
  };

  // 見積基盤の選択処理
  const handleOnSelectBaseQuote = (item) => (e) => {
    item['Check'] = e.target.checked;
    if (e.target.checked) {
      setEditDetails([
        ...editDetails,
        {
          ...item,
          KINGAKU: calcItem(item, 'KINGAKU'),
          GENKA_KINGAKU: calcItem(item, 'GENKA_KINGAKU'),
        },
      ]);
    } else {
      setEditDetails(editDetails.filter((x) => x['BASE_NO'] != item['BASE_NO']));
    }
  };

  // 明細の削除処理
  const handleOnClickDelete = (item) => (e) => {
    // 登録用の明細から削除
    setEditDetails(editDetails.filter((x) => x != item));
    // 見積基盤の明細のチェックを外す
    setSelectedKojiSyuBaseQuotes(
      selectedKojiSyuBaseQuotes.map((x) => ({
        ...x,
        Check: item['BASE_NO'] == x['BASE_NO'] ? false : x.Check,
      }))
    );
  };

  // 明細の移動処理
  const handleOnClickMove = (item, upDown) => (e) => {
    item = selectedRow;
    // 移動位置を取得
    let idx = editDetails.indexOf(item) + (upDown == 'down' ? 1 : -1);
    if (idx < 0) {
      return;
    }
    let _editDetails = editDetails.filter((x) => x != item);
    _editDetails.splice(idx, 0, item);
    setEditDetails(_editDetails);
  };

  // 登録明細の追加ボタン
  const handlenOnClickAdd = () => {
    setEditDetails([
      ...editDetails,
      {
        BASE_NO: 0,
        KOUJI_SYU: selectedKojiSyu,
        KINGAKU: 0,
        GENKA_RATE: 0,
        GENKA_KINGAKU: 0,
        EDITABLE_FLG: '1',
      },
    ]);
  };

  // 工事種別ラジオボタンの変更処理
  const handleOnChangeKojisyuMethod = (event) => {
    setKojisyuMethod(event.target.value);
  };

  // 工事種別の手入力の変更処理
  const handleOnChangeKojisyuHand = (target) => {
    setKojisyuHand(target.value);
  };

  // 工事種類の変更処理
  const handleChangeKojisyu = (target) => {
    setSelectedKojiSyu(target.value);
  };

  // 確定ボタン押下処理
  const handleOnClickFix = () => {
    // 工事種別が手入力が未入力の場合はエラー
    if (kojisyuMethod == 'hand' && !kojisyuHand) {
      setTimeout(function () {
        setDialog({
          type: 'error',
          message: '工事種別を入力してください。',
        });
      }, 0);
      return;
    }

    let kojisyu = kojisyuMethod == 'base' ? selectedKojiSyu : kojisyuHand;
    props.callbakQuoteDetaiModal(
      [...editDetails].map((x) => ({ ...x, KOUJI_SYU: kojisyu })),
      props.paramBaseQuote?.TargetKojiSyu
    );
    handleClose();
  };

  // 閉じるボタン処理
  const handleClose = () => {
    // 入力値を初期値に戻す
    setKojisyuMethod('base');
    setKojisyuHand('');
    setSelectedKojiSyu('');
    setSelectedKojiSyuBaseQuotes([]);
    setEditDetails([]);

    props.setShow(false);
  };

  // 貼り付け処理（編集開始処理をおこなう）
  const pastingCell = (s, args) => {
    beginningEdit(s, args);
  };
  // 編集開始処理
  const beginningEdit = (grid, args) => {
    var dataItem = grid.rows[args.row].dataItem;
    var col = grid.columns[args.col];

    // 注文済の場合は非活性
    if (dataItem?.CYU_MNO_KEY) {
      args.cancel = true;
    }
    // 見積基盤から選択した項目の場合
    else if (dataItem && dataItem['BASE_NO']) {
      // 工事種、内容、金額、適用、原価金額、社内備考は編集不可
      var readOnlys = ['KOUJI_SYU', 'CONTENT', 'KINGAKU', 'BIKO', 'GENKA_KINGAKU', 'BIKO_SYANAI'];
      // 仕様、単位、単価、掛け率、原価単価はフラグが編集可能フラグがOFFの場合は編集不可
      if (dataItem['EDITABLE_FLG'] != '1') {
        readOnlys = [...readOnlys, 'SIYOU', 'TANI', 'TANKA', 'GENKA_RATE', 'GENKA_TANKA'];
      }

      if (readOnlys.includes(col['binding'])) {
        args.cancel = true;
      }
    }
    // 手動の場合は以下を入力不可
    else {
      var readOnlys = ['KOUJI_SYU', 'KINGAKU', 'GENKA_KINGAKU'];
      if (readOnlys.includes(col['binding'])) {
        args.cancel = true;
      }
    }
  };

  // グリッドの選択時の処理（ime制御)
  const gridSelectionChanged = (grid, args) => {
    var col = grid.columns[args.col];
    // 数量、単価、原価、掛け率の場合はimeをoffにする
    var imeEnabled = !['SU', 'TANKA', 'GENKA_TANKA', 'GENKA_RATE'].some((x) => x == col?.binding);
    setTimeout(function () {
      grid.imeEnabled = imeEnabled;
    }, 50);
  };

  // 貼り付け後の処理
  const pasted = (grid, args) => {
    cellEditEnded(grid, args);
  };
  // 編集終了処理
  const cellEditEnded = (grid, args) => {
    var dataItem = grid.rows[args.row].dataItem;
    var col = grid.columns[args.col];
    var upd = false;
    // 掛け率から原価を計算
    if (col['binding'] == 'GENKA_RATE') {
      let tanka = toFloat(dataItem['TANKA']);
      let rate = toFloat(dataItem['GENKA_RATE'], 2);

      dataItem['GENKA_RATE'] = rate;
      dataItem['GENKA_TANKA'] = toInt(new Big(rate).times(tanka));
      dataItem['GENKA_KINGAKU'] = calcItem(dataItem, 'GENKA_KINGAKU');
      upd = true;
    }
    // 単価/原価単価から掛け率を取得
    else if (col['binding'] == 'TANKA' || col['binding'] == 'GENKA_TANKA') {
      let genka = toFloat(dataItem['GENKA_TANKA']);
      let tanka = toFloat(dataItem['TANKA']);
      let rate = tanka == 0 ? 0 : genka / tanka;

      rate = !isNaN(rate) ? rate : 0;
      dataItem['GENKA_RATE'] = toFloat(rate, 2);
      dataItem['GENKA_KINGAKU'] = calcItem(dataItem, 'GENKA_KINGAKU');

      upd = true;
    }
    // 数量の場合は小数点第一位で切り捨て
    else if (col['binding'] == 'SU') {
      dataItem['SU'] = toFloat(dataItem['SU'], 1);
      dataItem['KINGAKU'] = calcItem(dataItem, 'KINGAKU');
      dataItem['GENKA_KINGAKU'] = calcItem(dataItem, 'GENKA_KINGAKU');
      upd = true;
    }

    // 一覧の再描画をおこなう
    if (upd) {
      grid.beginUpdate();
      grid.setCellData(args.row, 6, dataItem['KINGAKU'], true);
      grid.setCellData(args.row, 8, dataItem['GENKA_RATE'], true);
      grid.setCellData(args.row, 9, dataItem['GENKA_TANKA'], true);
      grid.setCellData(args.row, 10, dataItem['GENKA_KINGAKU'], true);
      grid.endUpdate();

      // 合計を計算
      calcTotal(flexGrid.current.control.rows.map((x) => x.dataItem));
    }
  };

  // グリッドのフォーマット処理
  const formatItem = (grid, e) => {
    if (e.panel.cellType == CellType.Cell) {
      var col = grid.columns[e.col];
      var dataItem = grid.rows[e.row].dataItem;

      // 注文済の場合は非活性
      if (dataItem?.CYU_MNO_KEY) {
        e.cell.style.background = 'rgb(236, 219, 219)';
      }
      // 見積基盤の場合は以下を非活性
      else if (dataItem['BASE_NO'] != 0) {
        // 工事種、内容、金額、適用、原価金額、社内備考は編集不可
        var readOnlys = ['KOUJI_SYU', 'CONTENT', 'KINGAKU', 'BIKO', 'GENKA_KINGAKU', 'BIKO_SYANAI'];
        // 仕様、単位、単価、掛け率、原価単価はフラグが編集可能フラグがOFFの場合は編集不可
        if (dataItem['EDITABLE_FLG'] != '1') {
          readOnlys = [...readOnlys, 'SIYOU', 'TANI', 'TANKA', 'GENKA_RATE', 'GENKA_TANKA'];
        }

        if (readOnlys.includes(col['binding'])) {
          e.cell.style.background = 'rgb(236, 219, 219)';
        } else {
          e.cell.style.background = '';
        }
      }
      // 手動の場合は以下を非活性
      else {
        var readOnlys = ['KOUJI_SYU', 'KINGAKU', 'GENKA_KINGAKU'];
        if (readOnlys.includes(col['binding'])) {
          e.cell.style.background = 'rgb(236, 219, 219)';
        } else {
          e.cell.style.background = '';
        }
      }

      // 金額
      if (col['binding'] == 'KINGAKU') {
        dataItem['KINGAKU'] = calcItem(dataItem, 'KINGAKU');
      }

      // 原価合計
      if (col['binding'] == 'GENKA_KINGAKU') {
        dataItem['GENKA_KINGAKU'] = calcItem(dataItem, 'GENKA_KINGAKU');
      }
    }
  };

  // 計算処理
  const calcItem = (dataItem, prop) => {
    // 金額 = 数量 * 単価
    if (prop == 'KINGAKU') {
      let tanka = toFloat(dataItem['TANKA']);
      let su = toFloat(dataItem['SU'], 1);
      return toInt(tanka * su);
    }
    // 原価合計 = 数量 * 原価
    if (prop == 'GENKA_KINGAKU') {
      let genka = toFloat(dataItem['GENKA_TANKA']);
      let su = toFloat(dataItem['SU'], 1);
      return toInt(genka * su);
    }
  };

  // 見積基盤チェックが非活性かどうか？
  const isDisableQuoteCheck = (item) => {
    // 注文済の明細がある場合、非活性
    return editDetails.some((x) => x.CYU_MNO_KEY && x.BASE_NO == item.BASE_NO);
  };

  // 見積もり明細の選択処理
  const selectionChanged = (item) => (e) => {
    setSelectedRow(item);
  };

  return (
    <>
      <Dialog fullWidth={true} maxWidth={'xl'} open={props.show}>
        <DialogTitle className="p-3">
          {isAddInvoice ? '追加請求明細登録' : '見積明細登録'}
        </DialogTitle>
        <DialogContent className="px-0 py-0 pr-0 pl-0">
          <Card className="m-0">
            <Card.Body>
              {props.paramBaseQuote.Mode == 'add' ? (
                <Row>
                  <Col className="pl-3" md="4">
                    <Form.Group>
                      <label style={kojisyuMethod == 'base' ? { color: 'blue' } : {}}>
                        <input
                          type="radio"
                          className="m-1"
                          name="rdioKojiSyu"
                          value="base"
                          checked={kojisyuMethod == 'base'}
                          onChange={handleOnChangeKojisyuMethod}
                        />
                        工事種別を見積基盤から選択
                      </label>
                      <SelectComponent
                        value={selectedKojiSyu}
                        disabled={
                          selectedKojiSyuBaseQuotes.some((x) => x['Check']) ||
                          kojisyuMethod != 'base'
                        }
                        onChange={handleChangeKojisyu}
                      >
                        {kojiSyuList.map((x, i) => (
                          <option key={i} value={x}>
                            {x}
                          </option>
                        ))}
                      </SelectComponent>
                    </Form.Group>
                  </Col>
                  <Col className="pl-3" md="4">
                    <Form.Group>
                      <label style={kojisyuMethod == 'hand' ? { color: 'blue' } : {}}>
                        <input
                          type="radio"
                          className="m-1"
                          name="rdioKojiSyu"
                          value="hand"
                          disabled={selectedKojiSyuBaseQuotes.some((x) => x['Check'])}
                          checked={kojisyuMethod == 'hand'}
                          onChange={handleOnChangeKojisyuMethod}
                        />
                        工事種別を手入力
                      </label>
                      <TextComponent
                        placeholder="工事種別を入力してください"
                        type="text"
                        disabled={kojisyuMethod != 'hand'}
                        value={kojisyuHand}
                        onChange={handleOnChangeKojisyuHand}
                      ></TextComponent>
                    </Form.Group>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col className="pl-3" md="4">
                    <Form.Group>
                      <label>工事種別</label>
                      <TextComponent
                        type="text"
                        disabled={kojisyuMethod != 'hand'}
                        value={
                          kojisyuMethod == 'hand' ? kojisyuHand : props.paramBaseQuote.TargetKojiSyu
                        }
                        onChange={handleOnChangeKojisyuHand}
                      ></TextComponent>
                    </Form.Group>
                  </Col>
                </Row>
              )}
              {kojisyuMethod == 'base' ? (
                <Row>
                  <Col className="pt-2 pl-3 table-scroll" md="12" style={{ height: '218px' }}>
                    <FlexGrid
                      allowSorting={false}
                      itemsSource={selectedKojiSyuBaseQuotes}
                      selectionMode={'Cell'}
                      headersVisibility={'Column'}
                      className="small-detail"
                    >
                      <FlexGridColumn header="選" binding="Check" isReadOnly={true} width={28}>
                        <FlexGridCellTemplate
                          cellType="Cell"
                          template={(cell) => (
                            <input
                              type="checkbox"
                              checked={cell.item.Check}
                              onChange={handleOnSelectBaseQuote(cell.item)}
                              disabled={isDisableQuoteCheck(cell.item)}
                            ></input>
                          )}
                        />
                      </FlexGridColumn>
                      <FlexGridColumn
                        binding="CONTENT"
                        header="名称"
                        width={200}
                        isReadOnly={true}
                        cssClass="cell-readonly"
                      ></FlexGridColumn>
                      <FlexGridColumn
                        binding="SIYOU"
                        header="仕様"
                        width={200}
                        isReadOnly={true}
                        cssClass="cell-readonly"
                      ></FlexGridColumn>
                      <FlexGridColumn
                        binding="TANI"
                        header="単位"
                        isReadOnly={true}
                        cssClass="cell-readonly"
                        width={50}
                      ></FlexGridColumn>
                      <FlexGridColumn
                        binding="TANKA"
                        header="単価"
                        isReadOnly={true}
                        cssClass="cell-readonly"
                        dataType="Number"
                        width={100}
                      ></FlexGridColumn>
                      <FlexGridColumn
                        binding="BIKO"
                        header="摘要"
                        isReadOnly={true}
                        cssClass="cell-readonly"
                        width={100}
                      ></FlexGridColumn>
                      <FlexGridColumn
                        binding="GENKA_RATE"
                        header="掛け率"
                        sReadOnly={true}
                        cssClass="cell-readonly"
                        format="n2"
                        width={100}
                      ></FlexGridColumn>
                      <FlexGridColumn
                        binding="GENKA_TANKA"
                        header="原価単価"
                        isReadOnly={true}
                        cssClass="cell-readonly"
                        dataType="Number"
                        width={100}
                      ></FlexGridColumn>
                      <FlexGridColumn
                        binding="BIKO_SYANAI"
                        header="社内備考"
                        isReadOnly={true}
                        cssClass="cell-readonly"
                        width="2*"
                        allowResizing={false}
                        aggregate="Sum"
                      ></FlexGridColumn>
                    </FlexGrid>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              <Row className="pt-2">
                <Col>
                  <Button
                    className="material-symbols-rounded cell-icon-sm btn-fill"
                    variant="success"
                    disabled={editDetails.indexOf(selectedRow) < 0}
                    title="下に移動"
                    onClick={handleOnClickMove({}, 'down')}
                  >
                    Arrow_Downward
                  </Button>
                  <Button
                    className="material-symbols-rounded cell-icon-sm btn-fill ml-1"
                    variant="success"
                    disabled={editDetails.indexOf(selectedRow) < 0}
                    title="上に移動"
                    onClick={handleOnClickMove({}, 'up')}
                  >
                    Arrow_Upward
                  </Button>
                </Col>
                <Col className="text-right">
                  <Button
                    className="material-symbols-rounded cell-icon-sm btn-fill"
                    title="追加"
                    onClick={handlenOnClickAdd}
                  >
                    add
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col
                  className="pt-1 pl-3 table-scroll"
                  md="12"
                  style={kojisyuMethod == 'base' ? { height: '320px' } : { height: '538px' }}
                >
                  <FlexGrid
                    className="small-detail"
                    imeEnabled={true}
                    ref={flexGrid}
                    allowSorting={false}
                    itemsSource={editDetails}
                    selectionMode={'Cell'}
                    headersVisibility={'Column'}
                    formatItem={formatItem}
                    beginningEdit={beginningEdit}
                    cellEditEnded={cellEditEnded}
                    pastingCell={pastingCell}
                    pasted={pasted}
                    keyActionTab={KeyAction.Cycle}
                    autoGenerateColumns={false}
                    selectionChanged={gridSelectionChanged}
                  >
                    <FlexGridColumn header="　" isReadOnly={true} width={28}>
                      <FlexGridCellTemplate
                        cellType="Cell"
                        template={(cell) => (
                          <input
                            type="radio"
                            checked={cell.item === selectedRow}
                            onClick={selectionChanged(cell.item)}
                          ></input>
                        )}
                      />
                    </FlexGridColumn>
                    <FlexGridColumn
                      binding="CONTENT"
                      header="名称"
                      width={200}
                      isRequired={false}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="SIYOU"
                      header="仕様"
                      width={200}
                      isRequired={false}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="SU"
                      header="数量"
                      dataType="Number"
                      format="n1"
                      width={100}
                      inputType={'tel'}
                      isRequired={false}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="TANI"
                      header="単位"
                      width={50}
                      isRequired={false}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="TANKA"
                      header="単価"
                      dataType="Number"
                      width={100}
                      isRequired={false}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="KINGAKU"
                      header="金額"
                      dataType="Number"
                      width={100}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="BIKO"
                      header="摘要"
                      width={100}
                      isRequired={false}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="GENKA_RATE"
                      header="掛け率"
                      width={100}
                      format="n2"
                      isRequired={false}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="GENKA_TANKA"
                      header="原価単価"
                      dataType="Number"
                      width={100}
                      isRequired={false}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="GENKA_KINGAKU"
                      header="原価合計"
                      dataType="Number"
                      width={100}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="BIKO_SYANAI"
                      header="社内備考"
                      width="2*"
                      allowResizing={false}
                      isRequired={false}
                    ></FlexGridColumn>
                    <FlexGridColumn header="　" binding="Check" isReadOnly={true} width={28}>
                      <FlexGridCellTemplate
                        cellType="Cell"
                        template={(cell) => (
                          <IconButtonCompnent
                            className="cell-icon-sm"
                            variant="danger"
                            title="削除"
                            onClick={handleOnClickDelete(cell.item)}
                            disabled={cell.item.CYU_MNO_KEY}
                            tooltip={cell.item.CYU_MNO_KEY ? '注文済みのため、削除できません' : ''}
                            icon="Clear"
                          ></IconButtonCompnent>
                        )}
                      />
                    </FlexGridColumn>
                  </FlexGrid>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {/** 確認ダイアログ */}
          <MessageModal dialog={dialog}></MessageModal>
        </DialogContent>
        <DialogActions className="p-3" style={{ justifyContent: 'space-between' }}>
          <Button variant="secondary" className="footer-button" onClick={handleClose}>
            閉じる
          </Button>
          <div style={{ width: '800px' }}>
            <FlexGrid
              itemsSource={total}
              selectionMode={'None'}
              headersVisibility={'Column'}
              isReadOnly={true}
              allowSorting={false}
            >
              <FlexGridColumn
                binding="ShoKei"
                header="小計"
                format="c0"
                width={'1*'}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="ZeiKei"
                header="消費税"
                format="c0"
                width={90}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="SeikyuKei"
                header="請求金額"
                format="c0"
                width={'1*'}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="GenkaKei"
                header="原価合計"
                format="c0"
                width={'1*'}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="ArariKei"
                header="粗利益額"
                format="c0"
                width={'1*'}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="RiekiRitsu"
                header="利益率"
                format="n2"
                width={80}
              ></FlexGridColumn>
            </FlexGrid>
          </div>
          <Button
            className="btn-fill footer-button"
            variant="primary"
            onClick={handleOnClickFix}
            disabled={editDetails.length == 0}
          >
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default QuoteBaseModal;
