import { DataMap, HeadersVisibility } from '@grapecity/wijmo.grid';
import { CellType } from '@grapecity/wijmo.grid';
import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import TextComponent from 'components/Input/TextComponent';
import { useMaster } from 'context/MasterContext';
import { useRestApi } from 'context/RestApiContext';
import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';

import PaymentConstructionSelectModal from 'views/Payment/PaymentConstructionSelectModal';
import PaymentConstructionSearchModal from 'views/Payment/PaymentConstructionSearchModal';
import IconButtonCompnent from 'components/Input/IconButtonCompnent';
import { Exists } from 'constants/constants';

/** 工事選択画面 */
function PaymentConstructionSelect(props) {
  const paymentMonth = props.paymentMonth;

  const location = useLocation();
  const navigate = useNavigate();

  const master = useMaster();
  const restApi = useRestApi();

  var completeMap = new DataMap(Exists, 'key', 'value');

  // 工事追加用のモーダルパラメタ
  const [addModalParams, setAddModalParams] = useState({});
  // 工事検索のモーダルパラメタ
  const [searchModalParams, setSearchModalParams] = useState({});

  // 請求データ（請求番号のセット）
  const [payments, setPayments] = useState(new Set([]));

  // 工事一覧
  const [constructions, setConstructions] = useState([]);

  // コード翻訳用のマッピング
  var planerMap = new DataMap(master.planer, 'id', 'name');

  // 支払い請求情報を取得
  const searchContracts = () => {
    restApi.post('/api/payment/list', { paymentMonth: paymentMonth }, (data) => {
      let seikyuNos = new Set(data.payments.filter((p) => p.SEIKYU_NO).map((p) => p.SEIKYU_NO));
      setPayments(seikyuNos);

      setConstructions(
        data.paySeikyuList.map((p) => ({
          ...p,
          ATESAKI: props.getAtesaki(p),
          CanDelete: !seikyuNos.has(p.SEIKYU_NO),
        }))
      );
    });
  };

  // 初期表示処理
  useEffect(() => {
    location.state?.searchCondition && setSearchCondition(location.state.searchCondition);
    searchContracts();
  }, []);

  // 未完了の工事から選択ボタンの処理
  const handleOnClickAdd = () => {
    setAddModalParams({
      paymentMonth: props.paymentMonth,
      constructions: constructions,
      payments: payments,
      callback: (_constructions) => {
        setConstructions(
          _constructions.map((c) => ({ ...c, CanDelete: !payments.has(c.SEIKYU_NO) }))
        );
      },
    });
  };

  // 全ての工事から検索ボタンの処理
  const handleOnClickSearch = () => {
    setSearchModalParams({
      show: true,
      callback: (construction) => {
        if (!constructions.some((x) => x.SEIKYU_NO === construction.SEIKYU_NO)) {
          setConstructions([
            ...constructions,
            { ...construction, CanDelete: !payments.has(construction.SEIKYU_NO) },
          ]);
        }
      },
    });
  };

  // 削除ボタンの処理
  const handleOnClickDelete = (cell) => {
    setConstructions(constructions.filter((c) => c.SEIKYU_NO !== cell.item.SEIKYU_NO));
  };

  // 次へボタン押下
  const handleNext = () => {
    restApi.post(
      '/api/payment/seikyu/regist',
      {
        paymentMonth: paymentMonth,
        constructions: constructions,
      },
      (data) => {
        props.setConstructions(constructions);
        props.handleNext();
      }
    );
  };

  // グリッドのフォーマット処理
  const formatItem = (grid, e) => {
    if (e.panel.cellType == CellType.Cell) {
      var dataItem = grid.rows[e.row].dataItem;
      // 完了の場合は背景色をグレー
      if (dataItem.COMPLETE_FLG == '1') {
        e.cell.style.background = '#a6a6a6';
      } else {
        e.cell.style.background = '';
      }
    }
  };

  return (
    <>
      <Row className="pt-4">
        <Col className="pr-1">・原価入力対象の工事を選択してください。</Col>
      </Row>
      <Row className="pt-2">
        <Col className="pr-1" md="2">
          <Form.Group>
            <label>請求年月</label>
            <TextComponent value={paymentMonth} disabled={true} type="date" selectionMode="Month" />
          </Form.Group>
        </Col>
      </Row>
      <Row className="text-right mt-0 mb-0">
        <Col>
          <Button
            size="sm"
            style={{ width: '140px', alignItems: 'center' }}
            variant="primary"
            className="btn-fill p-0 m-1"
            onClick={handleOnClickSearch}
          >
            <SearchIcon style={{ fontSize: 15 }} />
            全ての工事から検索
          </Button>
          <Button
            size="sm"
            style={{ width: '150px', alignItems: 'center' }}
            variant="primary"
            className="btn-fill p-0 m-1"
            onClick={handleOnClickAdd}
          >
            <AddIcon style={{ fontSize: 15 }} />
            未完了の工事から選択
          </Button>
        </Col>
      </Row>
      <Row className="mt-0">
        <Col>
          <div className="table-scroll" style={{ height: 'calc(100vh - 342px)' }}>
            <FlexGrid
              isReadOnly={true}
              itemsSource={constructions}
              selectionMode={'Row'}
              headersVisibility={HeadersVisibility.Column}
              allowSorting={true}
              style={{ height: '100%' }}
              formatItem={formatItem}
            >
              <FlexGridColumn binding="SEIKYU_NO" header="#" width={40} format="d"></FlexGridColumn>
              <FlexGridColumn
                binding="COMPLETE_FLG"
                header="完了"
                width={48}
                dataMap={completeMap}
                align="center"
              ></FlexGridColumn>
              <FlexGridColumn binding="KOUJI_MEI" header="工事名" width={'1*'}></FlexGridColumn>
              <FlexGridColumn binding="ATESAKI" header="依頼主" width={'1*'}></FlexGridColumn>
              <FlexGridColumn binding="KOUJI_BASYO" header="工事場所" width={'2*'}></FlexGridColumn>
              <FlexGridColumn
                binding="TAN_CD"
                dataMap={planerMap}
                header="担当"
                width={80}
              ></FlexGridColumn>
              <FlexGridColumn header="　" isReadOnly={true} width={28}>
                <FlexGridCellTemplate
                  cellType="Cell"
                  template={(cell) => (
                    <IconButtonCompnent
                      className="cell-icon-sm"
                      variant="danger"
                      title="削除"
                      onClick={() => handleOnClickDelete(cell, '1')}
                      disabled={!cell.item.CanDelete}
                      tooltip={!cell.item.CanDelete ? '工事が設定済みのため削除できません' : ''}
                      icon="Clear"
                    />
                  )}
                />
              </FlexGridColumn>
            </FlexGrid>
          </div>
        </Col>
      </Row>
      <Row className="pt-2">
        <Col className="d-flex justify-content-between">
          <Button variant="secondary" className="footer-button" onClick={props.handleBack}>
            戻る
          </Button>
          <Button variant="primary" className="btn-fill footer-button" onClick={handleNext}>
            次へ
          </Button>
        </Col>
      </Row>
      {/* 工事選択モーダル */}
      <PaymentConstructionSelectModal params={addModalParams} />
      {/* 工事検索モーダル */}
      <PaymentConstructionSearchModal params={searchModalParams} />
    </>
  );
}

export default PaymentConstructionSelect;
