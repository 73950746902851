import { Button } from 'react-bootstrap';
import { Tooltip } from '@mui/material';

// アイコンボタンコンポーネント
function IconButtonCompnent(props) {
  return (
    <Tooltip title={props.tooltip} arrow>
      <span>
        <Button
          className={`material-symbols-rounded btn-fill ${props.className}`}
          variant={props.variant}
          onClick={props.onClick}
          disabled={props.disabled}
          style={props.disabled ? { pointerEvents: 'none' } : {}}
        >
          {props.icon}
        </Button>
      </span>
    </Tooltip>
  );
}

export default IconButtonCompnent;
