import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';
import { MessageModal } from 'components/modal/MessageModal';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const RestApiContext = createContext();

/**
 * 認証情報プロバイダ
 * @param {*} 子要素
 * @returns 認証情報プロバイダ
 */
export function RestApiContextProvider({ children }) {
  const auth = useAuth();
  const navigate = useNavigate();
  const [dialog, setDialog] = useState({});

  // 読み込み中
  const [loading, setLoading] = useState(false);

  /**
   * GET
   * @param {str} url URL
   * @param {*} successCallBack 処理成功時のコールバック処理
   */
  const get = useCallback(
    (url, successCallBack, headers) => {
      doFetch(url, successCallBack, {
        method: 'GET',
        headers: headers ?? {
          Accept: 'application/json;',
          authorization: 'JWT ' + auth.auth.access_token,
        },
        cache: 'no-cache',
        mode: 'cors',
      });
    },
    [auth, navigate]
  );

  /**
   * DELETE
   * @param {str} url URL
   * @param {*} successCallBack 処理成功時のコールバック処理
   */
  const doDelete = useCallback(
    (url, successCallBack, headers) => {
      doFetch(url, successCallBack, {
        method: 'DELETE',
        headers: headers ?? {
          Accept: 'application/json;',
          authorization: 'JWT ' + auth.auth.access_token,
        },
        cache: 'no-cache',
        mode: 'cors',
      });
    },
    [auth, navigate]
  );

  /**
   * POST
   * @param {str} url URL
   * @param {dict} body 内容
   * @param {*} successCallBack 処理成功時のコールバック処理
   */
  const post = useCallback(
    (url, body, successCallBack) => {
      doFetch(url, successCallBack, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json;',
          authorization: 'JWT ' + auth.auth.access_token,
        },
        body: JSON.stringify(body),
        cache: 'no-cache',
        mode: 'cors',
      });
    },
    [auth, navigate]
  );

  /**
   * リクエスト送信処理
   * @param {str} url URL
   * @param {dict} body 内容
   * @param {*} successCallBack 処理成功時のコールバック処理
   * @param {*} requestInfo 情報
   */
  const doFetch = useCallback(
    (url, successCallBack, requestInfo) => {
      // 読み込み中は処理しない
      if (loading) {
        return;
      }

      setLoading(true);
      const baseUrl = url.startsWith('http') ? '' : process.env.REACT_APP_REST_URL ?? '';
      fetch(`${baseUrl}${url}`, requestInfo)
        .then((res) => {
          // 認証エラーの場合はログアウト処理
          if (res.status == 401) {
            // 認証情報を削除
            auth.setAuth({
              isAuthed: false,
              access_token: '',
            });
            setDialog({
              type: 'info',
              message: '長時間操作がみられませんでした。再ログインをお願いします。',
            });
            setLoading(false);
            return undefined;
          }
          if (!res.ok) {
            setDialog({ type: 'error', message: '通信エラーが発生しました。' });
            setLoading(false);
            return undefined;
          }
          return res.json();
        })
        .then((data) => {
          if (data != undefined) {
            successCallBack(data);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setDialog({ type: 'error', message: '通信エラーが発生しました。' });
          setLoading(false);
        });
    },
    [auth, navigate]
  );

  /**
   * リクエスト送信（PDF表示）POST
   * @param {str} url URL
   * @param {dict} body 内容
   * @param {*} successCallBack 処理成功時のコールバック処理
   */
  const postToPdf = useCallback(
    (url, body, successCallBack) => {
      // 読み込み中は処理しない
      if (loading) {
        return;
      }
      setLoading(true);

      const baseUrl = process.env.REACT_APP_REST_URL ?? '';
      fetch(`${baseUrl}/${url}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: 'JWT ' + auth.auth.access_token,
        },
        body: JSON.stringify(body),
        cache: 'no-cache',
        mode: 'cors',
      })
        .then((res) => res.blob())
        .then((blob) => {
          const fileUrl = URL.createObjectURL(blob);
          window.open(fileUrl);
          successCallBack && successCallBack();
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setDialog({ type: 'error', message: '通信エラーが発生しました。' });
          setLoading(false);
        });
    },
    [auth, navigate]
  );

  /**
   * ファイルのダウンロードPOST
   * @param {str} url URL
   * @param {dict} body 内容
   * @param {*} successCallBack 処理成功時のコールバック処理
   */
  const postDownload = useCallback(
    (url, body, fileName, successCallBack) => {
      // 読み込み中は処理しない
      if (loading) {
        return;
      }
      setLoading(true);

      const baseUrl = process.env.REACT_APP_REST_URL ?? '';
      fetch(`${baseUrl}/${url}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: 'JWT ' + auth.auth.access_token,
        },
        body: JSON.stringify(body),
        cache: 'no-cache',
        mode: 'cors',
      })
        .then((res) => res.blob())
        .then((blob) => {
          // Blobデータを保存するためのa要素を作成
          const a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = fileName;
          // a要素をクリックしてダウンロードをトリガー
          a.click();
          // 不要なURLオブジェクトを解放
          URL.revokeObjectURL(a.href);
          successCallBack && successCallBack();
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setDialog({ type: 'error', message: '通信エラーが発生しました。' });
          setLoading(false);
        });
    },
    [auth, navigate]
  );

  /**
   * リクエスト送信（PDF表示）GET
   * @param {str} url URL
   * @param {*} successCallBack 処理成功時のコールバック処理
   */
  const getToPdf = useCallback(
    (url, successCallBack) => {
      // 読み込み中は処理しない
      if (loading) {
        return;
      }
      setLoading(true);

      const baseUrl = process.env.REACT_APP_REST_URL ?? '';
      fetch(`${baseUrl}/${url}`, {
        method: 'GET',
        headers: {
          authorization: 'JWT ' + auth.auth.access_token,
        },
        cache: 'no-cache',
        mode: 'cors',
      })
        .then((res) => res.blob())
        .then((blob) => {
          const fileUrl = URL.createObjectURL(blob);
          window.open(fileUrl);
          successCallBack && successCallBack();
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setDialog({ type: 'error', message: '通信エラーが発生しました。' });
          setLoading(false);
        });
    },
    [auth, navigate]
  );

  return (
    <RestApiContext.Provider value={{ get, post, getToPdf, postToPdf, postDownload, doDelete }}>
      {/** エラーダイアログ */}
      <MessageModal dialog={dialog}></MessageModal>
      {/** 読み込み中処理 */}
      <Backdrop sx={{ color: '#fff', zIndex: Number.MAX_SAFE_INTEGER }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {children}
    </RestApiContext.Provider>
  );
}
/**
 * 認証情報を取得
 * @returns 認証情報
 */
export function useRestApi() {
  const context = useContext(RestApiContext);
  if (context == undefined) {
    throw new Error('RESTAPIの取得に失敗');
  }
  return context;
}
