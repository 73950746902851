// 日付に変換
export function toDate(d) {
  if (!d) {
    return null;
  }

  const date = new Date(d);

  // 日付が無効な場合はnullを返す
  if (isNaN(date.getTime())) {
    return null;
  }

  return date;
}

// 年度の開始年月を取得
export function getNendoStartMonth(td) {
  td = td ?? new Date();
  // 1月、2月の場合は年度は前年の３月から
  if (td.getMonth() < 2) {
    return `${td.getFullYear() - 1}/03`;
  }
  return `${td.getFullYear()}/03`;
}

// 年度の終了年月を取得
export function getNendoEndMonth(td) {
  td = td ?? new Date();
  // 1月、2月の場合は年度は今年の２月まで
  if (td.getMonth() < 2) {
    return `${td.getFullYear()}/02`;
  }
  return `${td.getFullYear() + 1}/02`;
}

// 対象年月が過去の年月かどうか判定
export function isPastYearMonth(dateString) {
  // 文字列を日付オブジェクトにパース
  const dateObj = new Date(dateString);

  // 現在の年月を取得
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();

  // 年月を比較して過去の年月かどうかを判定
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth();
  const isPast = year < currentYear || (year === currentYear && month < currentMonth);

  return isPast;
}

// 対象の日付が指定日数より前かどうかを判定
export function isOlderThanDays(dateStr, days) {
  const date = new Date(dateStr);
  if (isNaN(date)) return false; // 無効な日付は false を返す

  // 31日前の日付を取得
  const pastDate = new Date();
  pastDate.setDate(pastDate.getDate() - days);

  return date <= pastDate;
}
