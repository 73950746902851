import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import '@grapecity/wijmo.styles/wijmo.css';
import { useEffect, useRef, useState, useCallback } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router';
import { Label } from 'reactstrap';
import { format, set } from 'date-fns';

import { useRestApi } from 'context/RestApiContext';
import { MessageModal } from 'components/modal/MessageModal';
import TextComponent from 'components/Input/TextComponent';
import OrderDetailsEditModal from './OrderDetailsEditModal';
import StatusLableComponent from 'components/Input/StatusLableComponent';

function OrderEntry() {
  const restApi = useRestApi();
  const location = useLocation();
  const navigate = useNavigate();

  // パラメタ
  const backPage = location?.state?.backPage;

  // モード情報
  const MODE_LIST = [
    { id: 'add', caption: '登録', variant: 'primary', cmd: 'add', readOnly: false },
    { id: 'upd', caption: '更新', variant: 'primary', cmd: 'upd', readOnly: false },
    { id: 'del', caption: '削除', variant: 'danger', cmd: 'del', readOnly: true },
    { id: 'request', caption: '承認申請', variant: 'primary', cmd: 'upd', readOnly: false },
    { id: 'cancel', caption: '申請取消', variant: 'danger', cmd: 'upd', readOnly: true },
    { id: 'approval', caption: '承認', variant: 'primary', cmd: 'upd', readOnly: true },
  ];
  // 画面モード
  const [mode, setMode] = useState(MODE_LIST.find((x) => x.id == location.state.mode));
  // 確認ダイアログ
  const [dialog, setDialog] = useState({});

  // 請求No
  const [seikyuNo, setSeikyuNo] = useState(-1);

  // 顧客番号
  const [clientCd, setClientCd] = useState(-1);
  // 顧客名
  const [clientName, setClientName] = useState('');
  // 見積番号
  const [quoteNo, setQuoteNo] = useState();
  // 注文ヘッダ
  const [header, setHeader] = useState({});
  // 注文明細
  const [details, setDetails] = useState([]);
  // 注文明細（表示用）
  const [viewDetails, setViewDetails] = useState([]);
  // 工事情報を表示するか？
  const [displayInfo, setDisplayInfo] = useState(true);
  // 注文明細編集画面のモーダルの表示
  const [editDialog, setEditDialog] = useState({
    show: false,
    orderDetails: [],
  });

  // 注文明細編集画面のモーダルのコールバック処理
  const callBackModal = (data) => {
    setDetails([...data]);
  };

  // 請求金額
  const [seikyuKin, setSeikyuKin] = useState(0);
  // 注文金額(対象の取引先以外)
  const [otherCyuKin, setOtherCyuKin] = useState(0);

  // 請求全体の合計
  const [seikyuTotal, setSeikyuTotal] = useState([
    {
      SeikyuKei: 0,
      OrderKei: 0,
      ArariKei: 0,
      RiekiRitsu: 0,
    },
  ]);

  // 合計
  const [total, setTotal] = useState([
    {
      ShoKei: 0,
      ZeiKei: 0,
      OrderKei: 0,
      MitsuKei: 0,
      ArariKei: 0,
      RiekiRitsu: 0,
    },
  ]);

  // オーダ明細
  const [orderDest, setOrderDest] = useState({});

  // グリッド
  const flexGrid = useRef();

  // 初期表示処理
  useEffect(() => {
    if (mode.id == 'add') {
      restApi.get(`/api/contract/info/${location.state.keiNo}`, (data) => {
        let contract = data.contract;
        setSeikyuKin(data.seikyuKin);
        setOtherCyuKin(data.cyuKin);
        setHeader({
          TRI_CD: location.state.orderDest.TRI_CD,
          KEI_NO: contract.KEI_NO,
          SEIKYU_NO: contract.SEIKYU_NO,
          QUOTE_NO: contract.QUOTE_NO,
          CLIENT_CD: contract.CLIENT_CD,
          KOUJI_MEI: contract.KOUJI_MEI,
          KOUJI_BASYO: contract.KOUJI_BASYO,
          KOUKI: '',
          CYU_KIN: 0,
          ZEI: 0,
          CYU_DATE: format(new Date(), 'yyyy/MM/dd'),
          BIKO: '',
        });
        setQuoteNo(contract.QUOTE_NO);
        setSeikyuNo(contract.SEIKYU_NO);
        setClientCd(contract.CLIENT_CD);
        setClientName(contract.CLIENT_NAME);
        setDetails([...details]);
      });
      setOrderDest(location?.state?.orderDest);
    } else {
      restApi.get(`/api/order/info/${location.state.cyuNo}`, (data) => {
        setSeikyuKin(data.seikyuKin);
        setOtherCyuKin(data.otherCyuKin);
        setHeader(data.header);
        setDetails(data.details);
        setQuoteNo(data.contract.QUOTE_NO);
        setSeikyuNo(data.contract.SEIKYU_NO);
        setClientCd(data.contract.CLIENT_CD);
        setClientName(data.contract.CLIENT_NAME);
        setOrderDest(data.orderDest);
      });
    }
  }, []);

  // 注文明細の変更処理
  useEffect(() => {
    // 明細番号順に並び替え
    let _details = [...details];

    // 空白埋めして表示
    let blank = displayInfo ? 17 : 27;
    if (_details.length < blank) {
      // _details = [..._details, ...Array(blank - _details.length).map(_ => ({}))]
    }
    setViewDetails([..._details]);

    // 小計
    let shokei = details.reduce((a, x) => a + x['KINGAKU'], 0);
    // 消費税
    let tax = Math.floor(shokei * 0.1);
    // 注文額
    let cyukin = shokei + tax;
    // 見積合計
    let mitsu_kingaku = details.reduce((a, x) => a + x['MITSU_KINGAKU'], 0);
    // 粗利
    let arari = mitsu_kingaku - shokei;
    // 粗利率
    let arariritu = mitsu_kingaku > 0 ? Math.floor((arari / mitsu_kingaku) * 100) / 100 : 0;

    // 注文額をヘッダに保持
    setHeader({ ...header, CYU_KIN: shokei, ZEI: tax });

    // 全体の注文金額
    let cyukinTotal = otherCyuKin + shokei;
    // 全体の請求金額に対する粗利
    let arariTotal = seikyuKin - cyukinTotal;
    // 全体の粗利率
    let ararirituTotal = seikyuKin > 0 ? Math.floor((arariTotal / seikyuKin) * 100) / 100 : 0;

    // 全体の請求金額に反映
    setSeikyuTotal([
      {
        SeikyuKei: seikyuKin,
        OrderKei: cyukinTotal,
        ArariKei: arariTotal,
        RiekiRitsu: ararirituTotal,
      },
    ]);

    // 集計結果を反映
    setTotal([
      {
        ShoKei: shokei,
        ZeiKei: tax,
        OrderKei: cyukin,
        MitsuKei: mitsu_kingaku,
        ArariKei: arari,
        RiekiRitsu: !isNaN(arariritu) ? arariritu : 0,
      },
    ]);
  }, [details, displayInfo]);

  // 編集処理
  const handleChange = (prop) => (target) => {
    setHeader({ ...header, [prop]: target.value });
  };

  // 明細編集ボタン
  const handleOnClickEdit = () => {
    setEditDialog({
      show: true,
      orderDetails: details,
    });
  };

  // 保存ボタン
  const handleOnClickSave = () => {
    setDialog({
      type: 'confirm',
      message: `${mode.caption}します。よろしいですか？`,
      okCallBack: () => {
        saveOrder();
      },
    });
  };

  // 却下ボタン
  const handleOnClickReject = () => {
    setDialog({
      type: 'confirm',
      message: `却下します。よろしいですか？`,
      okCallBack: () => {
        saveOrder('承認却下');
      },
    });
  };

  // 注文更新処理
  const saveOrder = (status) => {
    // 明細番号順に並び替え
    let _details = [...details];

    // ステータスを設定
    let _header = { ...header, STATUS: status ?? getStatus() };

    let req = {
      header: _header,
      details: _details.map((x, i) => ({
        ...x,
        CYU_MNO: i + 1,
        TANKA: x.TANKA ?? '0',
      })),
    };
    restApi.post(`/api/order/${mode.cmd}`, req, (_) => {
      // 呼び出し元に戻る
      navigate(backPage, {
        state: {
          ...location.state,
          clientId: clientCd,
          seikyuNo: seikyuNo,
        },
      });
    });
  };

  // ステータス取得
  const getStatus = () => {
    switch (mode.id) {
      case 'approval':
        return '承認済み';
      case 'request':
        return '承認待ち';
      case 'cancel':
        return '作成中';
      case 'reject':
        return '承認却下';
      case 'add':
        return '作成中';
      case 'upd':
        return header['STATUS'] == '承認待ち' ? '承認待ち' : '作成中';
      default:
        header['STATUS'];
    }
  };

  // 戻るボタン
  const handleOnClickReturn = () => {
    // 呼び出し元に戻る
    navigate(backPage, {
      state: {
        ...location.state,
        clientId: clientCd,
        seikyuNo: seikyuNo,
      },
    });
  };

  return (
    <>
      <Card className="m-0">
        <Card.Body>
          <Row
            onClick={() => {
              setDisplayInfo(!displayInfo);
            }}
          >
            <Col style={{ display: 'flex', columnGap: '15px' }}>
              <Label style={{ fontSize: '120%', fontWeight: 'bold', color: 'black' }}>
                {orderDest.TRI_NAME}
              </Label>
              {mode.id == 'add' ? (
                <StatusLableComponent status={'新規'} />
              ) : (
                <StatusLableComponent status={header.STATUS} />
              )}
            </Col>
            <Col className="text-right">
              <div className="font-icon-detail">
                {displayInfo ? (
                  <i className="material-symbols-rounded">keyboard_control_key</i>
                ) : (
                  <i className="material-symbols-rounded">keyboard_arrow_right</i>
                )}
              </div>
            </Col>
          </Row>
          {displayInfo ? (
            <>
              <Row>
                <Col className="pr-1" md="3">
                  <Form.Group>
                    <label>顧客名</label>
                    <TextComponent type="text" value={clientName} disabled={true}></TextComponent>
                  </Form.Group>
                </Col>
                <Col className="pr-1" md="2">
                  <Form.Group>
                    <label>注文日</label>
                    <TextComponent
                      placeholder=""
                      type="date"
                      value={header?.CYU_DATE}
                      onChange={handleChange('CYU_DATE')}
                      disabled={mode.readOnly}
                    ></TextComponent>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="pr-1" md="3">
                  <Form.Group>
                    <label>工事名</label>
                    <TextComponent
                      placeholder=""
                      type="text"
                      value={header?.KOUJI_MEI}
                      disabled={true}
                    ></TextComponent>
                  </Form.Group>
                </Col>
                <Col className="pr-1" md="4">
                  <Form.Group>
                    <label>工事場所</label>
                    <TextComponent
                      placeholder=""
                      type="text"
                      value={header?.KOUJI_BASYO}
                      disabled={true}
                    ></TextComponent>
                  </Form.Group>
                </Col>
                <Col className="pr-1" md="2">
                  <Form.Group>
                    <label>工期</label>
                    <TextComponent
                      placeholder=""
                      type="text"
                      value={header?.KOUKI}
                      onChange={handleChange('KOUKI')}
                      disabled={mode.readOnly}
                    ></TextComponent>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="pr-1" md="6" rows="3">
                  <Form.Group>
                    <div className="mb-3">
                      <label htmlFor="textArea1" className="form-label">
                        備考
                      </label>
                      <TextComponent
                        className="form-control"
                        type="textarea"
                        rows="2"
                        value={header?.BIKO}
                        onChange={handleChange('BIKO')}
                        disabled={mode.id == 'del'}
                      ></TextComponent>
                    </div>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group>
                    <div className="mb-3">
                      <label htmlFor="textArea2" className="form-label">
                        社内備考（※注文書には印刷されません）
                      </label>
                      <TextComponent
                        className="form-control"
                        type="textarea"
                        rows="2"
                        value={header?.BIKO_SYANAI}
                        onChange={handleChange('BIKO_SYANAI')}
                        disabled={mode.id == 'del'}
                      ></TextComponent>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
        </Card.Body>
      </Card>
      <Card className="mb-0">
        <Card.Body>
          <Row>
            <Col>工事注文明細</Col>
            <Col className="text-right">
              {!mode.readOnly ? (
                <Button
                  className="material-symbols-rounded cell-icon-sm btn-fill"
                  title="注文明細編集"
                  onClick={handleOnClickEdit}
                >
                  edit
                </Button>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Row className="pt-1">
            <Col
              className="pl-3 table-scroll"
              md="12"
              style={
                displayInfo ? { height: 'calc(100vh - 502px)' } : { height: 'calc(100vh - 259px)' }
              }
            >
              <FlexGrid
                allowSorting={false}
                ref={flexGrid}
                itemsSource={viewDetails}
                isReadOnly={true}
                selectionMode={'None'}
                headersVisibility={'Column'}
              >
                <FlexGridColumn
                  binding="KOUJI_SYU"
                  header="工事種別"
                  isReadOnly={true}
                  width={200}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="CONTENT"
                  header="名称"
                  isReadOnly={true}
                  width={200}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="SIYOU"
                  header="仕様"
                  isReadOnly={true}
                  width={200}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="SU"
                  header="数量"
                  format="n1"
                  width={60}
                  dataType="Number"
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="TANI"
                  header="単位"
                  isReadOnly={true}
                  width={45}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="TANKA"
                  header="単価"
                  format="c0"
                  isReadOnly={true}
                  width={80}
                  dataType="Number"
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="KINGAKU"
                  header="金額"
                  format="c0"
                  width={100}
                  dataType="Number"
                ></FlexGridColumn>
                <FlexGridColumn binding="BIKO" header="摘要" width={100}></FlexGridColumn>
                <FlexGridColumn
                  binding="GENKA_RATE"
                  header="掛け率"
                  format="n2"
                  width={60}
                  dataType="Number"
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="MITSU_TANKA"
                  header="見積単価"
                  format="c0"
                  isReadOnly={true}
                  width={80}
                  dataType="Number"
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="MITSU_KINGAKU"
                  header="見積金額"
                  format="c0"
                  isReadOnly={true}
                  width={100}
                  dataType="Number"
                  allowResizing={false}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="BIKO_SYANAI"
                  header="社内備考"
                  width="2*"
                  allowResizing={false}
                ></FlexGridColumn>
              </FlexGrid>
            </Col>
          </Row>
          <Row className="pt-0">
            <Col className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <Button className="footer-button" variant="secondary" onClick={handleOnClickReturn}>
                  戻る
                </Button>
              </div>
              <div style={{ width: '400' }}>
                <FlexGrid
                  itemsSource={seikyuTotal}
                  selectionMode={'None'}
                  headersVisibility={'Column'}
                  isReadOnly={true}
                  allowSorting={false}
                >
                  <FlexGridColumn
                    binding="SeikyuKei"
                    header="請求金額(税抜)"
                    format="c0"
                    width={'1*'}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="OrderKei"
                    header="注文金額(税抜)"
                    format="c0"
                    width={'1*'}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="ArariKei"
                    header="粗利益額"
                    format="c0"
                    width={'1*'}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="RiekiRitsu"
                    header="粗利率"
                    format="n2"
                    width={'1*'}
                  ></FlexGridColumn>
                </FlexGrid>
              </div>
              <div style={{ width: '600' }}>
                <FlexGrid
                  itemsSource={total}
                  selectionMode={'None'}
                  headersVisibility={'Column'}
                  isReadOnly={true}
                  allowSorting={false}
                >
                  <FlexGridColumn
                    binding="ShoKei"
                    header="小計"
                    format="c0"
                    width={'1*'}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="ZeiKei"
                    header="消費税"
                    format="c0"
                    width={'1*'}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="OrderKei"
                    header="注文金額"
                    format="c0"
                    width={'1*'}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="MitsuKei"
                    header="見積合計"
                    format="c0"
                    width={'1*'}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="ArariKei"
                    header="粗利益額"
                    format="c0"
                    width={'1*'}
                  ></FlexGridColumn>
                  <FlexGridColumn
                    binding="RiekiRitsu"
                    header="粗利率"
                    format="n2"
                    width={'1*'}
                  ></FlexGridColumn>
                </FlexGrid>
              </div>
              <div className="d-flex align-items-center flex-row-reverse">
                {mode.id == 'approval' ? (
                  <Button
                    variant={'danger'}
                    className="btn-fill footer-button ml-2"
                    onClick={handleOnClickReject}
                  >
                    却下
                  </Button>
                ) : null}
                <Button
                  variant={mode.variant}
                  className="btn-fill footer-button"
                  onClick={handleOnClickSave}
                >
                  {mode.caption}
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {/** メッセージモーダル */}
      <MessageModal dialog={dialog}></MessageModal>
      {/** 注文編集モーダル */}
      <OrderDetailsEditModal
        cyuNo={header.CYU_NO}
        params={editDialog}
        callBack={callBackModal}
        quoteNo={quoteNo}
        seikyuNo={seikyuNo}
        otherCyuKin={otherCyuKin}
        seikyuKin={seikyuKin}
      />
    </>
  );
}

export default OrderEntry;
