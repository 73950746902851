import * as wjcGrid from '@grapecity/wijmo.grid';
import { CellType } from '@grapecity/wijmo.grid';
import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import '@grapecity/wijmo.styles/wijmo.css';
import TextComponent from 'components/Input/TextComponent';
import { MessageModal } from 'components/modal/MessageModal';
import { useRestApi } from 'context/RestApiContext';
import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router';
import { Label } from 'reactstrap';

// 請求承認画面
function InvoiceApproval() {
  const restApi = useRestApi();
  const location = useLocation();
  const navigate = useNavigate();

  // グリッド
  const flexGrid = useRef();

  // 顧客情報
  const [client, setClient] = useState({});

  // モード情報
  const MODE_LIST = [
    { id: 'request', caption: '承認申請', variant: 'primary', readOnly: false },
    { id: 'cancel', caption: '申請取消', variant: 'danger', readOnly: true },
    { id: 'approval', caption: '承認', variant: 'primary', readOnly: true },
  ];

  // モード
  const [mode, setMode] = useState(MODE_LIST.find((x) => x.id == location.state.mode));

  // 確認ダイアログ
  const [dialog, setDialog] = useState({});

  // 戻り先
  const [backPage, setBackPage] = useState(location?.state?.backPage);

  // 工事情報を表示するか？
  const [displayInfo, setDisplayInfo] = useState(true);

  // 集計行のマージ処理
  class CustomMergeManager extends wjcGrid.MergeManager {
    getMergedRange(panel, r, c, clip = true) {
      var rng = new wjcGrid.CellRange(r, c);
      let dataItem = panel?.grid?.rows[r]?.dataItem;
      if (panel.cellType == CellType.Cell && dataItem?.Group) {
        if (c < 4) {
          rng.col = 0;
          rng.col2 = 4;
          return rng;
        }
        if (c > 5 && c < 8) {
          rng.col = 6;
          rng.col2 = 8;
          return rng;
        }
      }
      return null;
    }
  }

  // 請求情報
  const [invoice, setInvoice] = useState({});

  // 明細データ
  const [details, setDetails] = useState([]);
  // 明細集計データ
  const [total, setTotal] = useState([]);
  // 工事見積明細データ（表示用）
  const [viewDetails, setViewDetails] = useState([]);
  // Grid初期化処理
  const initializedGrid = (flex) => {
    flex.mergeManager = new CustomMergeManager();
  };

  // 初期表示
  useEffect(() => {
    let seikyuNo = location?.state?.seikyuNo;
    // 請求承認情報を取得する
    restApi.get(`/api/invoice/approval/${seikyuNo}`, (data) => {
      setDetails(data.details);
      setClient(data.client);
      setInvoice(data.invoice);
    });
  }, []);

  // 明細データに変更があった時の処理
  useEffect(() => {
    let _viewQuoteDetails = [];
    let _kojiSyu = '';
    let _quoteNo = '';

    details.forEach((qd, i) => {
      // 明細の小計行を作成
      if (_kojiSyu != qd['KOUJI_SYU'] || _quoteNo != qd['QUOTE_NO']) {
        _kojiSyu = qd['KOUJI_SYU'];
        _quoteNo = qd['QUOTE_NO'];
        _viewQuoteDetails.push({
          Group: true,
          AddInvoice: qd.MOTO_KEI_NO == 1, // 追加請求かどうか
          KOUJI_SYU: qd['KOUJI_SYU'],
          CONTENT: qd['KOUJI_SYU'],
          KINGAKU: details
            .filter((x) => x['KOUJI_SYU'] == qd['KOUJI_SYU'] && x['QUOTE_NO'] == qd['QUOTE_NO'])
            .reduce((a, x) => a + x['KINGAKU'], 0),
          GENKA_KINGAKU: details
            .filter((x) => x['KOUJI_SYU'] == qd['KOUJI_SYU'] && x['QUOTE_NO'] == qd['QUOTE_NO'])
            .reduce((a, x) => a + x['GENKA_KINGAKU'], 0),
        });
      }
      // 明細を追加
      _viewQuoteDetails.push(qd);
    });

    let blank = displayInfo ? 17 : 27;
    // setViewDetails([..._viewQuoteDetails, ...Array(Math.max(blank - _viewQuoteDetails.length, 0)).map(_ => { })]);
    setViewDetails([..._viewQuoteDetails]);

    // 小計
    let shokei = details.reduce((a, x) => a + x['KINGAKU'], 0);
    // 消費税
    let tax = Math.floor(shokei * 0.1);
    // 請求額
    let seikyu = shokei + tax;
    // 原価合計
    let genka_kingaku = details.reduce((a, x) => a + x['GENKA_KINGAKU'], 0);
    // 粗利
    let arari = shokei - genka_kingaku;
    // 粗利率
    let arariritu = Math.floor((arari / shokei) * 100) / 100;

    // 集計結果を反映
    setTotal([
      {
        ShoKei: shokei,
        ZeiKei: tax,
        SeikyuKei: seikyu,
        GenkaKei: genka_kingaku,
        ArariKei: arari,
        RiekiRitsu: !isNaN(arariritu) ? arariritu : 0,
      },
    ]);
  }, [details, displayInfo]);

  // グリッドのフォーマット処理
  const formatItem = (grid, e) => {
    if (e.panel.cellType == CellType.Cell) {
      var dataItem = grid.rows[e.row].dataItem;
      // 集計行の場合
      if (dataItem?.Group) {
        e.cell.style.background = dataItem.AddInvoice ? 'rgb(63, 130, 243)' : 'rgb(255, 160, 122)';
        e.cell.style.color = dataItem.AddInvoice ? 'rgb(255, 255, 255)' : 'rgb(255, 255, 255)';
        // 明細行の場合
      } else {
        e.cell.style.background = '';
        e.cell.style.color = '';
      }
    }
  };

  // 編集処理
  const handleOnChangeInvoice = (prop) => (target) => {
    setInvoice({ ...invoice, [prop]: target.value });
  };

  // 保存ボタン
  const handleOnClickSave = () => {
    setDialog({
      type: 'confirm',
      message: `${mode.caption}します。よろしいですか？`,
      okCallBack: () => {
        save();
      },
    });
  };

  // 却下ボタン
  const handleOnClickReject = () => {
    setDialog({
      type: 'confirm',
      message: `却下します。よろしいですか？`,
      okCallBack: () => {
        save('承認却下');
      },
    });
  };

  // 保存処理処理
  const save = (status = null) => {
    let req = {
      invoice: {
        ...invoice,
        STATUS: status ?? getStatus(),
        MITSU_KIN: total[0].ShoKei,
        ZEI: total[0].ZeiKei,
      },
    };
    restApi.post('/api/invoice/approval/upd', req, (_) => {
      // 呼び出し元に戻る
      navigate(backPage, {
        state: {
          ...location.state,
          clientId: client.CLIENT_CD,
          seikyuNo: location.state.seikyuNo,
        },
      });
    });
  };

  // ステータス取得
  const getStatus = () => {
    switch (mode.id) {
      case 'approval':
        return '承認済み';
      case 'request':
        return '承認待ち';
      case 'cancel':
        return '作成中';
      case 'reject':
        return '承認却下';
      default:
        invoice.STATUS;
    }
  };

  // 戻るボタン
  const handleOnClickReturn = () => {
    navigate(backPage, {
      state: {
        ...location.state,
        clientId: client.CLIENT_CD,
        seikyuNo: location.state.seikyuNo,
      },
    });
  };

  return (
    <>
      <Card className="m-0">
        <Card.Body>
          <Row
            onClick={() => {
              setDisplayInfo(!displayInfo);
            }}
          >
            <Col style={{ display: 'flex', columnGap: '15px' }}>
              <Label
                style={{ fontSize: '120%', fontWeight: 'bold', color: 'black' }}
              >{`${client?.NAME_SEI_1} ${client?.NAME_MEI_1}`}</Label>
              <Label style={{ fontSize: '120%', fontWeight: 'bold', color: 'black' }}></Label>
            </Col>
            <Col className="text-right">
              <div className="font-icon-detail">
                {displayInfo ? (
                  <i className="material-symbols-rounded">keyboard_control_key</i>
                ) : (
                  <i className="material-symbols-rounded">keyboard_arrow_right</i>
                )}
              </div>
            </Col>
          </Row>
          {displayInfo ? (
            <>
              <Row>
                <Col className="pr-1" md="3">
                  <Form.Group>
                    <label>工事名</label>
                    <TextComponent
                      placeholder=""
                      type="text"
                      value={invoice?.KOUJI_MEI}
                      disabled={true}
                    ></TextComponent>
                  </Form.Group>
                </Col>
                <Col className="pr-1" md="4">
                  <Form.Group>
                    <label>工事場所</label>
                    <TextComponent
                      placeholder=""
                      type="text"
                      value={invoice?.KOUJI_BASYO}
                      disabled={true}
                    ></TextComponent>
                  </Form.Group>
                </Col>
                <Col className="pr-1" md="2">
                  <Form.Group>
                    <label>請求日</label>
                    <TextComponent
                      type="date"
                      value={invoice?.SEIKYU_DATE}
                      disabled={true}
                    ></TextComponent>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="pr-1" md="3">
                  <Form.Group>
                    <label>請求先名</label>
                    <TextComponent
                      placeholder=""
                      type="text"
                      value={invoice?.SEIKYU_CLIENT_NAME}
                      disabled={true}
                    ></TextComponent>
                  </Form.Group>
                </Col>
                <Col className="pr-1" md="4">
                  <Form.Group>
                    <label>請求先住所</label>
                    <TextComponent
                      placeholder=""
                      type="text"
                      value={`${invoice?.SEIKYU_ADD_1}${invoice?.SEIKYU_ADD_2}`}
                      disabled={true}
                    ></TextComponent>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="pr-1" md="6" rows="3">
                  <Form.Group>
                    <div className="mb-3">
                      <label htmlFor="textArea1" className="form-label">
                        備考
                      </label>
                      <TextComponent
                        className="form-control"
                        type="textarea"
                        id="textArea1"
                        rows="2"
                        value={invoice?.BIKO}
                        onChange={handleOnChangeInvoice('BIKO')}
                      ></TextComponent>
                    </div>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group>
                    <div className="mb-3">
                      <label htmlFor="textArea2" className="form-label">
                        社内備考（※請求書には印刷されません）
                      </label>
                      <TextComponent
                        className="form-control"
                        type="textarea"
                        rows="2"
                        value={invoice?.BIKO_SYANAI}
                        onChange={handleOnChangeInvoice('BIKO_SYANAI')}
                      ></TextComponent>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
        </Card.Body>
      </Card>
      <Card className="mb-0">
        <Card.Body>
          <Row>
            <Col>工事明細</Col>
          </Row>
          <Row>
            <Col
              className="pl-3 table-scroll"
              md="12"
              style={
                displayInfo ? { height: 'calc(100vh - 498px)' } : { height: 'calc(100vh - 255px)' }
              }
            >
              <FlexGrid
                allowSorting={false}
                ref={flexGrid}
                itemsSource={viewDetails}
                isReadOnly={true}
                selectionMode={'None'}
                headersVisibility={'Column'}
                initialized={initializedGrid.bind(this)}
                formatItem={formatItem}
                loadedRows={(grid) => {
                  grid.rows.forEach((ele) => {
                    ele.height = 30;
                  });
                }}
              >
                <FlexGridColumn binding="" header="名称" isReadOnly={true} width={200}>
                  <FlexGridCellTemplate
                    cellType="Cell"
                    template={(cell) => <>{cell.item ? cell.item['CONTENT'] : ''}</>}
                  />
                </FlexGridColumn>
                <FlexGridColumn
                  binding="SIYOU"
                  header="仕様"
                  isReadOnly={true}
                  width={200}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="SU"
                  header="数量"
                  format="n1"
                  width={60}
                  dataType="Number"
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="TANI"
                  header="単位"
                  isReadOnly={true}
                  width={45}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="TANKA"
                  header="単価"
                  format="c0"
                  isReadOnly={true}
                  width={80}
                  dataType="Number"
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="KINGAKU"
                  header="金額"
                  format="c0"
                  width={100}
                  dataType="Number"
                ></FlexGridColumn>
                <FlexGridColumn binding="BIKO" header="摘要" width={100}></FlexGridColumn>
                <FlexGridColumn
                  binding="GENKA_RATE"
                  header="掛け率"
                  format="n2"
                  width={60}
                  dataType="Number"
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="GENKA_TANKA"
                  header="原価単価"
                  format="c0"
                  isReadOnly={true}
                  width={80}
                  dataType="Number"
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="GENKA_KINGAKU"
                  header="原価合計"
                  format="c0"
                  isReadOnly={true}
                  width={100}
                  dataType="Number"
                  allowResizing={false}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding=""
                  header=""
                  format="c0"
                  isReadOnly={true}
                  width={28}
                  allowResizing={false}
                >
                  <FlexGridCellTemplate
                    cellType="Cell"
                    template={(cell) =>
                      cell.item && cell.item['EDITABLE_FLG'] == '1' ? (
                        <i
                          className="material-symbols-rounded p-1"
                          style={{ transform: 'scale(1.6)', color: 'green', background: '#DDFFDD' }}
                        >
                          Done
                        </i>
                      ) : (
                        <></>
                      )
                    }
                  />
                </FlexGridColumn>
                <FlexGridColumn
                  binding="BIKO_SYANAI"
                  header="社内備考"
                  width="2*"
                  allowResizing={false}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="TRI_NAME"
                  header="注文先名"
                  width="2*"
                  allowResizing={false}
                ></FlexGridColumn>
              </FlexGrid>
            </Col>
          </Row>
          <Row className="pt-2">
            <Col>
              <Row>
                <Col className="d-flex align-items-end">
                  <Button
                    className="footer-button"
                    variant="secondary"
                    onClick={handleOnClickReturn}
                  >
                    戻る
                  </Button>
                </Col>
                <Col md="5">
                  <Row className="pr-3 text-right">
                    <FlexGrid
                      itemsSource={total}
                      selectionMode={'None'}
                      headersVisibility={'Column'}
                      isReadOnly={true}
                      allowSorting={false}
                    >
                      <FlexGridColumn
                        binding="ShoKei"
                        header="小計"
                        format="c0"
                        width={'1*'}
                      ></FlexGridColumn>
                      <FlexGridColumn
                        binding="ZeiKei"
                        header="消費税"
                        format="c0"
                        width={90}
                      ></FlexGridColumn>
                      <FlexGridColumn
                        binding="SeikyuKei"
                        header="請求金額"
                        format="c0"
                        width={'1*'}
                      ></FlexGridColumn>
                      <FlexGridColumn
                        binding="GenkaKei"
                        header="原価合計"
                        format="c0"
                        width={'1*'}
                      ></FlexGridColumn>
                      <FlexGridColumn
                        binding="ArariKei"
                        header="粗利益額"
                        format="c0"
                        width={'1*'}
                      ></FlexGridColumn>
                      <FlexGridColumn
                        binding="RiekiRitsu"
                        header="利益率"
                        format="n2"
                        width={80}
                      ></FlexGridColumn>
                    </FlexGrid>
                  </Row>
                </Col>
                <Col className="d-flex align-items-end flex-row-reverse">
                  <Button
                    variant={mode.variant}
                    className="btn-fill footer-button"
                    onClick={handleOnClickSave}
                  >
                    {mode.caption}
                  </Button>
                  {mode.id == 'approval' ? (
                    <Button
                      variant={'danger'}
                      className="btn-fill footer-button mr-2"
                      onClick={handleOnClickReject}
                    >
                      却下
                    </Button>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <MessageModal dialog={dialog}></MessageModal>
    </>
  );
}

export default InvoiceApproval;
